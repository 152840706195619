import React, { Component } from 'react'
import Footer from '../../../components/footer'
import "./index.scss"
import S from 'gongyongicon'
import {selectAppConfig} from "../../../api/schoolUrl"
import {zjyIndex} from "../../../config";
export default class AppDownload extends Component {
    state={
        appConfig:[]
    }
    componentDidMount () {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
        this.selectAppConfig()
    }
    selectAppConfig = async ()=>{
        await selectAppConfig().then(e=>{
            if(e){
                this.setState({
                    appConfig:e
                })
            }
        })
    }
    gotoIndex = () =>{ // 返回中教云首页
        window.open(`${zjyIndex}`)
    }

    render() {
        let{appConfig}=this.state
        return (
            <div  className='wapper_box'>
               <div className="app_wapper" style={{background: `url("${window.$$setImgSrc('/nxmh-appBack.png')}") no-repeat center 100%`,backgroundSize: '100% 100%'}}>
                   <div className={'phoneHeader'}>
                       <div className={'header_left'}>
                           宁夏教育资源公共服务平台    Ning Xia Public Service Platform for Educational Resources
                       </div>
                       <div className={'header_right'} onClick={()=>this.gotoIndex()}>
                           返回教育云首页
                       </div>
                   </div>
                   <div className="app_box">
                       <div className='center_con'>
                           <h2>APP下载</h2>
                           <div className='title_small'>初心如磐<span>|</span>使命在肩<span>|</span>使用微信扫一扫下载</div>
                           <ul className='ewm_box'>
                               <li className='list_li'>
                                   {
                                       appConfig.iosImg ?
                                           <img src={appConfig.iosImg} alt="avatar" /> : <img src={window.$$img('zjyd-web-global/global_placeholder_2.png')} alt="avatar" />
                                   }
                                   {/*<img src={appConfig.iosImg} alt="avatar" />*/}
                                   {/*<RenderImg size='m' id={appConfig.iosImg} type={appConfig.iosMediaType}/>*/}
                                   <div className='btn_box'>
                                       <S.Iphone/>
                                       <div className='Color_Box'>
                                           <div className='color_top'>Download On The </div>
                                           <div className='color_bottom'>App Store</div>
                                       </div>
                                   </div>
                               </li>
                               <li className='list_li'>
                                   {
                                       appConfig.androidImg ?
                                           <img src={appConfig.androidImg} alt="avatar" /> : <img src={window.$$img('zjyd-web-global/global_placeholder_2.png')} alt="avatar" />
                                   }
                                   {/*<img src={appConfig.androidImg} alt="avatar" />*/}
                                   {/*<RenderImg size='m' id={appConfig.androidImg} type={appConfig.androidMediaType}/>*/}
                                   <div className='btn_box'>
                                       <S.Android/>
                                       <div className='Color_Box'>
                                           <div className='color_top'>Download On The </div>
                                           <div className='color_bottom'>Android</div>
                                       </div>
                                   </div>
                               </li>
                           </ul>
                       </div>
                   </div>
               </div>
                <Footer />
            </div>
        )
    }
}
