import { getUserRole, getUserInfo } from '@/api/role';
import { postActionlog } from '@/api/actionlog';
import Cookies from 'js-cookie';
// 映射关系
const authorityObj = {
    'CBS': {
        path: "guanlizhekongjian",
        text: 'CBS',
        name: "进入管理者空间"
    },
    'TS': {
        path: "gerenkongjian",
        text: 'TS',
        name: "进入教学空间"
    },
    'SS': {
        path: "gerenkongjian",
        text: 'SS',
        name: "进入学习空间"
    },
    'PORTAL': {
        path: "provincemanager",
        text: 'PORTAL',
        name: "进入门户管理"
    }
};
const token = Cookies.get("info")
    ? JSON.parse(Cookies.get("info")).token
    : "";
// 权限列表
export let authorityList = [];
export let userInfo = {};

export const getAuthorityList = (sys, callBack) => {
    try {
        token?getUserInfo().then((userRes)=>{
            authorityList.push({
                path: "gerenzhongxin",
                name: "个人中心",
                url:sys['gerenzhongxin']
            })
            if(!userRes){callBack();return}
            userInfo = userRes;

            //埋点
            let role =  userInfo.roles[0]? userInfo.roles[0].roleName:'学生';
            console.log(role,7777,userInfo);

            if(userInfo.nxUserId.length >= 32){
                let strArr = [8,4,4,4,12];
                let nUserId = ''
                strArr.forEach((x,i)=>{
                    if(nUserId.length){nUserId += '-'}
                    nUserId += userInfo.nxUserId.slice(nUserId.length-i,nUserId.length+x-i);
                })
                console.log(nUserId,'_user_id');
                window.aplus_queue.push({ action: "aplus.setMetaInfo", arguments: ["_user_id", nUserId]});
            }

            let prame = { 
                occupation: role,  
                sex: userInfo.sex, province:'宁夏',
            }
            if(userInfo.id.length == 18){
                prame.pass_id = userInfo.id
            }
            console.log(prame,'prame');
            window.aplus_queue.push({ action:'aplus.record', arguments:['$$_user_profile', 'OTHER', prame]}); 

            //埋点2,平台操作行为统计 "action":1 //行为 1）登录；2）进入教学空间；（3）院校中心、院校空间；4）职教风采；5）大国工匠；6）智慧实训；7）资源中心；
            let acitondata = {
                userId:userInfo.id,
                userName:userInfo.fullName,
                schoolId : userInfo.mainOrgId,
                schoolName: userInfo.mainOrgName,
                action : 1,
            }
            console.log(acitondata,'acitondata');
            postActionlog(acitondata);

            let {identityId} = userRes;
            getUserRole().then(res=>{
                if(!res){callBack();return}
                // 将多个权限存放到数组里面
                if(!res[identityId]){callBack();return}
                let auArr = res[identityId].split(',');
                auArr.map((auStr)=>{
                    // 跳转路径
                    if(!authorityObj[auStr]){callBack();return;}
                    authorityObj[auStr]['url'] = sys[authorityObj[auStr]['path']];
                    authorityList.push(authorityObj[auStr])
                });
                callBack();
            });
        }):callBack()
    } catch (error) {
        console.log("bbbbbbbbbb",error);
    }
}
