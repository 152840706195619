import React, { Component } from "react";
import { HashRouter } from "react-router-dom";
import RouterView from "./router/router_view";
import routers from "./router/router_config";
import "./reset_style/public.scss";
import resetAntd from "./config/antd_global";
window.GoTop = function () {
  (function smoothscroll() {
    var currentScroll =
        document.documentElement.scrollTop || document.body.scrollTop;
    if (currentScroll > 0) {
      window.requestAnimationFrame(smoothscroll);
      window.scrollTo(0, currentScroll - currentScroll / 10);
    }
  })();
};
class App extends Component {
  render() {
    return (
        <HashRouter>
          <RouterView routers={routers}></RouterView>
        </HashRouter>
    );
  }
}
App = resetAntd(App);

export default App;
