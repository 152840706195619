import React, { Component } from 'react'
import './index.scss';

export default class index extends Component {
  render() {
    let { type, content } = this.props;
    return (
      <div className="sys_empty_wrap">
        <img
          alt=""
          src={type === 1 ? require('@/assets/certificate/none_resource.png') : require("@/assets/certificate/resource_empty.png")}
        />
        <p>{content}</p>
      </div>
    )
  }
}
