import React, { Component } from 'react'
import Styles from "./index.module.scss";
import { nxbUrl} from '@/config/secret'
export default class FooterBox extends Component {
    render() {
        return (
            <div className={Styles.footer_warp}>
                <div className='footer_logo'>
                    <img src={require('@/assets/democ/footerLogo.svg')}/>
                </div>
                <ul>
                    <li className='svg_box svg_box_one'>
                        <span className='isClick' onClick={() => window.open('https://www.nxeduyun.com/czsc.jhtml')}>帮助中心</span>
                    </li>
                    <li className='svg_box'>
                        <span className='isClick' onClick={() => window.open(nxbUrl)}>门户管理</span>
                    </li>
                    <li className='svg_box'>
                        <span className='isClick' onClick={()=>window.open('https://www.nxeduyun.com/developer/#/home')}>开放平台</span>
                    </li>
                    <li>
                        <span>版权所有：宁夏回族自治区教育厅</span>
                    </li>
                    <li>
                        <span>技术支持：宁夏教育信息化管理中心</span>
                    </li>
                    <li>
                        <span>服务热线：0951-5559291  5559148</span>
                    </li>
                    <li>
                        <span>宁ICP备16000125号</span>
                    </li>
                    <li>
                        <span>宁公网安备 64010602000493号</span>
                    </li>
                </ul>
            </div>
        )
    }
}
