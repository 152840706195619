import React, { Component } from 'react'
import Header from '../../../components/demoeHeader'
import Footer from '../../../components/footer'
import { Input, Button, Pagination ,Modal} from 'antd'
import S from "gongyongicon";
import Styles from "./index.module.scss"
import {getEnterpriseEnterPortal,getEnterpriseAudit} from "../../../api/enterpriseUrl"
import RenderImg from '../../../components/renderImg/index'
import Cookies from 'js-cookie';
import {logoutUrl} from "../../../config";
import EmptyConet from '../../../components/mainemptyConet'
const urlPre = "zjyd-space-front-peixun/"
const {Search} = Input

export default class Company extends Component {
    state = {
        dataSoure: [],
        visible:false,
        total: 0,
        highlighted: 0,
        epsName: null,
        epsType: null,
        labelList: [{
            name: "全部分类",
        }, {
            name: "国有企业",
        },{
            name: "集体企业",
        },{
            name: "港澳台及外资企业",
        },{
            name: "民营企业",
        },{
            name: "机关事业单位",
        },{
            name: "社会团体",
        },{
            name: "民办非企业",
        },{
            name: "合伙组织",
        },{
            name: "个体工商户",
        },],
        userInfo: Cookies.get("info")? JSON.parse(decodeURI(Cookies.get("info"))):'',
        pageNum: 1,
        pageSize: 24,
    }

    componentDidMount() {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
        this.getEnterpriseEnterPortal()
    }

    getEnterpriseEnterPortal = () =>{
        let data = {
            epsType: this.state.epsType,
            epsName: this.state.epsName,
            pageNum: this.state.pageNum,
            pageSize: this.state.pageSize
        }
        getEnterpriseEnterPortal(data).then(res =>{
            console.log('res ===>>> 000', res)
            if(res){
                this.setState({
                    dataSoure: res.data,
                    total: res.total
                })
            }
        })
    }


    onSearch = (value) => { // 搜索
        console.log('value 001', value)
        this.setState({
            epsName: value
        }, () =>{
            this.getEnterpriseEnterPortal()
        })
    }

    buttonIndex =(index)=>{
        console.log('index 001', index)
        if(index === 0){
            this.setState({
                epsType: null,
                highlighted: 0
            }, () => {
                this.getEnterpriseEnterPortal()
            })
        } else {
            this.setState({
                epsType: index,
                highlighted: index
            }, () => {
                this.getEnterpriseEnterPortal()
            })
        }

    }
    onShowSizeChange=(current, pageSize)=>{ // 每页条数
        this.setState({
            pageSize:pageSize,
            pageNum:1
        },()=>{
            this.getEnterpriseEnterPortal()
        })
    }
    onChange=(page)=>{ // 分页
        this.setState({
            pageNum:page
        },()=>{
            this.getEnterpriseEnterPortal()
        })
    }

    getEnterpriseAudit =()=>{
        // let data = {
        //     userId : this.state.userInfo.id
        // }
        let {userInfo} = this.state
        console.log('这个是登录完的userInfo：：', userInfo)
        if (userInfo) {
            getEnterpriseAudit().then(res => {
                console.log('res 001 ', res)
                if(res){
                    if(res.auditStatus === 1){
                        Modal.info({
                            title: '您已经提交入驻申请',
                            className:'modalEnterprises',
                            centered:true,
                            icon:<S.Blueexclamation/>,
                            content: (
                                <div>
                                    您已提交入驻申请，请耐心等待审核结果
                                </div>
                            ),
                            onOk() {},
                        });
                        // this.mesWarning('您已提交入驻申请，请耐心等待审核结果')
                    } else if(res.auditStatus === 2){
                        Modal.info({
                            title: '您的企业已成功入驻',
                            className:'modalEnterprises',
                            centered:true,
                            icon:<S.Blueexclamation/>,
                            content: (
                                <div>
                                    您的企业已成功入驻，无需再次申请
                                </div>
                            ),
                            onOk() {},
                        });
                        // this.mesWarning('您的企业已成功入驻，无需再次申请')
                    } else if(res.auditStatus === 4 && userInfo.mainOrgId !== "8c76a9af50bf40c5acb7afe3fb74fbbd"){
                        Modal.info({
                            title: '您的企业已成功入驻',
                            className:'modalEnterprises',
                            centered:true,
                            icon:<S.Blueexclamation/>,
                            content: (
                                <div>
                                    您的企业已成功入驻，无需再次申请
                                </div>
                            ),
                            onOk() {},
                        });
                        // this.mesWarning('您已有所属机构，无法再次申请')
                    } else {
                        this.props.history.push('/join')
                    }
                } else {
                    this.props.history.push('/join')
                }
            })
        } else {
            window.location = `${logoutUrl}?url=${window.location.origin}`
        }
    }

    render() {
        const {$$img} = window;
        const {highlighted,userInfo,labelList,total,dataSoure ,pageNum,pageSize} = this.state
        console.log('cookieInfo 001', userInfo)
        return (
            <div className={Styles.CompanyCentre_warp}>
                <Header {...this.props} id={7} />
                <div className="head-box" style={{background: `url("${window.$$setImgSrc('/nxmh_enterpriseCenter.png')}") no-repeat 100%`,backgroundSize: '100% 100%'}}>
                    <div className="bg-img-1">
                        <div>企业中心</div>
                        <div className="join-now" onClick={this.getEnterpriseAudit}>
                            立即加入企业中心
                        </div>
                    </div>
                </div>
                <div className="content">
                    <div className="content_box">
                        <div className="top_warp">
                            <div className="top">
                                <div className="btns">
                                    <span className="fist">企业分类</span>
                                    {
                                        labelList ? labelList.map((z, y) =>{
                                            return <Button className={y === highlighted ? 'active' : ''} onClick={() => this.buttonIndex(y)}>
                                                { z.name }
                                            </Button>
                                        }): null
                                    }

                                </div>
                            </div>
                            <div className="search">
                                    <span>
                                        共查询到{total}个企业
                                    </span>
                                <Search style={{ width: '3.6rem', height: '0.38rem' }} placeholder="请输入企业名称" onSearch={this.onSearch} enterButton/>
                            </div>

                </div>
                <div className='CompanyCentre_content'>
                    {
                        dataSoure && dataSoure.length > 0 ? dataSoure && dataSoure.map((item, index) => {
                            return <div className="dataList" key={item.id}>
                                <ul>
                                    <div className="li_con">
                                        <div className="top">
                                            <div className="top_text">
                                                <p className='companyName'>{item.epsName}</p>
                                                <li className='companyCityLi'>所在省份:<span>{item.provinceName}</span> 企业类型:<span>{
                                                    item.epsType === 1 ? "国有企业" :
                                                    item.epsType === 2 ? "集体企业" :
                                                    item.epsType === 3 ? "港澳台及外资企业" :
                                                    item.epsType === 4 ? "民营企业" :
                                                    item.epsType === 5 ? "机关事业单位" :
                                                    item.epsType === 6 ? "社会团体" :
                                                    item.epsType === 7 ? "民办非企业" :
                                                    item.epsType === 8 ? "合伙组织" :
                                                    item.epsType === 9 ? "个体工商户" : ''
                                                }</span>
                                                </li>
                                            </div>
                                            <span className='pic'>
                                                <RenderImg size='m' id={item.epsLogo} type=''/>
                                            </span>
                                        </div>
                                    </div>
                                </ul>
                                <div className="text">
                                    <p className='companyIntroduction'>
                                        {item.epsShortDesc}
                                    </p>
                                    {/*{item.epsShortDesc ?*/}
                                    {/*    <span className='companyDetails'>查看详情 <span className='companyDetailsA'> > </span></span>*/}
                                    {/*    : ''*/}
                                    {/*}*/}
                                </div>

                                <div className="footer_box">
                                    <ul>
                                        {
                                            item.epsLabelList && item.epsLabelList.length ? item.epsLabelList.map((v, i) =>{
                                                return <li className='companyLabelA '>
                                                    {v}
                                                </li>
                                            }): null
                                        }
                                    </ul>
                                    <Button className='enterWebsite' onClick={() => window.open(item.innerWebsite)}>进入企业空间</Button>
                                </div>
                            </div>
                        })  : (
                            <div className={Styles.EmptyConet_CONT}>
                                <EmptyConet/>
                            </div>
                        )
                    }

                    {
                        dataSoure && dataSoure.length > 0 ?
                            <div className='footer_pag'>
                                <Pagination
                                    showSizeChanger
                                    pageSizeOptions={["12", "24", "36", "48"]}
                                    onShowSizeChange={this.onShowSizeChange}
                                    onChange={this.onChange}
                                    defaultCurrent={1}
                                    pageSize={pageSize}
                                    current={pageNum}
                                    total={total}
                                />

                            </div>: ''
                    }
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        )
    }
}
