import React, {Component} from 'react'
import Header from '../../../components/demoeHeader'
import Footer from '../../../components/footer'
import {Input, Button, Pagination, Drawer} from 'antd'
import Styles from "./index.module.scss"
import {getFriendLinkPage} from '../../../api/schoolUrl'
import EmptyConet from '../../../components/mainemptyConet'
import {newsHostURL} from "@/config"
const urlPre = "zjyd-space-front-peixun/"
const {Search} = Input


export default class Morelinks extends Component {
    state = {
        dataSoure: [],
        visible: false,
        pageNum: 1,
        pageSize: 24,
        total: 0,

    }
    onSearch = (val) => { // 搜索
        this.setState({
            name: val
        })
    }

    componentDidMount() {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
        this.getFriendLinkPage()
    }

    getFriendLinkPage = async () => {
        const {pageNum, pageSize} = this.state
        let data = {pageNum, pageSize}
        await getFriendLinkPage(data).then(e => {
            // let allLink = JSON.parse(JSON.stringify(e.data));
            // allLink.unshift({
            //     linkName:'1+X证书',
            //     linkUrl:newsHostURL+'#/certificate/certificatehome'
            // })
            if (e) {
                this.setState({
                    dataSoure: e.data,
                    total: e.total
                })
            }
        })
    }

    goLink = (url) => {
        window.open(url)
    }
    //pageSize变化
    onShowSizeChange = (current, pageSize) => {
        this.setState(
            {
                pageNum: 1,
                pageSize: pageSize,
            },
            () => {
                this.getFriendLinkPage();
            }
        );
    };
    //pageNum变化
    onPageNumChange = (page, pageSize) => {
        this.setState(
            {
                pageNum: page,
            },
            () => {
                this.getFriendLinkPage();
            }
        );
    };

    render() {
        const {$$img} = window;
        const {dataSoure, visible, pageNum, pageSize, total} = this.state
        return (
            <div className={Styles.Morelinks_warp}>
                <Header {...this.props}/>
                <div className="top_warp" style={{background: `url("${window.$$setImgSrc('/nxmh-morelinkstopbgi.png')}") no-repeat 100%`,backgroundSize: '100% 100%'}}>
                    <div className='top'>
                        <p>您的位置：<i className="first" onClick={() => this.props.history.push('/main')}>首页</i> / <i className="two">友情链接</i></p>
                        <p>
                            友情链接
                        </p>
                    </div>
                </div>
                <div className="Morelinks_bottom">
                    <div className="content">
                        <div className="content_box">
                            <div className="search">
                                <div className={'searchcontent'}>
                            <span>
                            共查到{total}个友情链接
                            </span>

                                    <Search style={{width: '3.6rem', height: '0.38rem'}} placeholder="请输入链接名称" onSearch={this.onSearch}
                                            enterButton/>

                                </div>
                            </div>
                            <div className='Morelinks_content'>
                                <div className="Morelinks_conbottm">
                                    {
                                        dataSoure && dataSoure.length > 0 ? dataSoure && dataSoure.map((v, i) => {
                                            return <div className="dataList" key={v.id} style={{backgroundImage: `url("${window.$$setImgSrc('/nxmh-morelinksbgilittlelinks.png')}")`}}>
                                                <ul>
                                                    <li>
                                                        <div className="li_con">
                                                            <span className={'blue'}></span>
                                                            <p className='companyName'>{v.linkName}</p>
                                                        </div>
                                                        <div className="footer_box">
                                                            <Button className='enterWebsite' onClick={() => this.goLink(v.linkUrl)}>官网链接
                                                                > </Button>
                                                        </div>
                                                    </li>

                                                </ul>
                                            </div>
                                        }) : (
                                            <div className={Styles.EmptyConet_CONT}>
                                                <EmptyConet/>
                                            </div>
                                        )
                                    }
                                </div>

                                {
                                    dataSoure && dataSoure.length > 0 ?(
                                        <div className='footer_pag'>
                                            <Pagination
                                                showSizeChanger
                                                onShowSizeChange={this.onShowSizeChange}
                                                onChange={this.onPageNumChange}
                                                defaultCurrent={1}
                                                current={pageNum}
                                                pageSize={pageSize}
                                                total={total}
                                                pageSizeOptions={ ['12', '24', '36', '48']}
                                            />

                                        </div>
                                    ):""
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <Footer/>
            </div>
        )
    }
}
