import { post } from "../../until/axios_instans";

// 获取工作室级别列表
export function studioLevel(data) {
    return post("/api/craftsman/api/v1/common/studioLevel", data);
}
// 工作室列表(没有机构ID)
export function studioList(data) {
    return post("/api/craftsman/api/v1/portal/studio/studioList", data);
}
//根据学校id查询域名
export function getDoMain(data) {
    return post("/api/university/schoolDomain/getDoMain", data);
}
