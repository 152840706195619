export const isEmpty = (v) => {
    return typeof v === 'undefined' || v === null || v === "";
}

export const isNotEmpty = (v) => {
    return !isEmpty(v);
}
export function ziyuan_get_query(name) {
    const search = window.location.href
    if (!search) return null
    const item = (search.split('?')[1] || '').split('&').find(v => {
      return v.includes(name)
    });
    if (item) {
      const _item_arr = item.split('=')
      return _item_arr[1]
    } else {
      console.log(`${name} is not in search`)
      return null
    }
  }
