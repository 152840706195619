import React, { Component } from 'react'
import Header from '../../../components/demoeHeader'
import Footer from '../../../components/footer'
import style from "./index.module.scss"
import {getEduServiceList,selectServiceCopywriting} from "../../../api/schoolUrl"
import {Button, Input, Pagination} from "antd";
import RenderImg from "../../../components/renderImg";
import EmptyConet from '../../../components/mainemptyConet'
const {Search} = Input
export default class apply extends Component {
    state={
        dataSoure: [],
        pageNum:1,
        pageSize:24,
        total:0,
        name:'',//检索条件
        copywritingIntroduction:'',//介绍文案
        number:30
    }
    componentDidMount() {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
        this.getEduServiceList()
        this.selectServiceCopywriting()
    }
    //教育服务列表
    getEduServiceList= async ()=>{
        let {
            pageNum,
            pageSize,
            name,
        } = this.state;
        let data={eduName:name,pageNum:pageNum,pageSize:pageSize,}
        await getEduServiceList(data).then(e=>{
            if(e){
                this.setState({dataSoure:e.data,total:e.total,pageNum:e.pageNum,pageSize:e.pageSize})
            }
        })
    }
    //top的文案
    selectServiceCopywriting= async ()=>{
        await selectServiceCopywriting({serviceType:4}).then(e=>{
            if(e){
                this.setState({copywritingIntroduction:e.copywritingIntroduction})
            }
        })
    }
    //pageSize变化
    onShowSizeChange = (current, pageSize) => {
        this.setState(
            {
                pageNum: 1,
                pageSize: pageSize,
            },
            () => {
                this.getEduServiceList();
            }
        );
    };
    //pageNum变化
    onPageNumChange = (page, pageSize) => {
        this.setState(
            {
                pageNum: page,
            },
            () => {
                this.getEduServiceList();
            }
        );
    };
    onSearch = (val) => { // 搜索
        this.setState({
            name: val
        }, ()=>{
            this.getEduServiceList()
        })
    }
    goto=(e)=>{
        if(e){
            window.open(e)
        }
    }
    render() {
        const { dataSoure,total,pageNum,pageSize} = this.state
        return (
            <div className={style.apply_warp}>
                <Header {...this.props} />
                <div className="top_warp">
                    <div className="head-box" style={{background: `url("${window.$$setImgSrc('/nxmh-yyzx.png')}") no-repeat`,backgroundSize: '100% 100%'}}>
                        <div className="bg-img-1">
                            <span>您的位置：<i className={'isClick'} onClick={()=> this.props.history.goBack()}>首页</i> / <i className={'noClick'}>应用中心 </i> </span>
                            <div>应用中心</div>
                        </div>
                    </div>
                    <div className="content">
                        <div className="content_box">
                            <div className="search">
                                <span>
                                    共查询到{total}个应用
                                </span>
                                <Search style={{ width: '3.6rem', height: '0.38rem'}} placeholder="请输入应用名称" onSearch={this.onSearch} enterButton/>
                            </div>
                            <div className="apply_content">
                                {
                                    dataSoure && dataSoure.length > 0 ? dataSoure && dataSoure.map((v, i) => {
                                        return <div className="dataList" key={v.id}>
                                            <ul>
                                                <li>
                                                    {
                                                        v.imgType === 1? <img src={window.$$setImgSrc(v.eduIcon)}/> :
                                                            <RenderImg size='m' id={v.eduIcon} type={v.mediaType}/>
                                                    }
                                                </li>
                                                <div className="li_con">
                                                    <li>{v.eduName}</li>
                                                    {/*<li>{v.eduIntroduction}</li>*/}
                                                    <p>{v.eduIntroduction}</p>
                                                    <div className="footer_box">
                                                        <Button onClick={() => this.goto(v.eduLink)}>查看详情 ></Button>
                                                    </div>
                                                </div>

                                            </ul>
                                        </div>

                                    }): (
                                        <EmptyConet/>
                                    )
                                }
                                {
                                    dataSoure && dataSoure.length > 0 ?
                                        <div className='footer_pag'>
                                            <Pagination
                                                showSizeChanger
                                                pageSizeOptions={["12", "24", "36", "48"]}
                                                onShowSizeChange={this.onShowSizeChange}
                                                onChange={this.onPageNumChange}
                                                defaultCurrent={1}
                                                current={pageNum}
                                                pageSize={pageSize}
                                                total={total}
                                            />
                                        </div>: ''
                                }
                            </div>
                        </div>
                    </div>

                </div>
                {/*<Education/>*/}
                <Footer />
            </div>
        )
    }
}
