
import { createHashHistory } from 'history';
const history = createHashHistory();

export default {
    CHANGE_ACTIVE(state, { data }) {
        state.activeKey = data;
        sessionStorage.setItem('header_menu_key', data);
    },
    GET_BUTTON_ROLE(state, { data }) {
        state.buttonRole = data;
    },
    GET_MENU_ROLE(state, { data }) {
        // let pathname = window.location.pathname;
        // let o = data[0];
        // function jump(url) {
        //     if (pathname === '/' && url) {
        //         history.push(url);
        //     }
        // }
        // if (o && o.children[0] && o.children[0].menuUrl) {
        //     let url = o.children[0].menuUrl;
        //     jump(url)
        // } else {
        //     if (o && o.children[0] && o.children[0].children[0].menuUrl) {
        //         let url = o.children[0].children[0].menuUrl;
        //         jump(url);
        //     }
        // }
        // data.push({
        //     id: "39a6887a24b346cab2914fb5db604b16",
        //     menuName: "校本资源库",
        //     menuUrl: "/main/school_resource"
        // })

        state.menuList = data;
   

        
        
    }
}
