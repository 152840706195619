import React, { Component } from 'react'
import echarts from 'echarts/lib/echarts';
import 'echarts/lib/component/tooltip';
import 'echarts/lib/component/title';
import 'echarts/lib/component/visualMap';
import 'echarts/lib/chart/map';
import 'echarts/lib/chart/bar';
// import '../../../components/mapChina/china'
import 'echarts/lib/chart/line';
import 'echarts/lib/chart/gauge';
import 'echarts/lib/chart/pie';
// import 'echarts/map/js/china';
// import geoJson from 'echarts/map/json/china.json';
import Header from '../../../components/demoeHeader'
import Footer from '../../../components/footer'
import "./index.module.scss"
export default class TestMap extends Component {
    render() {
        return (
            <div>
                <Header {...this.props} id={4} />
                地图
                <Footer />
            </div>
        )
    }
}
