import React, { Component } from 'react'
import { Input, Form,Modal,Tooltip,Select,Button,Checkbox,Upload} from 'antd'
import Styles from "./index.module.scss"
import S from "gongyongicon";
import AddMark from "../../../components/addMark"
import {addEnterpriseEnter,getAddr} from "../../../api/enterpriseUrl"
import {uploadFile} from "../../../api/uploadFile";
import RenderImg from '../../../components/renderImg/index'
import Cookies from 'js-cookie';
const FormItem = Form.Item;
const { TextArea } = Input;
const { Option } = Select;

export default class Join extends Component{
    state = {
        text:"整合智慧教学（智慧课堂）、智慧考评、智慧管理、智慧实训于一体，建立学生与教师的终身学习/发展档案，使教学管理多端互联、实现数据共享、构建教学与实训的立体化，进而全新打造现代化的全场景智慧教育生态体系。",
        typeList:[
            {
             key: 1,
             value:"国有企业"
            },
            {
             key: 2,
             value:"集体企业"
            },
            {
             key: 3,
             value:"港澳台及外资企业"
            },
            {
             key: 4,
             value:"民营企业"
            },
            {
             key: 5,
             value:"机关事业单位"
            },
            {
             key: 6,
             value:"社会团体"
            },
            {
             key: 7,
             value:"民办非企业"
            },
            {
             key: 8,
             value:"合伙组织"
            },
            {
             key: 9,
             value:"个体工商户"
            },

        ],
        markVisible:false,
        tagList:[],
        closable:false,
        agreementVisible:false,
        epsType: null,
        epsLabel: [],
        filePng: {},
        cookieInfo: Cookies.get("info")? JSON.parse(decodeURI(Cookies.get("info"))):'',
        joinDisable: true,
        checkedValues: false
    }

    componentDidMount() {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
        this.getProvince()
    }


    addEnterpriseEnter =()=>{
        let {epsLabel,cookieInfo,joinDisable,checkedValues} = this.state
        if (epsLabel.length > 3) {
            return this.mesWarning('最多只能添加三个标签')
        }
        if (!checkedValues) {
            return this.mesWarning('请同意企业入驻协议')
        }
        this.props.form.validateFields((err, value) => {
            if(!err){
                if (joinDisable) {
                    this.setState({
                        joinDisable: false
                    })
                    let data = {
                        "addr": value.addr,
                        "applyTime": value.applyTime,
                        // "applyUserId": cookieInfo.id,
                        "areaCode": this.state.areaCode,
                        "areaName": this.state.areaName,
                        "auditResult": value.auditResult,
                        "auditStatus": value.auditStatus,
                        "auditTime": value.auditTime,
                        "auditUserName": value.auditUserName,
                        "businessLicense": this.state.filePng.fileId,
                        "cityCode": this.state.cityCode,
                        "cityName": this.state.cityName,
                        "contactEmail": value.contactEmail,
                        "contactPeople": value.contactPeople,
                        "contactPhone": value.contactPhone,
                        "epsLabel": epsLabel.join(","),
                        "epsLogo": this.state.file.fileId,
                        "epsName": value.epsName,
                        "epsShortDesc": value.epsShortDesc,
                        "epsType": this.state.epsType,
                        "legalPerson": value.legalPerson,
                        "postalCode": value.postalCode,
                        "provinceCode": this.state.provinceCode,
                        "provinceName": this.state.provinceName,
                        "recommender": value.recommender,
                        "taxNum": value.taxNum,
                        "totalTel": value.totalTel
                    }
                    addEnterpriseEnter(data).then(res =>{
                        if(res){
                            this.setState({
                                joinDisable: true
                            })
                            this.props.history.push('/company')
                        }
                    })
                }
            }

        })
    }

    getProvince =(value)=>{
        let data = {
            level: 1,
            pid: null
        }
        getAddr(data).then(res =>{
            this.setState({
                provinceList: res
            })
        })
    }
    getCity =(value, key)=>{
        let data = {
            level: 2,
            pid: key.key
        }
        getAddr(data).then(res =>{
            this.setState({
                cityList: res,
                provinceCode: value.key,
                provinceName: value.label
            })
        }, () => {


        })
    }
    getArea =(value, key)=>{
        let data = {
            level: 3,
            pid: key.key
        }
        getAddr(data).then(res =>{
            this.setState({
                areaList: res,
                cityCode: value.key,
                cityName: value.label
            })
        })
    }

    setCode =(value, key)=> {
        this.setState({
            areaCode: value.key,
            areaName: value.label
        })
    }

    //上传图片
    handleImgChange = (fileId, mediaType) => {
        this.props.form.setFieldsValue({"publicQrcodeImg": fileId})
        this.setState({
            fileId,
            mediaType
        })
    }
    //公司类型
    companyType=(value)=>{
        this.setState({
            epsType: value
        }, () =>{
            this.props.form.setFieldsValue({
                epsType: value
            })
        })
    }
    //确定
    sureClick=()=>{
        this.AddLabel.props.form.validateFields((err,value)=>{
            if(!err){
                let epsLabel = this.state.epsLabel ? this.state.epsLabel : []
                epsLabel.push(value.epsLabel)
                this.setState({
                    epsLabel: epsLabel,
                    markVisible: false,
                    closable: false
                })
            }
        })
    }
    //校验电子邮箱格式
    validateInvestmailbox =  (rule, value, callback) => {
        let regex = /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.(com|cn|net|clup|com.cn))$/;
        if (value && !regex.test(value)){
            callback('请填写正确电子邮箱')
        }else{
            callback();
        }

    };
    //协议checkbox
    onChange=(e)=>{
        this.setState({
            checkedValues: e.target.checked
        })
    }
    //同意协议
    agreementClick=()=>{
        this.setState({
            agreementVisible:false
        })
    }
    //modal内checkbox
    onChangeModal=()=>{

    }

    //上传方法
    multipartUpload = async (e) => {
        let list = [...e.target.files];
        let upFile = { originName:list[0].name }
        this.setState({
            file: upFile
        })
        let thisFileName =list[0].name.substring(0,list[0].name.lastIndexOf('.')).trim()
        if (thisFileName === ""){
            return
        }
        let flag = false;
        list.forEach((k) => {
            const fileNameindex = k.name.lastIndexOf('.'); // 取到文件名开始到最后一个点的长度
            const fileNameLen = k.name.length; // 取到文件名长度
            const fileType = k.name.substring(fileNameindex + 1, fileNameLen); // 截
            if (!this.checkFileType(fileType)) {
                // message.error("上传的资源格式不符")
                // this.mesWarning('上传的资源格式不符')
                flag = true
            }
        });
        //类型不符停止进行
        if (flag){
            document.getElementById("file").value = '';
            return
        }
        let f = 0;
        let fn = async (a) => {
            f++;
            let da = await this.uploadFile(list[a]);
            if (da === 1 && f < list.length) {
                return fn(f);
            }
        };
        fn(f);
    };
    //上传文件
    uploadFile = async (v) => {
        if (!v) return;
        return new Promise((reslove, reject) => {
            if (v.size > 5242880) {
                this.setState({
                    file: null
                })
                return this.mesWarning('文件大于5MB');
            }
            const fromData = new FormData()
            fromData.append('file', v)
            fromData.append('folder', 'nx')
            uploadFile(fromData,{isToken:false}).then(res => {
                if (res) {
                    let upFile = {
                        originName:res.originFileName,
                        fileId:res.fpName,
                        fileSuffix:res.mediaType,
                        resourceId:res.id,
                        fileSize:(res.fileSize / 1024).toFixed(2)
                    }
                    this.setState({
                        file: upFile
                    })
                    reslove(1);
                }
            })
        });
    };
    //检测文件后缀是否符合要求
    checkFileType = (type) => {
        let checkType = "jpg、png、gif、bmp";
        return checkType.includes(type)
    };
    //上传方法
    uploadPng = async (e) => {
        let list = [...e.target.files];
        let upFile = { originName:list[0].name }
        this.setState({
            filePng: upFile
        })
        let thisFileName =list[0].name.substring(0,list[0].name.lastIndexOf('.')).trim()
        if (thisFileName === ""){
            this.mesWarning('文件名不能为空')
            return
        }
        let flag = false;
        list.forEach((k) => {
            const fileNameindex = k.name.lastIndexOf('.'); // 取到文件名开始到最后一个点的长度
            const fileNameLen = k.name.length; // 取到文件名长度
            const fileType = k.name.substring(fileNameindex + 1, fileNameLen); // 截
            if (!this.checkFileTypePng(fileType)) {
                // this.mesWarning("上传的资源格式不符")
                flag = true
            }
        });
        //类型不符停止进行
        if (flag){
            document.getElementById("filePng").value = '';
            return
        }
        let f = 0;
        let fn = async (a) => {
            f++;
            let da = await this.uploadFilePng(list[a]);
            if (da === 1 && f < list.length) {
                return fn(f);
            }
        };
        fn(f);
    };
    //上传文件
    uploadFilePng = async (v) => {
        if (!v) return;
        return new Promise((reslove, reject) => {
            if (v.size > 5242880) {
                this.setState({
                    filePng: null
                })
                return this.mesWarning('文件大于5MB');
            }
            const fromData = new FormData()
            fromData.append('file', v)
            fromData.append('folder', 'nx')
            uploadFile(fromData).then(res => {
                if (res) {
                    let upFile = {
                        originName:res.originFileName,
                        fileId:res.fpName,
                        fileSuffix:res.mediaType,
                        resourceId:res.id,
                        fileSize:(res.fileSize / 1024).toFixed(2)
                    }
                    this.setState({
                        filePng: upFile
                    })
                    reslove(1);
                }
            })
        });
    };

    //检测文件后缀是否符合要求
    checkFileTypePng = (type) => {
        let checkType = "jpg、png";
        return checkType.includes(type)
    };

    addLabel =()=>{
        if(this.state.epsLabel.length === 3){
            this.mesWarning('最多只能添加三个标签')
        } else {
            this.setState({
                markVisible:true
            })
        }
    }
//上传文件
    beforeUploadTwo =async (file) => {
        const {schoolEduCode,taskId,cookieInfo} =this.state
        let upFile = { resourceName:file.name }
        this.setState({
            filePng: upFile
        })
        const formdata = new FormData();
        formdata.append('file', file);
        formdata.append('folder', 'nx')
        const isLt5M = file.size / 1024 / 1024 < 5;
        if (!isLt5M) {
            this.mesWarning("文件不能大于5M")
            return false;
        }
        const fileNameindex = file.name.lastIndexOf('.'); // 取到文件名开始到最后一个点的长度
        const fileNameLen = file.name.length; // 取到文件名长度
        const fileType = file.name.substring(fileNameindex + 1, fileNameLen); // 截
        let flag = false;
        if (!this.checkFileTypePng(fileType)) {
            // this.mesWarning("上传的资源格式不符")
            flag = true
        }
        //类型不符停止进行
        if (flag){
            document.getElementById("filePng").value = '';
            return
        }
        let res = await uploadFile(formdata);
        if (res instanceof Object) {
            let upFile = {
                resourceName:res.originFileName,
                fileId:res.fpName,
                resourceSuffix:res.mediaType,
                resourceId:res.id,
                resourceSize:(res.fileSize / 1024).toFixed(2)
            }
            this.setState({
                filePng: upFile
            })

        }};

    deleTag =()=>{
        if(this.state.closable === false){
            this.setState({
                closable: true
            })
        } else {
            this.setState({
                closable: false
            })
        }
    }

    deleTagList =(index)=>{
        const epsLabel =this.state.epsLabel.filter((ele, key, arr) => {
                return (
                    index !== key
                )
        })
        this.setState({
            epsLabel:epsLabel
        })
    }
    //删除图片
    delItemImg = e => {
        this.setState({ file: '' });
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        const { filePng,epsLabel,provinceList,cityList,areaList,file,agreementVisible,closable,typeList,text,markVisible} = this.state
        return (
            <div className={Styles.join_in}>
                <div className='top_title'>
                    <div className='return_wapper'>
                        <S.ColsePage_icon/>
                        <span onClick={() => this.props.history.goBack()}>关闭</span>
                    </div>
                    <span>企业申请入驻</span>
                </div>

                <div className="content">
                    <div className="content_title">您的位置：
                        <i style={{cursor: 'pointer'}} onClick={()=>this.props.history.goBack()}>企业中心</i> /
                        <span>企业申请入驻</span>
                    </div>
                    <div className="content_form">
                        <div className="warning_text">
                            <S.ExclamatoryMark/>
                            <div>请注意：当前登录的账号将默认成为企业入驻的管理账号。入驻申请成功后请使用当前登录的账号进行企业空间管理。</div>
                        </div>
                        <div className='title_two'>企业资质信息</div>
                        <div className="qualif-info">
                            <Form
                                className="upd_resource_form"
                            >
                                <Form.Item className="li_fo">
                                    <div className='name-form'>
                                        <div className='form_title'>
                                            <span><i className="red">*</i>企业名称</span>
                                            <Tooltip title={text}>
                                                <S.Jxmh_icon/>
                                            </Tooltip>
                                        </div>
                                        {getFieldDecorator("epsName", {
                                            initialValue: "",
                                            rules: [{ required: true, message: "请输入您的企业名称" }]
                                        })(
                                            <Input
                                                placeholder="请输入您的企业名称"
                                                maxLength={20}
                                                suffix={<span className='len'>{this.props.form.getFieldValue(`epsName`) ? this.props.form.getFieldValue(`epsName`).length : 0}/20</span>}/>)}


                                    </div>
                                </Form.Item>
                                <Form.Item  colon={false} label={"企业logo"} className="cover-form">
                                    <div className="upload-box">
                                        {
                                            getFieldDecorator('epsLogo', {
                                                initialValue: file ? file.fileId : '',
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: '上传的资源格式不符',
                                                    },
                                                ]
                                            })(
                                                <div className="up_button">
                                                    <div className="span_right">
                                                        <label htmlFor="file"  className="inputlabelBox">
                                                            <S.TianJia/>
                                                            <span>上传Logo</span>
                                                        </label>
                                                        {file && file.fileId ?
                                                            <div className="pic_img">
                                                                <span className="pic"><RenderImg size='m' id={file.fileId} type=''/></span>
                                                                <i className="i_deleticon"  onClick={this.delItemImg}><S.Del1/></i>
                                                            </div>
                                                            : ''}
                                                        <input
                                                            type="file"
                                                            accept="image"
                                                            onChange={this.multipartUpload}
                                                            name="myfile"
                                                            id="file"
                                                            style={{ display: "none" }}
                                                            onClick={(event)=> { event.target.value = null }}
                                                        />
                                                    </div>
                                                    <ul className="bottom_tit">
                                                        {/*上传中*/}
                                                        {/*{file? <Tooltip placement="topLeft" title={file.originName}><li className="title_de">{file.fileId? <Upiconwc />: <Spin />}<span className="tie_span">{file.originName}</span><i className="Upicona" onClick={this.deleteFile}><Upicondele/></i> </li></Tooltip>:null}*/}
                                                        {/*已完成*/}
                                                    </ul>
                                                </div>

                                            )
                                        }
                                        <div className="upload-text">支持jpg、png、gif、bmp图片格式，不超过5M，<br/>建议最佳尺寸160*️88px</div>
                                    </div>
                                </Form.Item>
                                <Form.Item  colon={false} label={"法人代表"} className="li_fo_bottom">
                                    {
                                        getFieldDecorator('legalPerson', {
                                            initialValue:'',
                                            rules: [
                                                {
                                                    required: true,
                                                    message: '请输入法人代表名称',
                                                },
                                            ]
                                        })(
                                            <Input
                                                placeholder="请输入法人代表名称"
                                                maxLength={10}
                                                suffix={<span className='len'>{this.props.form.getFieldValue(`legalPerson`) ? this.props.form.getFieldValue(`legalPerson`).length : 0}/10</span>}/>)

                                    }
                                </Form.Item>
                                <Form.Item  colon={false} label={"企业简介"} className="li_fo_bottom li_fo_TextArea">
                                    {
                                        getFieldDecorator('epsShortDesc', {
                                            initialValue:'',
                                            rules: [
                                                {
                                                    required: true,
                                                    message: '请输入企业简介',
                                                },
                                            ]
                                        })(
                                            <TextArea rows={3}
                                                placeholder="请输入企业简介"
                                                maxLength={500}/>)
                                    }

                                    <p className="font-numa">{this.props.form.getFieldValue(`epsShortDesc`) ? this.props.form.getFieldValue(`epsShortDesc`).length : 0}/500</p>
                                </Form.Item>
                                <Form.Item  colon={false} label={"企业类型"} className="li_fo_bottom">
                                    {
                                        getFieldDecorator('epsType', {
                                            initialValue:'',
                                            rules: [
                                                {
                                                    required: true,
                                                    message: '请选择企业类型',
                                                },
                                            ]
                                        })(
                                            <Select onChange={this.companyType} placeholder="请选择企业类型">
                                                {
                                                    typeList.length>0&&typeList.map((item,index)=>(
                                                        <Option key={item.key}>{item.value}</Option>
                                                    ))
                                                }

                                            </Select>)
                                    }
                                </Form.Item>
                                <Form.Item  colon={false} label={"企业标签"} className="li_top">
                                    {
                                        getFieldDecorator('epsLabel', {
                                            initialValue:'',
                                        })(
                                            <div className="mark-box">
                                                <div className="edit-box" onClick={this.deleTag}>
                                                    <S.Compileicon/>
                                                    <span>删除</span>
                                                </div>
                                                {/*<Button onClick={this.addLabel}>添加标签</Button>*/}
                                                <div className="add-mark" onClick={this.addLabel}>
                                                    <S.Choose_topic/>
                                                    <span>添加标签</span>
                                                </div>
                                                <span>提示：您最多添加三个标签</span>
                                            </div>
                                        )
                                    }
                                    <div className='tag-box'>
                                        {
                                            epsLabel && epsLabel.length > 0 ? epsLabel.map((item,index)=>(
                                                <Button key={index} className="ant_but" closable={closable} >{item}
                                                {closable === true ?
                                                    <Button className="cha_icon"
                                                                      onClick={() => this.deleTagList(index)}><S.Shanchuicon/></Button>:""}
                                                </Button>
                                            )) : null
                                        }

                                    </div>
                                </Form.Item>
                                <Form.Item  colon={false} label={"企业所在地"} className="li_fo_Select">
                                    {
                                        getFieldDecorator('address', {
                                            initialValue:'',
                                        })(
                                            <div className="right_cont">
                                                <div className="li_Select">
                                                    <Select
                                                        labelInValue= {true}
                                                        onChange={this.getCity} placeholder="请选择省">
                                                        {
                                                            provinceList && provinceList.length > 0 ? provinceList.map((item,index)=>(
                                                                <Option key={item.id} value={item.code}>{item.name}</Option>
                                                            )) : null
                                                        }

                                                    </Select>
                                                </div>
                                                <div className="li_Select">
                                                    <Select labelInValue= {true} onChange={this.getArea} placeholder="请选择市">
                                                        {
                                                            cityList && cityList.length > 0 && cityList.map((item,index)=>(
                                                                <Option key={item.id} value={item.code}>{item.name}</Option>
                                                            ))
                                                        }

                                                    </Select>
                                                </div>
                                                <div className="li_Select">
                                                    <Select labelInValue= {true} onChange={this.setCode} placeholder="请选择区">
                                                        {
                                                            areaList && areaList.length > 0 && areaList.map((item,index)=>(
                                                                <Option key={item.id} value={item.code}>{item.name}</Option>
                                                            ))
                                                        }

                                                    </Select>
                                                </div>
                                            </div>
                                        )
                                    }
                                </Form.Item>
                                <Form.Item  colon={false} label={"通讯地址"} className="li_fo_bottom">
                                    {
                                        getFieldDecorator('addr', {
                                            initialValue:'',
                                            rules: [
                                                {
                                                    required: true,
                                                    message: '请输入通讯地址',
                                                },
                                            ]
                                        })(
                                            <Input
                                                placeholder="请输入通讯地址"
                                                maxLength={50}
                                                suffix={<span className='len'>{this.props.form.getFieldValue(`addr`) ? this.props.form.getFieldValue(`addr`).length : 0}/50</span>}/>)

                                    }
                                </Form.Item>
                                <Form.Item  colon={false} label={"邮编"} className="li_fo_bottom">
                                    {
                                        getFieldDecorator('postalCode', {
                                            initialValue:'',
                                        })(
                                            <Input
                                                placeholder="请输入邮编"
                                                maxLength={10}
                                                suffix={<span className='len'>{this.props.form.getFieldValue(`postalCode`) ? this.props.form.getFieldValue(`postalCode`).length : 0}/10</span>}/>)

                                    }
                                </Form.Item>
                                <Form.Item  className="li_fo">
                                    <div className='name-form'>
                                        <div className='form_title'>
                                            <span><i className="red">*</i>统一社会信用代码</span>
                                            <Tooltip title={text}>
                                                <S.Jxmh_icon/>
                                            </Tooltip>
                                        </div>
                                        {getFieldDecorator("taxNum", {
                                            initialValue: "",
                                            rules: [{ required: true, message: "请输入统一社会信用代码" }]
                                        })(
                                            <Input
                                                placeholder="请输入统一社会信用代码"
                                                maxLength={18}
                                                suffix={<span className='len'>{this.props.form.getFieldValue(`taxNum`) ? this.props.form.getFieldValue(`taxNum`).length : 0}/18</span>}/>)}
                                    </div>

                                </Form.Item>
                                <Form.Item  colon={false} label={"工商营业执照"} className="cover-form cover-form_bottom">
                                    <div className="upload-box">
                                        {
                                            getFieldDecorator('businessLicense', {
                                                initialValue: filePng ? filePng.fileId : '',
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: '上传工商营业执照',
                                                    },
                                                ]
                                            })(
                                                <div className="up_button">
                                                    <div className="span_right">
                                                        <label htmlFor="file"  className="inputlabelBox">
                                                            <S.TianJia/>
                                                            <span>上传营业执照</span>
                                                        </label>
                                                        {filePng && filePng.fileId ? <span className="pic"><RenderImg size='m' id={filePng.fileId} type=''/></span> : ''}
                                                        <Upload
                                                            fileList={[]}
                                                            multiple={true}
                                                            beforeUpload={ this.beforeUploadTwo}
                                                            listType="picture-card"
                                                            showUploadList={false}
                                                            accept={'.jpg'}>
                                                            <Button> <S.TianJia/>上传营业执照</Button>
                                                        </Upload>
                                                    </div>
                                                    <ul className="bottom_tit">
                                                        {/*上传中*/}
                                                        {/*{file? <Tooltip placement="topLeft" title={file.originName}><li className="title_de">{file.fileId? <Upiconwc />: <Spin />}<span className="tie_span">{file.originName}</span><i className="Upicona" onClick={this.deleteFile}><Upicondele/></i> </li></Tooltip>:null}*/}
                                                        {/*已完成*/}
                                                    </ul>
                                                </div>

                                            )
                                        }
                                        <div className="upload-text">支持格式：<br/>
                                            jpg.、jpeg、png、bmp，大小不超过5M</div>
                                    </div>
                                </Form.Item>
                            </Form>
                        </div>
                        <Modal title='添加企业标签'
                               visible={markVisible}
                               destroyOnClose={true}
                               width='660px'
                               onCancel={() => this.setState({ markVisible: false })}
                               wrapClassName={Styles.Add_tags}
                               centered={true}
                               footer={
                                   <div className='pos-btn'>
                                       <Button className="but"
                                               onClick={() => this.setState({ markVisible: false })}>取消</Button>
                                       <Button type="primary" className="but"
                                               onClick={this.sureClick}>确定</Button>
                                   </div>
                               }
                        >
                            <div>
                                <AddMark wrappedComponentRef={form => {this.AddLabel = form;}}></AddMark>
                            </div>
                        </Modal>
                    </div>
                    <div className="content_form_two">
                        <div className='title_two people_title'>认证人信息</div>
                        <div className="information_form">
                            <Form
                                className="upd_resource_form"
                            >
                                <FormItem  colon={false} label={"联系人姓名"} className="li_fo_bottom">
                                    {
                                        getFieldDecorator('contactPeople', {
                                            initialValue:'',
                                            rules: [
                                                {
                                                    required: true,
                                                    message: '请输入联系人姓名',
                                                },
                                            ]
                                        })(
                                            <Input
                                                placeholder="请输入联系人姓名"
                                                maxLength={8}
                                                suffix={<span className='len'>{this.props.form.getFieldValue(`contactPeople`) ? this.props.form.getFieldValue(`contactPeople`).length : 0}/8</span>}/>)

                                    }
                                </FormItem>
                                <FormItem  colon={false} label={"联系人手机"} className="li_fo_bottom">
                                    {
                                        getFieldDecorator('contactPhone', {
                                            initialValue:'',
                                            rules: [
                                                {
                                                    required: true,
                                                    message: '请输入联系人手机',
                                                },
                                                {
                                                    pattern: /^(?:(?:\+|00)86)?1(?:(?:3[\d])|(?:4[5-7|9])|(?:5[0-3|5-9])|(?:6[5-7])|(?:7[0-8])|(?:8[\d])|(?:9[1|8|9]))\d{8}$/,
                                                    message: "请输入正确的手机号",
                                                },
                                            ]
                                        })(
                                            <Input
                                                placeholder="请输入联系人手机"
                                                maxLength={11}
                                                suffix={<span className='len'>{this.props.form.getFieldValue(`contactPhone`) ? this.props.form.getFieldValue(`contactPhone`).length : 0}/11</span>}/>)

                                    }
                                </FormItem>
                                <FormItem  colon={false} label={"联系人座机"} className="li_fo_bottom">
                                    {
                                        getFieldDecorator('totalTel', {
                                            initialValue:'',
                                        })(
                                            <Input
                                                placeholder="请输入联系人座机"
                                                maxLength={11}
                                                suffix={<span className='len'>{this.props.form.getFieldValue(`totalTel`) ? this.props.form.getFieldValue(`totalTel`).length : 0}/11</span>}/>)

                                    }
                                </FormItem>
                                <FormItem  colon={false} label={"电子邮箱"} className="li_fo_bottom">
                                    {
                                        getFieldDecorator('contactEmail', {
                                            initialValue:'',
                                            rules:[
                                                {
                                                    validator:this.validateInvestmailbox
                                                },
                                            ]
                                        })(
                                            <Input
                                                placeholder="请输入电子邮箱"
                                                maxLength={30}
                                                suffix={<span className='len'>{this.props.form.getFieldValue(`contactEmail`) ? this.props.form.getFieldValue(`contactEmail`).length : 0}/30</span>}/>)

                                    }
                                </FormItem>
                                <FormItem  colon={false} label={"入驻推荐方"} className="li_fo_bottom">
                                    {
                                        getFieldDecorator('recommender', {
                                            initialValue:'',
                                        })(
                                            <Input
                                                placeholder="请输入入驻推荐方"
                                                maxLength={20}
                                                suffix={<span className='len'>{this.props.form.getFieldValue(`recommender`) ? this.props.form.getFieldValue(`recommender`).length : 0}/20</span>}/>)

                                    }
                                </FormItem>
                            </Form>
                        </div>
                        <div className="checkbox">
                            <Checkbox onChange={this.onChange}>我已认真阅读并同意</Checkbox>
                            <span className='text' onClick={()=>{this.setState({agreementVisible:true})}}>《企业入驻协议》</span>
                        </div>
                        <Modal title='入驻协议'
                               className={Styles.agreement_modal}
                               width='1000px'
                               onCancel={() => this.setState({ agreementVisible: false })}
                               visible={agreementVisible}
                               centered={true}
                               footer={
                                   <div className='pos-btn'>
                                       <div className="foote_top">
                                           <Checkbox onChange={this.onChangeModal}>已查阅并同意遵守上述协议内容，并同意将所选资源共享至素材资源库</Checkbox>
                                       </div>
                                       <div className="foote_bottom">
                                           <Button className="but"
                                                   onClick={() => this.setState({ agreementVisible: false })}>拒绝</Button>
                                           <Button type="primary" className="but"
                                                   onClick={this.agreementClick}>同意</Button>
                                       </div>
                                   </div>
                               }
                        >
                            <div className={Styles.agreement_box}>
                                <b>
                                    亲爱的用户！我们依据相关规定制定了
                                    <span style={{color:"#3E78ED"}}>《资源共享服务协议》</span>
                                    和
                                    <span style={{color:"#3E78ED"}}>《资源共享隐私政策》</span>
                                    ，请您点击确定之前，仔细阅读并充分理解相关条款，方便您了解自己的权利。
                                </b>
                                <div>1.2004年取得学士学位授予权,时任河南省委书记的李克强视察学校时，称赞学校“用较短的时间，走完了可能是很多学校多年走不完的历史”。</div>
                                <div>2.2004年取得学士学位授予权,时任河南省委书记的李克强视察学校时，称赞学校“用较短的时间，走完了可能是很多学校多年走不完的历史。</div>
                                <div>3.黄河科技学院创办于1984年，本部位于河南省郑州市，是经教育部批准成立的一所民办普通本科高校。1994年成为经国家教委批准第一所实施专科学历教育的民办高校，2000年成为经教育部批准第一所实施本科学历教育的民办高校；</div>
                                <div>
                                    4.2004年取得学士学位授予权,时任河南省委书记的李克强视察学校时，称赞学校“用较短的时间，走完了可能是很多学校多年走不完的历史”。
                                    2013年被教育部批准为全国首批“应用科技大学改革试点战略研究单位”，并成为河南省首批转型发展试点高校；2014年，学校教改成果“民办高校应用型人才培养模式创新与实践”获得国家级教学成果二等奖；学校被教育部评为“全国毕业生就业典型经验高校”（全国高校毕业生就业工作50强）。2015年，学校“黄河众创空间”被科技部认定为全国首批众创空间。
                                </div>
                                <div>1.2004年取得学士学位授予权,时任河南省委书记的李克强视察学校时，称赞学校“用较短的时间，走完了可能是很多学校多年走不完的历史”。</div>
                                <div>2.2004年取得学士学位授予权,时任河南省委书记的李克强视察学校时，称赞学校“用较短的时间，走完了可能是很多学校多年走不完的历史”。</div>
                                <div>3.黄河科技学院创办于1984年，本部位于河南省郑州市，是经教育部批准成立的一所民办普通本科高校。1994年成为经国家教委批准第一所实施专科学历教育的民办高校，2000年成为经教育部批准第一所实施本科学历教育的民办高校；</div>
                                <div>4.黄河科技学院创办于1984年，本部位于河南省郑州市，是经教育部批准成立的一所民办普通本科高校。1994年成为经国家教委批准第一所实施专科学</div>
                            </div>
                        </Modal>
                        <div className="bottom_button">
                            <Button type='primary' onClick={this.addEnterpriseEnter}>提交申请</Button>
                            <Button onClick={()=>{this.props.history.push('/company')}}>取消申请</Button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
Join = Form.create()(Join);
