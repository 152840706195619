import React, { Component } from 'react'
import Cookies from 'js-cookie';
import {gerenzhongxin, logoutUrl, zjyIndex} from '../../config/index';
import Styles from "./index.module.scss";
import S from 'gongyongicon'
import RenderImg from '../../components/renderImg/index'
import {authorityList, userInfo} from '../../until/authorityList'
import {newsHostURL,zyzxUrl} from "../../config"
import './smartedu.css'
import { postActionlog } from '@/api/actionlog';
const _id_create = () => {
    return 'xxxxxx-xxx-4xxx-yxx-xxxxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16 | 0,
            v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}
const state = _id_create()
export default class index extends Component {
    state = {
        act: this.props.id ,
        jumpHome: "",
        change_nav: false,
        userId: ''
    }
    componentDidMount() {
        let { pathname } = this.props.location
        if (pathname === "/main" || pathname === "/demob" || pathname === "/democ" || pathname === "/demoe") {
            localStorage.setItem("jumpHome", pathname)
        }
        this.setState({ jumpHome: localStorage.getItem('jumpHome') || "/main" })
        window.addEventListener('scroll', this.bindHandleScroll)
        this.setState({
            userId: Cookies.get("info")? JSON.parse(decodeURI(Cookies.get("info"))).userId:''
        });
    }

    bindHandleScroll = (e) => {
        if (e.srcElement && e.srcElement.documentElement.scrollTop > 180) {
            return this.setState({ change_nav: true })
        }
        this.setState({ change_nav: false })
        if (e.srcElement && e.srcElement.documentElement.scrollTop > 45 && e.srcElement.documentElement.scrollTop < 170) {
            return this.setState({ isOpacoty: false })
        } else {
            this.setState({ isOpacoty: true })
        }
    }
    goroRegister = () =>{ // 注册
        window.location = `${gerenzhongxin+"/#/register"}?url=${window.location.origin}`
    }
    //登录
    handleLogin=()=>{
        window.location = `${logoutUrl}?url=${window.location.origin}`
    }
        // 获取地址栏参数
    GetQueryString(name) {
        const search = window.location.href;
        if (!search) return null;
        if (!search.split("?")[1]) return null;
        const item = search.split("?")[1].split("&");
        if (!item) return null;
        const fi = item.find((v) => v.includes(name))?.split("=")[1];
        if (item) {
            return fi ? decodeURI(fi) : undefined;
        } else {
            console.log(`${name} is not in search`);
            return null;
        }
    }
    //确定退出登录
    handleLogout = () => {
        this.hint({
            "title": '温馨提示',
            'type': 'warning',
            'content': `确定要退出登录吗？`,
            'cancelText': '取消',
            'okText': '确定',
            'isCancel': true,
            'sure': this.logout
        })
    };
    logout = () => { // 退出登录
        window.aplus_queue.push({ action: 'aplus.setMetaInfo', arguments: ["_user_id", null] });
        window.location = `${logoutUrl}?url=${window.location.origin}&out=true`
    }
    gotoIndex = () =>{ // 返回中教云首页
        window.open(`${zjyIndex}`)
    }
    gotoZixun = () => { // 跳到资讯列表页
        let organizationId = JSON.parse(localStorage.getItem('enterpriseInfo')) ? JSON.parse(localStorage.getItem('enterpriseInfo')).id : ''
        //window.open(newsHostURL + `news/nx_index.html?schoolId=${organizationId}&name=NX`)
        window.location = newsHostURL + `news/nx_index.html?schoolId=${organizationId}&name=NX`
    }
    //埋点2,平台操作行为统计 "action":1 //行为 1）登录；2）进入教学空间；（3）院校中心、院校空间；4）职教风采；5）大国工匠；6）智慧实训；7）资源中心；
    postaction = (aciton) =>{
        if(JSON.stringify(userInfo) != "{}" && userInfo.id != ''){
            let acitondata = {
                userId:userInfo.id,
                userName:userInfo.fullName,
                schoolId : userInfo.mainOrgId,
                schoolName: userInfo.mainOrgName,
                action : aciton,
            }
            console.log(acitondata,"acitondata")
            postActionlog(acitondata);
        }
    }
    render() {
        const { act, jumpHome, change_nav,userId } = this.state
        let href = window.location.href.split("#")[1]

        let hasSmartedu = window.location.href.indexOf('smartedu=smartedu') !== -1

        let smartedu = this.GetQueryString('smartedu')
        if(smartedu === 'smartedu' || hasSmartedu){
            return (
                <div className={Styles.demoeHeader_wrap}>
                <div className="smartedu_head">
                <div className="smartedu_top">
                    <div className="smartedu_logo_top">
                        <a className="smartedu_left_a" href="https://www.nxeduyun.com/portalUi/countyLogin/index.html?redirectUri=https://www.smartedu.cn&tab=5" target="_blank"></a>
                        <a className="smartedu_right_a" href="https://www.nxeduyun.com/portalUi/countyLogin/index.html?redirectUri=https://www.smartedu.cn&tab=5" target="_blank"></a>
                        <img  height="59px" src={require('../../assets/img/home_logo2.png')}/>
                        <p className="smartedu_logo2">宁夏智慧教育平台<br></br><span style={{letterSpacing: '-1px',fontSize: '20px'}}>Smart Education of Ningxia</span></p>
                    </div>
                    <p className="smartedu_login">
                        <a href="https://auth2.nxeduyun.com/oauth2/v1/connect/authorize?grant_type=authorization_code&amp;response_type=code&amp;client_id=903d4e9d-238f-438e-bd5a-5b4b4b534bf2&amp;state=952ef464-ec0f-4b60-9b42-0a24ab5f512d&amp;scope=openid,profile&amp;redirect_uri=https://edu.nxeduyun.com/portalapi/oauthLogin" target="_blank">登录</a>|
                        <a href="https://www.nxeduyun.com/portalUi/#/register/stage?ddtab=true" target="_blank">注册</a>
                    </p>
                    <div className="smartedu_nav">
                        <span onClick={
                            ()=>{ 
                                window.aplus_queue.push({
                                    'action':'aplus.record',
                                    'arguments':['category_click', 'CLK', {
                                      category_name:'首页',
                                      category_id:1
                                     }]
                                })
                                
                                window.open('https://nx.smartedu.cn/')
                            }
                        }>首页</span>
                        <span onClick={
                            ()=>{ 
                                window.aplus_queue.push({
                                    'action':'aplus.record',
                                    'arguments':['category_click', 'CLK', {
                                      category_name:'中小学智慧教育',
                                      category_id:2
                                     }]
                                })
                                window.open('https://basic.nx.smartedu.cn/')
                            }
                        }>中小学智慧教育</span>
                        <span onClick={
                            ()=>{ 
                                window.aplus_queue.push({
                                    'action':'aplus.record',
                                    'arguments':['category_click', 'CLK', {
                                      category_name:'智慧职教',
                                      category_id:3,
                                     }]
                                })
                               window.location = 'https://vocational.nx.smartedu.cn/'
                            }
                        }>智慧职教</span>
                        <span 
                        onClick={
                            ()=>{ 
                                window.aplus_queue.push({
                                    'action':'aplus.record',
                                    'arguments':['category_click', 'CLK', {
                                      category_name:'智慧高教',
                                      category_id:4
                                     }]
                                })
                                window.open('https://higher.nx.smartedu.cn/')
                            }
                        }>智慧高教</span>
                        <span 
                        onClick={
                            ()=>{ 
                                window.aplus_queue.push({
                                    'action':'aplus.record',
                                    'arguments':['category_click', 'CLK', {
                                      category_name:'服务大厅',
                                      category_id:5
                                     }]
                                })
                                window.open('https://employment.nx.smartedu.cn/')
                            }
                        }>服务大厅</span>
                        <span onClick={
                            ()=>{ 
                                window.aplus_queue.push({
                                    'action':'aplus.record',
                                    'arguments':['category_click', 'CLK', {
                                      category_name:'国家平台',
                                      category_id:6
                                     }]
                                })
                                window.open('https://www.nxeduyun.com/portalUi/countyLogin/index.html?redirectUri=https://www.smartedu.cn&tab=5')
                            }
                        }>国家平台</span>
                </div>
                </div>
            </div>
                <div className={'header_bottom'}>
                    <div className="demoeHeader_cont demoeHeader_cont_bottom">
                        <div className="nav_wrap">
                            <div className="nav_container" >
                                <ul>
                                <li
                                        className={act === 0 ? "act" : ''}
                                        onClick={() => {
                                            this.setState({ act: 0 })
                                            window.aplus_queue.push({ 
                                                'action':'aplus.record', 
                                                'arguments':['category_click', 'CLK', { category_name:"首页",category_id:"NX0201"}] 
                                            });
                                            window.location = 'https://vocational.nx.smartedu.cn/'
                                        }}>首页</li>
                                     <li
                                        className={href === '/courseList' ? "act" : ''}
                                        onClick={()=>{
                                            window.aplus_queue.push({ 
                                                'action':'aplus.record', 
                                                'arguments':['category_click', 'CLK', { category_name:"课程中心",category_id:"NX0203"}] 
                                            });
                                            window.location ='https://vocational.nx.smartedu.cn/courseList/#/main/courseList?id=65&smartedu=smartedu'
                                        }}
                                    >
                                        课程中心
                                    </li>
                                    <li
                                        className={href === '/resourceCentre' ? "act" : ''}
                                        onClick={()=>{
                                            window.aplus_queue.push({ 
                                                'action':'aplus.record', 
                                                'arguments':['category_click', 'CLK', { category_name:"素材中心",category_id:"NX0202"}] 
                                            });
                                            window.location = 'https://vocational.nx.smartedu.cn/material/#/main/material?id=68&smartedu=smartedu'  
                                        }}
                                    >
                                        素材中心
                                    </li>
                                    <li
                                        className={act === 6 ? "act" : ''}
                                        onClick={() => {
                                            this.setState({ act: 6 })
                                            window.aplus_queue.push({ 
                                                'action':'aplus.record', 
                                                'arguments':['category_click', 'CLK', { category_name:"匠人中心",category_id:"NX0206"}] 
                                            });
                                            window.location = 'https://vocational.nx.smartedu.cn/greatCountry/#/greatCountry?smartedu=smartedu'
                                            // this.props.history.push('/schoolCentre?smartedu=smartedu')
                                        }}>匠人中心</li>
                                    <li
                                        className={href ===  '/trainingResources' ? "act" : ''}
                                        onClick={() => {
                                            window.aplus_queue.push({ 
                                                'action':'aplus.record', 
                                                'arguments':['category_click', 'CLK', { category_name:"虚拟仿真资源",category_id:"NX0207"}] 
                                            });
                                            window.location = 'https://vocational.nx.smartedu.cn/trainingResources/#/main/trainingResources?id=58&smartedu=smartedu'
                                            // this.props.history.push('/schoolCentre?smartedu=smartedu')
                                        }}>虚拟仿真资源</li>
                                    <li
                                        className={act === 3 ? "act" : ''}
                                        onClick={() => {
                                            this.setState({ act: 3 })
                                            window.aplus_queue.push({ 
                                                'action':'aplus.record', 
                                                'arguments':['category_click', 'CLK', { category_name:"实训中心",category_id:"NX0205"}] 
                                            });
                                            window.location = 'https://vocational.nx.smartedu.cn/trainingCentre/#/trainingCentre?smartedu=smartedu'
                                            // this.props.history.push('/trainingCentre?smartedu=smartedu')
                                        }}>实训中心</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
            )
        }else{
            return (
                <div className={Styles.demoeHeader_wrap}>
                    <div className="demoeHeader_cont">
                        <div className="demoe_title_wrap">
                            <div className="title_container">
                                <div className="schoolName">
                                    {/* <img src={require('../../assets/democ/headerLogo.svg')}/> */}
                                    <img src={require('../../assets/democ/headerzj.jpg')}/>
                                    <p className='schoolTitle' style={{marginLeft:'4px'}}>
                                    <span className='zh'>宁夏职业教育云平台</span>
                                    <span className='en'>NINGXIA VOCATIONAL EDUCATION CLOUD PLATFORM</span>   
                                    </p>
                                </div>
                                <div className="header_right">
                                    <div className="functionBtns">
                                        <div className='return_box' onClick={()=>this.gotoIndex()}><S.Return1/><span>返回教育云平台</span></div>
                                        {/*<div className='return_box' onClick={() => {*/}
                                        {/*    this.setState({ act: 8 })*/}
                                        {/*    this.props.history.push('/appDownload')*/}
                                        {/*}}>*/}
                                        {/*</div>*/}
                                        {
                                            userInfo.id?'':
                                                <div className="login_box">
                                                    <span onClick={this.handleLogin}>登录</span>
                                                    {/* <span onClick={this.goroRegister}>注册</span> */}
                                                </div>
    
                                        }
    
                                    </div>
                                    {
                                        userInfo.id?
                                            <>
                                                <div className='nx_btn_box'>
                                                    <div className={'goto_btn'}><S.Menhicon/>进入云门户</div>
                                                    {
                                                        authorityList.length?(
                                                            <div className='menu_right_down'>
                                                                {authorityList.map((v, i) => {
                                                                    return <div
                                                                        key={i}
                                                                        className='menu_right_down_item'
                                                                        onClick={()=>{
                                                                            if(v.name == "进入教学空间"){
                                                                                //埋点2,平台操作行为统计 "action":1 //行为 1）登录；2）进入教学空间；（3）院校中心、院校空间；4）职教风采；5）大国工匠；6）智慧实训；7）资源中心；
                                                                                this.postaction(6)
                                                                            }
                                                                            window.location = v.url;
                                                                        }}
                                                                    >
                                                                        {v.name}
                                                                    </div>
                                                                })}
                                                            </div>
                                                        ):""
                                                    }
    
                                                </div>
                                                <div className='menu_right'>
                                                    <RenderImg size='m' id={userInfo.portraitId} type={userInfo.suffix}/>
                                                    <div className="user-right">
                                                        <p className="userinfo">{userInfo.humanName || userInfo.fullName || ""}</p>
                                                        <img className="icon" src={require('../../assets/democ/userInfoIcon.svg')} />
                                                    </div>
                                                    <div className='menu_right_down'>
                                                        <div className='menu_right_down_item' onClick={this.handleLogout}><S.Gbiocn/>退出登录</div>
                                                    </div>
    
                                                </div>
                                            </>:''
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={'header_bottom'}>
                        <div className="demoeHeader_cont demoeHeader_cont_bottom">
                            <div className="nav_wrap">
                                <div className="nav_container">
                                    <ul>
                                        <li
                                            className={act === 0 ? "act" : ''}
                                            onClick={() => {
                                                this.setState({ act: 0 })
                                                this.props.history.push(jumpHome)
                                            }}>首页</li>
                                        <li
                                            className={act === 4 ? "act" : ''}
                                            onClick={() => {
                                                //埋点2,平台操作行为统计 "action":1 //行为 1）登录；2）进入教学空间；（3）院校中心、院校空间；4）职教风采；5）大国工匠；6）智慧实训；7）资源中心；
                                                this.postaction(4)
                                                this.setState({ act: 4 })
                                                this.gotoZixun()
                                            }}>职教动态</li>
                                        <li
                                            onClick={() => {
                                                this.props.history.push('/noticeList')
                                            }}>通知公告</li>
                                        <li
                                            className={act === 6 ? "act" : ''}
                                            onClick={() => {
                                                //埋点2,平台操作行为统计 "action":1 //行为 1）登录；2）进入教学空间；（3）院校中心、院校空间；4）职教风采；5）大国工匠；6）智慧实训；7）资源中心；
                                                this.postaction(5)
                                                this.setState({ act: 6 })
                                                this.props.history.push('/greatCountry')
                                                
                                            }}>大国工匠</li>
                                        <li
                                            className={act === 3 ? "act" : ''}
                                            onClick={() => {
                                                //埋点2,平台操作行为统计 "action":1 //行为 1）登录；2）进入教学空间；（3）院校中心、院校空间；4）职教风采；5）大国工匠；6）智慧实训；7）资源中心；
                                                this.postaction(6)
                                                // this.setState({ act: 3 })
                                                this.props.history.push('/trainingCentre')
                                                // window.location = 'https://college.zj.nxeduyun.com/training/?schoolId=b267bc47cdae4dfcb212cb247f0b0943&name=CS&nav=%25E5%25AE%259E%25E8%25AE%25AD'
                                            }}>智慧实训</li>
                                        <li
                                            className={href === '/resourceCentre' ? "act" : ''}
                                            onClick={()=>{
                                                    //埋点2,平台操作行为统计 "action":1 //行为 1）登录；2）进入教学空间；（3）院校中心、院校空间；4）职教风采；5）大国工匠；6）智慧实训；7）资源中心；
                                                    this.postaction(7)
                                                    window.location = zyzxUrl
                                                }
                                            }
                                        >
                                            资源中心
                                        </li>
                                 
                                        <li
                                        className={act === 6 ? "act" : ''} 
                                        onClick={() => {
                                            this.setState({ act: 6 }) 
                                            this.props.history.push('/apply') 
                                        }}>应用中心</li> 

                                        <li
                                            className={act === 2 ? "act" : ''}
                                            onClick={() => {
                                                //埋点2,平台操作行为统计 "action":1 //行为 1）登录；2）进入教学空间；（3）院校中心、院校空间；4）职教风采；5）大国工匠；6）智慧实训；7）资源中心；
                                                this.postaction(3)
                                                this.setState({ act: 2 })
                                                this.props.history.push('/schoolCentre')
                                            }}>院校中心</li>
    
                                        <li
                                            className={act === 7 ? "act" : ''}
                                            onClick={() => {
                                                this.setState({ act: 7 })
                                                this.props.history.push('/company')
                                            }}>企业中心</li>
    
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            )
        }
    }
}
