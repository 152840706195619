import { post,get } from '../../until/axios_instans';

//获取按钮权限
export function getButtonRole(data){
    return post('/api/ucenter/api/v1/permission/buttons',data);
}

//获取菜单权限
export function getMenu(data){
    return post('/api/ucenter/api/v1/permission/routers',data);
}

//动态设置title  icon
export let schoolInfo = (data) => {
    return get("/api/university/portal/schoolInfo/"+data)
}

// 获取用户角色列表
export let getUserRole = () => {
    return post("/api/gongyong/api/v1/address/identityIdEnum")
}

// 获取个人信息接口
export let getUserInfo = () => {
    return post("/api/ucenter/api/v1/verification/effective")
}
