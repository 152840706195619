import axios from "axios"

// 在校学生数量
export const ProvincialDataBasic = () => {
    return axios.get(`${process.env.REACT_APP_edums_getWay}/nx/index.php?r=DeveloperDataCenter/ProvincialDataBasic&is_debug=1&c_user_id=2`)
}
// 院校师生总数量
export const ProvincialData = () => {
    return axios.get(`${process.env.REACT_APP_edums_getWay}/nx/index.php?r=DeveloperDataCenter/ProvincialData&is_debug=1 &c_user_id=2`)
}
