import { post } from "../../until/axios_instans";

// 获取门户列表
export function getEnterpriseEnterPortal(data) {
  return post('/api/es/api/v1/portal/esEnterprise/getEnterpriseEnterPortal', data);
}
// 企业入驻
export function addEnterpriseEnter(data) {
  return post(window.apiNx + '/api/v1/nx/enterpriseEnter/addEnterpriseEnter', data);
}
// 获取省市区
export function getAddr(data) {
  return post(window.apiNx + '/api/v1/nx/enterpriseEnter/getAddr', data);
}
// 获取审核状态
export function getEnterpriseAudit(data) {
  return post(window.apiNx + '/api/v1/nx/enterpriseEnter/getEnterpriseAudit', data);
}


