import React from 'react';
import './index.scss';
import {Link} from 'react-router-dom';
import { queryOrgCollege} from "@/api/certificate";
import { baseURL } from '@/config/index.js'
import {Col, Modal, Tabs} from "antd";
// import loopFix from "swiper/src/components/core/loop/loopFix";
const {TabPane} = Tabs;
function callback(key) {
    console.log(key);
}
export default class IssCard extends React.Component {
    state = {
        querylist:[],
        orgCollegeList:[],
        remarkShow:false,
        pageNum:1,
        pageSize:12,
        total:0,
        polotModal:false,
        ModalTye:1,
        polotModalContent:"",
    }
    componentDidMount() {
        this.getIssuingList(this.props.item.id)
    }
    componentWillReceiveProps(nextProps) {
        if(nextProps.item.id!=this.props.item.id){
            this.getIssuingList(nextProps.item.id)
            console.log("nextPropsnextPropsnextProps",nextProps)
        }
    }
    allHeight=()=>{
        const {orgCollegeList}=this.state
        const {item}=this.props
        orgCollegeList.map((todo,index)=>{
           let ele=document.getElementById(`cardId${item.id}${index}`)
           let  spreadEle=document.getElementById(`spread${item.id}${index}`)
            if(ele.offsetHeight>80){
                ele.style.height=80+"px"
                spreadEle.style.display="block";
            }
        })
    }
    //展开全部
    showAllText=(key)=>{
        const {item}=this.props
        let ele=document.getElementById(`cardId${item.id}${key}`)
        let packupEle=document.getElementById(`packup${item.id}${key}`)
        let  spreadEle=document.getElementById(`spread${item.id}${key}`)
        ele.style.height=''
        packupEle.style.display="block"
        spreadEle.style.display="none"
    }
    //收起全部
    packUpText=(key)=>{
        const {item}=this.props
        let ele=document.getElementById(`cardId${item.id}${key}`)
        let packupEle=document.getElementById(`packup${item.id}${key}`)
        let  spreadEle=document.getElementById(`spread${item.id}${key}`)
        ele.style.height=80+'px'
        packupEle.style.display="none"
        spreadEle.style.display="block"
    }

    async  getIssuingList(id){
        let orgCollegeList = await queryOrgCollege({"orgId":id});
      this.setState({
          orgCollegeList}
          ,()=>{
          // this.allHeight()
          })
    }
    reBytesStr (str) {
        str=str.replace(/<\/?.+?>/g,"").replace(/ /g,"").replace(/&(\S*)?;/g,"")
        if((!str && typeof(str) != 'undefined')) {
            return '';
        }
        var num = 0;
        var str1 = str;
        var str = '';
        for(var i = 0, lens = str1.length; i < lens; i++) {
            num += ((str1.charCodeAt(i) > 255) ? 2 : 1);
        }
        return num
    }
    toggleForm=(item,type)=>{
        const  {remarkShow}=this.state
        console.log('orgCollegeList',this.state.orgCollegeList)
        this.setState({
            remarkShow:!remarkShow,
            polotModal:true,
            polotModalContent:type==1?item.remark:this.state.orgCollegeList,
            ModalTye:type,
            polotModalTitle:item.orgName,
        },()=>{
            // let ele=document.getElementById(`intro${this.props.index}`)
            // if(!this.state.remarkShow){
            //     ele.style.height=40+'px'
            // }else{
            //     ele.style.height=""
            // }

        })
    }
    cancel=()=>{
        this.setState({
            polotModal:false,
        })
    }
    render() {
        const {orgCollegeList,remarkShow,cityListVal,cityList,polotModal,collegeList} =this.state
        const {item,index}=this.props
        return (
                            <div className='lilotpage-list' key={index}>
                                <div  className='lilotpage-top'>
                                    <span className="icon">
                                        {
                                            item.logoFileId ?
                                                <img src={`${baseURL}${item.logoFileId}`}/>:<img src={require('../default-img3.png')}/>
                                        }
                                    </span>
                                    <h2 className='lilotpage-name'>{item.orgName}</h2>
                                </div>
                                <div className="lilotpage-cont lilotpage-bottom ">
                                    <label className='lable-name'>机构简介：</label>
                                    <div className='cont-right cont-righta'>
                                        <div className="ul-div">
                                            <ul className="list-ul list-ul-1"  id={`intro${this.props.index}`} style={{"height":"40px"}} >
                                                <li>{item.remark}</li>
                                                {/*{remarkShow? <Link className='lilotpage-link' onClick={this.toggleForm}>收起详情</Link>:<Link className='lilotpage-link' onClick={this.toggleForm}>查看详情</Link>}*/}
                                            </ul>
                                            <Link className='lilotpage-link'  onClick={()=>{this.toggleForm(item,1)}}>查看详情</Link>
                                        </div>
                                    </div>
                                </div>


                             {/*   <div className='lilotpage-cont'>
                                        <label className='lable-name'>机构简介：</label>
                                      <div id={`intro${this.props.index}`} style={{"height":"40px"}}  className='cont-right'><p>{item.remark}</p></div>
                                </div>*/}


                                <div className='lilotpage-cont lilotpage-conta'>
                                    <label className='lable-name'>试点证书：</label>
                                    <div className='cont-right'>
                                        {item.certArr.map((certItem, index) => {
                                            return (
                                                <span className='zs-namelist'>{certItem}</span>
                                            );
                                        })}
                                    </div>
                                </div>
                                <div className="lilotpage-cont lilotpage-bottom">
                                    <label className='lable-name'>试点院校：</label>
                                    <div className='cont-right'>
                                        {orgCollegeList.map((todo,key)=> {
                                                return (
                                                    <div className="ul-div">
                                                        <h3>{todo.collegeType}（{todo.collegeNum}所)</h3>
                                                        <ul className="list-ul" id={`cardId${item.id}${key}`}>
                                                            {/*<span style={{"display":"none"}}  id={`spread${item.id}${key}`} className='lilotpage-link' onClick={()=>{this.showAllText(key)}}>展开全部</span>*/}
                                                            {/*<span style={{"display":"none"}}  id={`packup${item.id}${key}`} className='lilotpage-link' onClick={()=>{this.packUpText(key)}}>收起全部</span>*/}
                                                            {todo.collegeList.map((secondTiem,m)=>{
                                                                return <li>{secondTiem}</li>
                                                            })}
                                                        </ul>
                                                    </div>
                                                )
                                            }
                                        )}
                                        {/*<span className='lilotpage-link' onClick={()=>{this.toggleForm(item,2)}}>查看更多</span>*/}
                                    </div>
                                </div>
                                <Modal className=' Modal-Createpoll Modal-Createpolla pilotpage' width="1000px" visible={polotModal} destroyOnClose={true}
                                       maskClosable={false}
                                       title={
                                           <div>
                                               {this.state.ModalTye==1? <div className="div-title">
                                                       <div className="h3">{this.state.polotModalTitle}</div>
                                                   </div>:""}
                                           </div>
                                       }
                                       onCancel={this.cancel}
                                       centered={true}
                                       closeIcon={<img src={require("../../../../assets/certificate/icon_close.png")}/>}
                                       footer={null}
                                >
                                    {this.state.ModalTye==1?  <div className="polotModal-content">
                                        {this.state.polotModalContent}
                                    </div>: <ul className="polotModal-lista">
                                        <Tabs defaultActiveKey="1" onChange={callback}>
                                            <TabPane tab={
                                                <div>中职院校<span className="span">（{this.state.polotModalContent[0]? this.state.polotModalContent[0].collegeNum:0}所)</span> </div>
                                            } key="1">
                                                {this.state.polotModalContent[0] ?
                                                    this.state.polotModalContent[0].collegeList.map((item,index)=>{
                                                    return(<span>
                                                        {item}  、
                                                    </span>)
                                                })
                                                : ''}
                                            </TabPane>
                                            <TabPane tab={
                                                <div>高职院校<span className="span">（{this.state.polotModalContent[1]?this.state.polotModalContent[1].collegeNum:0}所)</span> </div>
                                            } key="2">
                                                { this.state.polotModalContent[1] ?
                                                    this.state.polotModalContent[1].collegeList.map((item,index)=>{
                                                    return(<span>
                                                        {item}  、
                                                    </span>)
                                                }): ''}
                                            </TabPane>
                                            <TabPane tab={
                                                <div>本科院校<span className="span">（{this.state.polotModalContent[2]?this.state.polotModalContent[2].collegeNum:0}所)</span> </div>
                                            } key="3">
                                                { this.state.polotModalContent[2] ?
                                                    this.state.polotModalContent[2].collegeList.map((item,index)=>{
                                                    return(<span>
                                                        {item}  、
                                                    </span>)
                                                }):''}
                                            </TabPane>
                                        </Tabs>


                                    </ul>}
                                </Modal>
                            </div>

        )
    }
}
