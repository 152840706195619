import React, { Component } from 'react'
import Footer from '../../../components/footer'
import {selectNoticeById} from "../../../api/schoolUrl"
import Header from "../../../components/demoeHeader";
import style from './index.module.scss'
import S from "gongyongicon";
export default class NoticeDetile extends Component {
    state={
        noticeData: [],
        noticeId:'',
        fileList:[]
    }
    componentDidMount() {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
        const data = this.props.location.search; //地址栏截取
        const param = data.split("?")[1];
        if (param) {
            const codeParam = param.includes("%") ? decodeURI(param) : param;
            const jsonparam = JSON.parse(codeParam);
            this.setState({
                noticeId: jsonparam.id
            }, () => {
                this.selectNoticeById()
            })
        }
    }
    selectNoticeById=async (e)=>{
        await selectNoticeById({id:this.state.noticeId}).then(e=>{
            if(e){
                console.log(e)
                this.setState({
                    noticeData:e,
                    total:e.total,
                    fileList:e.enclosureQoList
                })
            }
        })
    }
    //文件下载
    downloadFile=(index)=>{
        let fileName=this.state.fileList[index].fileName
        let resourceId=this.state.fileList[index].resourceId
        console.log(fileName,this.state.fileList[index])
        const url = window.$$gw('//api/fujian/api/v1/fujian/download'+'/'+resourceId+ '/'+ fileName);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        link.click();
    }
    render() {
        let {noticeData,fileList}=this.state
        return (
            <div className={style.lookNotice}>
                <Header {...this.props} />
                <div className='lookNotice_span'>
                    <span>您的位置：
                        <span className={'isClick'} onClick={()=>this.props.history.push('/')}>首页 </span> /
                        <span className={'isClick'} onClick={()=> this.props.history.push('/noticeList')}>通知公告</span> /
                        <i>公告详情</i>
                    </span>
                </div>
                <div className='main_box'>
                    <div>
                        <div className='news_title'>
                            {noticeData.noticeTitle}
                        </div>
                        <div className='bq_box'>
                            <span>{noticeData.createTime}</span>
                            <span className='autherInfo'>
                               {noticeData.noticeAuthor}
                            </span>
                        </div>
                        {/*<div className='zy_box'>*/}
                        {/*    <div className='zy_title'>摘要</div>*/}
                        {/*    <div className='zy_con'>*/}
                        {/*        {noticeData.noticeAbstract}*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        <div className="cont_text">
                            <div dangerouslySetInnerHTML={{__html: noticeData.noticeContent}}/>
                        </div>
                        <div className="enclosure">
                            <div className="line"></div>
                            <div className="content">
                                <div className="title">
                                    <div className="text_line"></div>
                                    <div className="text">附件</div>
                                </div>
                                <div className="file_box">
                                    {
                                        fileList&&fileList.map((item,index)=>(
                                            <div className="box_in">
                                                {
                                                    item.suffix=="ppt"||item.suffix=="pptx"?
                                                        <S.Appt/>:item.suffix=="doc"||item.suffix=="docx"?<S.WordSvg/>:
                                                        item.suffix=="pdf"?<S.Apdf/>:
                                                            item.suffix=="xls"||item.suffix=="xlsx"?<S.Axlse/>:<S.Aunknown/>
                                                }
                                                <div className="box_in_content" onClick={()=>this.downloadFile(index)}>
                                                    <div className="box_in_content_text">{item.fileName}</div>
                                                </div>
                                                <div className="down_load">

                                                    <S.DownIcon/>
                                                    <span onClick={()=>this.downloadFile(index)}>下载</span>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        )
    }
}
