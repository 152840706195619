export let loginUrl = ''//登录中心
export let zjyIndex = ''// 教育云首页
//export let baseURL = 'https://zjapi.educs.com.cn'//axios baseURL
export let baseURL = 'https://gw.zj.nxeduyun.com'//axios baseURL
export let newsHostURL = ''//咨询跳转地址
export let logoutUrl = '' // 退出登录
export let gerenzhongxin = '' // 个人中心
export let zyzxUrl = ''
export const setLoginUrl = (url) => {
    loginUrl = url
}
export const setZjyIndex = (url) => {
    zjyIndex = url
}
export const setBaseURL = (url) => {
    baseURL = url
}
export const setLogoutUrl = (url) => {
    logoutUrl = url
}
export const setGerenzhongxin = (url) => {
    gerenzhongxin = url
}
export const setNewsHostURL = (url) => {
    newsHostURL = url
}
export const setZyzxUrl = (url) => {
    zyzxUrl = url
}


// 依赖07接口
export const initUrlByType07 = (config) => {
    setLoginUrl(config?.sys?.logincenter ?? '')
    setZjyIndex('https://www.nxeduyun.com')
    setBaseURL(config?.gw ?? '')
    setLogoutUrl(config?.sys?.logoutcenter ?? '')
    setGerenzhongxin(config?.sys?.gerenzhongxin ?? '')
    let curUrl=window.location.href
    var regExp=/#(\S*)/;
    curUrl= curUrl.replace(regExp,"") // 获取当前域名
    setNewsHostURL(curUrl)
    setZyzxUrl(config?.sys?.shengbenziyuanku?? '')
}
