/*
 * @Author: your name
 * @Date: 2021-11-23 16:47:22
 * @LastEditTime: 2021-11-23 17:16:51
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \zjyd-front-ningxia-portal\src\page\main\certificate\index.js
 */
import React, { Component } from "react";
import "./index.scss";
import RouteView from "@/router/router_view";
import Heder from "./components/header/index";
import Footer from "./components/footer/index"

export default class index extends Component {
  state = {};
  render() {
    return (
      <div className="resource_main">
          <Heder />
        <div className="resource_container">
          <RouteView routers={this.props.routers} />
          <div className="resource_footer">
            <Footer />
          </div>
        </div>
      </div>
    );
  }
}
