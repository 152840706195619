import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Table } from 'antd';
import './index.scss';

export default class index extends Component {
    state = {
        columns: [
            {
                title: '序号',
                key: 'index',
                dataIndex: 'index',
                align: 'center',
                render: (text, item, index) => {
                    return <span>{index + 1}</span>
                }
            },
            {
                title: '培训评价组织名称',
                dataIndex: 'title',
                key: 'title',
                align: 'center'
            },
            {
                title: '证书名称',
                dataIndex: 'value',
                key: 'value',
                align: 'center'
            }
        ],
        dataSource: [{ "title": "北大荒农垦集团有限公司", "value": "家庭农场畜禽养殖" },
        { "title": "北大荒农垦集团有限公司", "value": "家庭农场粮食生产经营" },
        { "title": "北大荒农垦集团有限公司", "value": "农业经济组织经营管理" },
        { "title": "山东省寿光蔬菜产业集团有限公司", "value": "设施蔬菜生产" },
        { "title": "北京中农服农业科技股份有限公司", "value": "农产品供应链与品牌管理" },
        { "title": "北京化育求贤教育科技有限公司", "value": "化工精馏安全控制" },
        { "title": "北京化育求贤教育科技有限公司", "value": "化工危险与可操作性（HAZOP）分析" },
        { "title": "广州南方测绘科技股份有限公司", "value": "测绘地理信息数据获取与处理" },
        { "title": "广州南方测绘科技股份有限公司", "value": "测绘地理信息智能应用" },
        { "title": "福建金创利信息科技发展股份有限公司", "value": "不动产数据采集与建库" },
        { "title": "北京市中煤教育科贸公司", "value": "煤矿智能化开采" },
        { "title": "北京市中煤教育科贸公司", "value": "矿山应急救援" },
        { "title": "北京市中煤教育科贸公司", "value": "煤炭清洁高效利用" },
        { "title": "国家电网有限公司", "value": "电力电缆安装运维" },
        { "title": "国家电网有限公司", "value": "输电线路施工及运维" },
        { "title": "国家电网有限公司", "value": "继电保护检修" },
        { "title": "国家电网有限公司", "value": "变配电运维" },
        { "title": "国家电网有限公司", "value": "配电线路运维" },
        { "title": "国家电网有限公司", "value": "装表接电" },
        { "title": "国家电网有限公司", "value": "变电一次安装" },
        { "title": "国家电网有限公司", "value": "变电二次安装" },
        { "title": "国家电网有限公司", "value": "电力系统营销服务" },
        { "title": "广东南方电力科学研究院有限公司", "value": "用户侧微电网工程应用" },
        { "title": "博努力（北京）仿真技术有限公司", "value": "发电集控运维" },
        { "title": "苏宁帮客科技服务有限公司", "value": "新能源充电设施安装与维护" },
        { "title": "深圳百泰投资控股集团有限公司", "value": "贵金属首饰执模" },
        { "title": "北京诺斐释真管理咨询有限公司", "value": "贵金属首饰制作与检验" },
        { "title": "中国轻工业联合会", "value": "鞋类设计与制作" },
        { "title": "北京锦达科教开发总公司", "value": "服装陈列设计" },
        { "title": "北京锦达科教开发总公司", "value": "纺织面料开发" },
        { "title": "北控水务（中国）投资有限公司", "value": "智能水厂运行与调控" },
        { "title": "北控水务（中国）投资有限公司", "value": "水环境监测与治理" },
        { "title": "中国水利水电第八工程局有限公司", "value": "土木工程混凝土材料检测" },
        { "title": "黄河万家寨水利枢纽有限公司", "value": "大坝安全智能监测" },
        { "title": "中电建生态环境集团有限公司", "value": "地表水（河湖库湾）水质监测" },
        { "title": "广州城市轨道交通培训学院股份有限公司", "value": "城市轨道交通变电检修" },
        { "title": "广州城市轨道交通培训学院股份有限公司", "value": "城市轨道交通信号检修" },
        { "title": "神州高铁技术股份有限公司", "value": "轨道交通车辆检修" },
        { "title": "南京地铁集团有限公司", "value": "城市轨道交通接触网维护" },
        { "title": "南京地铁集团有限公司", "value": "城市轨道交通通信设备维护" },
        { "title": "南京地铁集团有限公司", "value": "城市轨道交通线路维护" },
        { "title": "北京智联友道科技有限公司", "value": "轨道交通车辆机械维护" },
        { "title": "郑州捷安高科股份有限公司", "value": "城市轨道交通车辆维护和保养" },
        { "title": "北京全路通信信号研究设计院集团有限公司", "value": "轨道交通自动控制系统装备运营维护" },
        { "title": "湖南省交通科学研究院有限公司", "value": "道路机电设备装调与运维" },
        { "title": "北京和利时系统工程有限公司", "value": "列车运行控制系统车载设备运用与维护" },
        { "title": "北京和利时系统工程有限公司", "value": "列车运行控制系统现场信号设备运用与维护" },
        { "title": "北京中铁科客货运输技术有限公司", "value": "多式联运组织与管理" },
        { "title": "北京博维航空设施管理有限公司", "value": "旅客登机桥维修" },
        { "title": "北京首都机场航空安保有限公司", "value": "民航安全检查" },
        { "title": "江苏无国界航空发展有限公司", "value": "民用航空器航线维修" },
        { "title": "北京翔宇教育咨询有限公司", "value": "民航空中服务" },
        { "title": "北京空港航空地面服务有限公司", "value": "民用机场航班地面保障" },
        { "title": "中汇空港（北京）教育科技发展有限责任公司", "value": "机场运行管理" },
        { "title": "中国理货协会", "value": "港口理货" },
        { "title": "广西汽车集团有限公司", "value": "国六柴油车养护诊断" },
        { "title": "北京祥龙博瑞汽车服务（集团）有限公司", "value": "车身智能焊接" },
        { "title": "北京运华科技发展有限公司", "value": "网约车运营管理" },
        { "title": "中汽数据有限公司", "value": "智能汽车大数据管理与应用" },
        { "title": "北京新能源汽车股份有限公司", "value": "电动汽车高电压系统评测与维修" },
        { "title": "国汽（北京）智能网联汽车研究院有限公司", "value": "智能网联汽车共享出行服务" },
        { "title": "北京卓创至诚技术有限公司", "value": "新能源汽车装调与测试" },
        { "title": "北京国人通教育科技有限公司", "value": "在线学习服务" },
        { "title": "北京安达思创管理顾问有限公司", "value": "青少年劳动教育项目开发与实施" },
        { "title": "新华国采教育网络科技有限责任公司", "value": "器乐艺术指导" },
        { "title": "东北师大理想软件股份有限公司", "value": "生涯规划指导" },
        { "title": "杭州快搜才网络科技有限公司", "value": "职场拓展" },
        { "title": "北京三快在线科技有限公司", "value": "酒店收益管理" },
        { "title": "上海棕榈电脑系统有限公司", "value": "旅游大数据分析" },
        { "title": "携程旅游网络技术（上海）有限公司", "value": "定制旅行管家服务" },
        { "title": "中国旅游协会", "value": "旅行策划" },
        { "title": "中国饭店协会", "value": "酒店运营管理" },
        { "title": "华住酒店管理有限公司", "value": "现代酒店服务质量管理" },
        { "title": "北京中凯国际研学旅行股份有限公司", "value": "研学旅行课程设计与实施" },
        { "title": "北京首都旅游集团有限责任公司", "value": "前厅运营管理" },
        { "title": "北京首都旅游集团有限责任公司", "value": "餐饮服务管理" },
        { "title": "新疆芳葡香思教育咨询有限公司", "value": "葡萄酒推介与侍酒服务" },
        { "title": "中国饭店协会", "value": "餐饮管理运行" },
        { "title": "网育网（北京）国际教育科技发展中心", "value": "会展管理" },
        { "title": "广东省餐饮技师协会", "value": "粤菜制作" },
        { "title": "广东省餐饮技师协会", "value": "粤点制作" },
        { "title": "完美世界教育科技（北京）有限公司", "value": "电子竞技赛事运营" },
        { "title": "北京外研在线数字科技有限公司", "value": "实用英语交际" },
        { "title": "北京速录科技有限公司", "value": "中文速录" },
        { "title": "中宝评（北京）教育科技有限公司", "value": "珠宝首饰设计" },
        { "title": "武汉学苑珠宝科技有限公司", "value": "电脑首饰设计" },
        { "title": "北京色彩时代商贸有限公司", "value": "人物化妆造型" },
        { "title": "四川蓝宇档案管理服务有限公司", "value": "档案数字化加工" },
        { "title": "北京中民福祉教育科技有限责任公司", "value": "婚礼策划" },
        { "title": "北京中民福祉教育科技有限责任公司", "value": "社区治理" },
        { "title": "北京中民福祉教育科技有限责任公司", "value": "遗体防腐整容" },
        { "title": "北京人民在线网络有限公司", "value": "舆情监测与分析" },
        { "title": "贝壳找房（北京）科技有限公司", "value": "新居住数字化经纪服务" },
        { "title": "北京中民福祉教育科技有限责任公司", "value": "殡仪服务" },
        { "title": "上海踏瑞计算机软件有限公司", "value": "人力资源共享服务" },
        { "title": "北京乐智启航文化发展有限公司", "value": "智慧后勤运营" },
        { "title": "启端（北京）教育科技有限公司", "value": "人力资源数字化管理" },
        { "title": "北京中标赛宇科技有限公司", "value": "标准编审" },
        { "title": "北京中文在线教育科技发展有限公司", "value": "社会心理服务" },
        { "title": "中国光大实业（集团）有限责任公司", "value": "老年护理服务需求评估" },
        { "title": "济南阳光大姐服务有限责任公司", "value": "家庭保健按摩" },
        { "title": "湖南金职伟业母婴护理有限公司", "value": "产后恢复" },
        { "title": "北京中民福祉教育科技有限责任公司", "value": "老年康体指导" },
        { "title": "泰康珞珈（北京）科学技术研究院有限公司", "value": "医养个案管理" },
        { "title": "哈尔滨华辰生物科技有限公司", "value": "皮肤护理" },
        { "title": "中国医药教育协会", "value": "体重管理" },
        { "title": "中国健康养老集团有限公司", "value": "老年慢病膳食调理" },
        { "title": "北京易芳堂科技发展有限公司", "value": "芳香疗法" },
        { "title": "九龙堂（北京）国际医学保健研究院", "value": "中医体质评估与应用" },
        { "title": "惠州雅姬乐化妆品有限公司", "value": "美容光电仪器操作" },
        { "title": "中检科教育科技（北京）有限公司", "value": "食品检验管理" },
        { "title": "江苏恒瑞医药股份有限公司", "value": "药物制剂生产" },
        { "title": "广州汇标检测技术中心", "value": "可食食品快速检验" },
        { "title": "烟台富美特信息科技股份有限公司", "value": "食品合规管理" },
        { "title": "上海医药（集团）有限公司", "value": "药品购销" },
        { "title": "广联达科技股份有限公司", "value": "工程造价数字化应用" },
        { "title": "中国建筑科学研究院有限公司", "value": "建设工程质量检测" },
        { "title": "中铁二十局集团有限公司", "value": "建筑工程施工工艺实施与管理" },
        { "title": "盾构及掘进技术国家重点实验室", "value": "全断面隧道掘进机操作" },
        { "title": "青岛好品海智信息技术有限公司", "value": "制冷空调系统安装与维修" },
        { "title": "壹仟零壹艺网络科技（北京）有限公司", "value": "建筑装饰装修数字化设计" },
        { "title": "中国室内装饰协会", "value": "室内设计" },
        { "title": "北京智能装配式建筑研究院有限公司", "value": "智能建造设计与集成应用" },
        { "title": "三一重工股份有限公司", "value": "装配式混凝土预制构件质量检验" },
        { "title": "四川升拓检测技术股份有限公司", "value": "路桥工程无损检测" },
        { "title": "北京京东乾石科技有限公司", "value": "智能仓储装备应用及维护" },
        { "title": "秦皇岛博赫科技开发有限公司", "value": "化工设备检维修作业" },
        { "title": "广州中望龙腾软件股份有限公司", "value": "机械产品三维模型设计" },
        { "title": "北京洛凯特文化传播有限公司", "value": "产品创意设计" },
        { "title": "北京机械工业自动化研究所有限公司", "value": "机械数字化设计与制造" },
        { "title": "北京卓创至诚技术有限公司", "value": "机械工程制图" },
        { "title": "海尔智家股份有限公司", "value": "注塑模具模流分析及工艺调试" },
        { "title": "北京赛育达科教有限责任公司", "value": "增材制造模型设计" },
        { "title": "武汉益模科技股份有限公司", "value": "拉延模具数字化设计" },
        { "title": "西安增材制造国家研究院有限公司", "value": "增材制造设备操作与维护" },
        { "title": "江苏徐工信息技术股份有限公司", "value": "工程机械数字化管理和运维" },
        { "title": "北京精雕科技集团有限公司", "value": "精密数控加工" },
        { "title": "北方至信人力资源评价（北京）有限公司", "value": "多工序数控机床操作" },
        { "title": "通用技术集团大连机床有限责任公司", "value": "数控机床安装与调试" },
        { "title": "上海汽车集团股份有限公司", "value": "燃油汽车总装与调试" },
        { "title": "上海淞泓智能汽车科技有限公司", "value": "车辆自动驾驶系统应用" },
        { "title": "上海景格科技股份有限公司", "value": "汽车智能制造系统集成应用" },
        { "title": "上海飞机制造有限公司", "value": "飞机机械系统装配" },
        { "title": "中国航空工业集团有限公司", "value": "飞机铆接装配" },
        { "title": "国营川西机器厂", "value": "航空发动机修理" },
        { "title": "中航国际航空发展有限公司", "value": "航空柔性加工生产线管控与操作" },
        { "title": "徐州鑫科机器人有限公司", "value": "特种机器人操作与运维" },
        { "title": "遨博（北京）智能科技有限公司", "value": "智能协作机器人技术及应用" },
        { "title": "深圳市优必选科技股份有限公司", "value": "服务机器人应用开发" },
        { "title": "宁波摩科机器人科技有限公司", "value": "焊接机器人编程与维护" },
        { "title": "中国科学院沈阳自动化研究所", "value": "工业机器人产品质量安全检测" },
        { "title": "深圳市优必选科技股份有限公司", "value": "服务机器人实施与运维" },
        { "title": "成都康德世纪教育科技集团有限公司", "value": "商务流程自动化机器人应用" },
        { "title": "天水三和数码测绘院有限公司", "value": "无人机摄影测量" },
        { "title": "深圳市大疆创新科技有限公司", "value": "无人机操作应用" },
        { "title": "北京优云智翔航空科技有限公司", "value": "无人机检测与维护" },
        { "title": "中国航空工业集团有限公司", "value": "无人机组装与调试" },
        { "title": "北京韦加智能科技股份有限公司", "value": "植保无人飞机应用" },
        { "title": "北京翔宇教育咨询有限公司", "value": "无人机航空喷洒" },
        { "title": "北京京东乾石科技有限公司", "value": "物流无人机操作与运维" },
        { "title": "中大国飞（北京）航空科技有限公司", "value": "无人机拍摄" },
        { "title": "杭州市特种设备检测研究院", "value": "智能网联电梯维护" },
        { "title": "杭州西奥电梯有限公司", "value": "电梯维修保养" },
        { "title": "深圳市越疆科技有限公司", "value": "机器视觉系统应用" },
        { "title": "苏州富纳艾尔科技有限公司", "value": "工业视觉系统运维" },
        { "title": "无锡信捷电气股份有限公司", "value": "可编程控制器系统应用编程" },
        { "title": "浙江瑞亚能源科技有限公司", "value": "可编程控制系统集成及应用" },
        { "title": "山东莱茵科斯特智能科技有限公司", "value": "生产线数字化仿真应用" },
        { "title": "珠海格力智能装备有限公司", "value": "数字化工厂产线装调与运维" },
        { "title": "北京中科航天人才服务有限公司", "value": "智能配电集成与运维" },
        { "title": "北京新奥时代科技有限责任公司", "value": "制造执行系统实施与应用" },
        { "title": "江苏哈工海渡教育科技集团有限公司", "value": "智能运动控制系统集成与应用" },
        { "title": "北京新大陆时代教育科技有限公司", "value": "工业数据采集与边缘服务" },
        { "title": "武汉华中数控股份有限公司", "value": "智能制造单元维护" },
        { "title": "武汉华中数控股份有限公司", "value": "智能制造单元集成应用" },
        { "title": "沈机（上海）智能系统研发设计有限公司", "value": "智能制造生产线集成应用" },
        { "title": "江苏汇博机器人技术股份有限公司", "value": "智能制造生产管理与控制" },
        { "title": "济南二机床集团有限公司", "value": "智能制造系统集成应用" },
        { "title": "沈阳新松机器人自动化股份有限公司", "value": "智能制造设备操作与维护" },
        { "title": "联想（北京）有限公司", "value": "智能制造现场数据采集与应用" },
        { "title": "北京赛育达科教有限责任公司", "value": "智能线集成与应用" },
        { "title": "中船舰客教育科技（北京）有限公司", "value": "智能产线控制与运维" },
        { "title": "北京赛育达科教有限责任公司", "value": "智能线运行与维护" },
        { "title": "上海电气自动化设计研究所有限公司", "value": "智能制造设备安装与调试" },
        { "title": "厦门科云信息科技有限公司", "value": "企业财务与会计机器人应用" },
        { "title": "北京东大正保科技有限公司", "value": "政府财务与会计机器人应用" },
        { "title": "中联集团教育科技有限公司", "value": "智能审计" },
        { "title": "中天运会计师事务所", "value": "审计信息化应用" },
        { "title": "平安国际智慧城市科技股份有限公司", "value": "家庭理财规划" },
        { "title": "上海财才网信息科技有限公司", "value": "薪酬管理" },
        { "title": "中保慧杰教育咨询（北京）有限公司", "value": "交通事故查勘估损与理赔" },
        { "title": "物产中大集团股份有限公司", "value": "机动车鉴定评估与回收" },
        { "title": "清控紫荆（北京）教育科技股份有限公司", "value": "金融产品数字化营销" },
        { "title": "中关村互联网金融研究院", "value": "金融数据建模应用" },
        { "title": "金智东博（北京）教育科技股份有限公司", "value": "金融智能投顾" },
        { "title": "中企云链（北京）金融信息服务有限公司", "value": "供应链智能融资技术应用" },
        { "title": "北京市黄金科技工程咨询公司", "value": "黄金投资分析" },
        { "title": "中经网数据有限公司", "value": "企业信用管理" },
        { "title": "湖南中德安普大数据网络科技有限公司", "value": "业财税融合成本管控" },
        { "title": "湖南中德安普大数据网络科技有限公司", "value": "业财税融合大数据投融资分析" },
        { "title": "北京中税网控股股份有限公司", "value": "企业纳税精细化管理" },
        { "title": "浙江衡信教育科技有限公司", "value": "个税计算" },
        { "title": "北京东大正保科技有限公司", "value": "企业管理咨询" },
        { "title": "北京睿学云诚教育咨询有限公司", "value": "品类管理" },
        { "title": "深圳市逸马科技有限公司", "value": "连锁特许经营管理" },
        { "title": "江苏京东信息技术有限公司", "value": "连锁企业门店运营管理" },
        { "title": "中联职教（北京）科技有限公司", "value": "门店数字化运营与管理" },
        { "title": "国家发展和改革委员会国际合作中心", "value": "企业境外经营合规管理" },
        { "title": "中联集团教育科技有限公司", "value": "社交电商运营" },
        { "title": "厦门优优汇联信息科技有限公司", "value": "跨境电子商务多平台运营" },
        { "title": "北京博导前程信息技术股份有限公司", "value": "农产品电商运营" },
        { "title": "联创新世纪（北京）品牌管理股份有限公司", "value": "移动互联网运营" },
        { "title": "北京中清研信息技术研究院有限公司", "value": "跨境电商海外营销" },
        { "title": "北京运华科技发展有限公司", "value": "汽车电商服务平台运营与管理" },
        { "title": "阿里巴巴（中国）网络技术有限公司", "value": "跨境电商B2C数据运营" },
        { "title": "江苏京东信息技术有限公司", "value": "电子商务客户服务" },
        { "title": "南京奥派信息产业股份公司", "value": "直播电商" },
        { "title": "中广协广告信息文化传播有限责任公司", "value": "广告审查" },
        { "title": "联创新世纪（北京）品牌管理股份有限公司", "value": "新媒体营销" },
        { "title": "中教畅享（北京）科技有限公司", "value": "数字营销技术应用" },
        { "title": "百度在线网络技术（北京）有限公司", "value": "数据营销" },
        { "title": "物产中大集团股份有限公司", "value": "供应链数据分析" },
        { "title": "北京中物联物流采购培训中心", "value": "供应链运营" },
        { "title": "中科智库物联网技术研究院江苏有限公司", "value": "智慧物流装备应用" },
        { "title": "北京起重运输机械设计研究院有限公司", "value": "智能物流仓储运维" },
        { "title": "北京第一时间网络科技有限公司", "value": "网络直播编导" },
        { "title": "北京中广上洋科技股份有限公司", "value": "网络直播技术" },
        { "title": "中广协广告信息文化传播有限责任公司", "value": "网络直播运营" },
        { "title": "北京人民在线网络有限公司", "value": "媒体融合运营" },
        { "title": "南京奥派信息产业股份公司", "value": "新媒体技术" },
        { "title": "北京乐享云创科技有限公司", "value": "融媒体内容制作" },
        { "title": "新华网股份有限公司", "value": "新媒体运营" },
        { "title": "北京字节跳动科技有限公司", "value": "自媒体运营" },
        { "title": "浙江中科视传科技有限公司", "value": "互动媒体应用开发" },
        { "title": "凤凰卫视有限公司", "value": "新媒体编辑" },
        { "title": "凤凰新联合（北京）教育科技有限公司", "value": "移动流媒体技术" },
        { "title": "北京人民在线网络有限公司", "value": "互联网内容风控" },
        { "title": "中国动漫集团有限公司", "value": "动画制作" },
        { "title": "中国动漫集团有限公司", "value": "数字艺术创作" },
        { "title": "浙江中科视传科技有限公司", "value": "文创产品数字化设计" },
        { "title": "杭州时光坐标影视传媒股份有限公司", "value": "数字影视特效制作" },
        { "title": "中摄协国际文化传媒（北京）有限公司", "value": "数字影像处理" },
        { "title": "杭州遥望网络科技有限公司", "value": "短视频数字化运营" },
        { "title": "奇安信科技集团股份有限公司", "value": "网络安全应急响应" },
        { "title": "北京神州绿盟科技有限公司", "value": "网络安全服务" },
        { "title": "奇安信科技集团股份有限公司", "value": "云安全运营服务" },
        { "title": "北京神州数码云科信息技术有限公司", "value": "Web安全测试" },
        { "title": "启明星辰信息技术集团股份有限公司", "value": "网络安全风险管理" },
        { "title": "上海海盾安全技术培训中心", "value": "安全防范系统建设与运维" },
        { "title": "北京天融信网络安全技术有限公司", "value": "网络安全渗透测试" },
        { "title": "深信服科技股份有限公司", "value": "云数据中心安全建设与运维" },
        { "title": "深信服科技股份有限公司", "value": "网络安全运营平台管理" },
        { "title": "北京中链智培科技有限公司", "value": "区块链智能合约开发" },
        { "title": "北京百度网讯科技有限公司", "value": "区块链系统应用与设计" },
        { "title": "中科智库物联网技术研究院江苏有限公司", "value": "区块链系统集成与应用" },
        { "title": "腾讯云计算（北京）有限责任公司", "value": "区块链应用软件开发与运维" },
        { "title": "赛迪（青岛）区块链研究院有限公司", "value": "区块链操作技术" },
        { "title": "西安纸贵互联网科技有限公司", "value": "区块链数据治理与维护" },
        { "title": "广东南方电力科学研究院有限公司", "value": "电能交易区块链应用" },
        { "title": "同方计算机有限公司", "value": "数据中心运维" },
        { "title": "深信服科技股份有限公司", "value": "云数据中心建设与运维" },
        { "title": "国富瑞数据系统有限公司", "value": "数据中心IT系统运维管理" },
        { "title": "中邮建技术有限公司", "value": "数据中心动力系统建设" },
        { "title": "北京久其软件股份有限公司", "value": "数据资产管理" },
        { "title": "武汉达梦数据库有限公司", "value": "数据库管理系统" },
        { "title": "哈工大大数据集团有限公司", "value": "数据标注" },
        { "title": "广东泰迪智能科技股份有限公司", "value": "大数据应用开发（Python）" },
        { "title": "中慧云启科技集团有限公司", "value": "Python程序开发" },
        { "title": "北京中软国际信息技术有限公司", "value": "数据应用开发与服务（Python）" },
        { "title": "广州粤嵌通信科技股份有限公司", "value": "物联网智能终端开发与设计" },
        { "title": "国信蓝桥教育科技（北京）股份有限公司", "value": "物联网单片机应用与开发" },
        { "title": "北京金山云网络技术有限公司", "value": "智联网（AIoT）应用开发" },
        { "title": "工业和信息化部电子第五研究所", "value": "物联网安全测评" },
        { "title": "海尔智家股份有限公司", "value": "物联网场景设计与开发" },
        { "title": "北京新大陆时代教育科技有限公司", "value": "物联网工程实施与运维" },
        { "title": "北京中兴协力科技有限公司", "value": "电梯物联网系统应用开发" },
        { "title": "中盈创信（北京）科技有限公司", "value": "物联网安装调试与运维" },
        { "title": "中移物联网有限公司", "value": "物联网云平台运用" },
        { "title": "北京新奥时代科技有限责任公司", "value": "大数据工程化处理与应用" },
        { "title": "成都云上天府大数据研究院有限公司", "value": "大数据治理" },
        { "title": "星环信息科技（上海）有限公司", "value": "大数据平台管理与开发" },
        { "title": "南京云创大数据科技股份有限公司", "value": "大数据应用部署与调优" },
        { "title": "沈阳新松机器人自动化股份有限公司", "value": "工业大数据应用与开发" },
        { "title": "深圳希施玛数据科技有限公司", "value": "金融大数据处理" },
        { "title": "北京京东乾石科技有限公司", "value": "智能仓储大数据分析" },
        { "title": "杭州朗迅科技有限公司", "value": "集成电路设计与验证" },
        { "title": "杭州朗迅科技有限公司", "value": "集成电路封装与测试" },
        { "title": "新华三技术有限公司", "value": "集成电路检测技术应用" },
        { "title": "北京华大九天软件有限公司", "value": "集成电路版图设计" },
        { "title": "龙芯中科技术有限公司", "value": "嵌入式边缘计算软硬件开发" },
        { "title": "快克智能装备股份有限公司", "value": "电子装联" },
        { "title": "西安诺瓦星云科技股份有限公司", "value": "LED显示屏应用" },
        { "title": "树根互联技术有限公司", "value": "工业数字孪生建模与应用" },
        { "title": "北京智能装配式建筑研究院有限公司", "value": "数字孪生城市建模与应用" },
        { "title": "腾讯云计算（北京）有限责任公司", "value": "云计算应用开发" },
        { "title": "腾讯云计算（北京）有限责任公司", "value": "微信小程序开发" },
        { "title": "腾讯科技（深圳）有限公司", "value": "安卓应用开发" },
        { "title": "腾讯科技（深圳）有限公司", "value": "Web全栈开发" },
        { "title": "北京金山办公软件股份有限公司", "value": "WPS办公应用" },
        { "title": "北京中软国际信息技术有限公司", "value": "JAVA应用开发" },
        { "title": "天津东软睿道教育信息技术有限公司", "value": "JavaWeb应用开发" },
        { "title": "北京四合天地科技有限公司", "value": "Web应用软件测试" },
        { "title": "北京京东世纪信息技术有限公司", "value": "自然语言处理应用开发" },
        { "title": "北京新奥时代科技有限责任公司", "value": "互联网软件测试" },
        { "title": "北京联合永道软件股份有限公司", "value": "移动应用软件测试" },
        { "title": "福建省网龙普天教育科技有限公司", "value": "虚拟现实应用设计与制作" },
        { "title": "中科泰岳（北京）科技有限公司", "value": "虚拟现实工程技术应用" },
        { "title": "华为软件技术有限公司", "value": "移动应用开发" },
        { "title": "用友网络科技股份有限公司", "value": "工业APP设计与开发" },
        { "title": "航天云网科技发展有限责任公司", "value": "工业互联网APP应用开发" },
        { "title": "杭州海康威视数字技术股份有限公司", "value": "综合安防系统建设与运维" },
        { "title": "同方计算机有限公司", "value": "终端系统运维" },
        { "title": "北京神州数码云科信息技术有限公司", "value": "网络系统软件应用与维护" },
        { "title": "中盈创信（北京）科技有限公司", "value": "商用密码应用与维护" },
        { "title": "南京中兴信雅达信息科技有限公司", "value": "5G网络优化" },
        { "title": "武汉烽火技术服务有限公司", "value": "5G承载网络运维" },
        { "title": "华为技术有限公司", "value": "5G移动通信网络部署与运维" },
        { "title": "中科泰岳（北京）科技有限公司", "value": "5G移动前端技术应用" },
        { "title": "北京神州数码云科信息技术有限公司", "value": "下一代互联网（IPv6）搭建与运维" },
        { "title": "新华三技术有限公司", "value": "无线网络规划与实施" },
        { "title": "广东省通信产业服务有限公司", "value": "移动网络优化" },
        { "title": "中兴通讯股份有限公司", "value": "数字化网络管理与应用" },
        { "title": "锐捷网络股份有限公司", "value": "网络设备安装与维护" },
        { "title": "安徽省通信产业服务有限公司", "value": "全光网线路建设与维护" },
        { "title": "珠海世纪鼎利科技股份有限公司", "value": "移动通信无线网络测试与优化" },
        { "title": "中兴通讯股份有限公司", "value": "物联网通信技术应用" },
        { "title": "福建中锐网络股份有限公司", "value": "网络系统规划与部署" },
        { "title": "联想（北京）有限公司", "value": "移动互联网应用开发" },
        { "title": "北京华晟经世信息技术有限公司", "value": "光宽带网络建设" },
        { "title": "新华三技术有限公司", "value": "智能网络应用与优化" },
        { "title": "中电新一代（北京）信息技术研究院", "value": "综合布线系统安装与维护" },
        { "title": "电信科学技术研究院有限公司", "value": "移动通信基站测试" },
        { "title": "海尔智家股份有限公司", "value": "工业互联网网络运维" },
        { "title": "青岛好品海智信息技术有限公司", "value": "工业互联网集成应用" },
        { "title": "北京工联科技有限公司", "value": "工业互联网预测性维护" },
        { "title": "北京工联科技有限公司", "value": "工业互联网设备数据采集" },
        { "title": "北京鸿腾智能科技有限公司", "value": "工业互联网安全测评与应急" },
        { "title": "辽宁向日葵教育科技有限公司", "value": "工业互联数据交互与应用" },
        { "title": "北京寓乐世界教育科技有限公司", "value": "工业互联网标识数据规划应用" },
        { "title": "中科智库物联网技术研究院江苏有限公司", "value": "工业传感器集成应用" },
        { "title": "科大讯飞股份有限公司", "value": "人工智能语音应用开发" },
        { "title": "北京百度网讯科技有限公司", "value": "人工智能深度学习工程应用" },
        { "title": "科大讯飞股份有限公司", "value": "人工智能数据处理" },
        { "title": "曙光信息产业股份有限公司", "value": "人工智能系统平台实施" },
        { "title": "北京新奥时代科技有限责任公司", "value": "人工智能前端设备应用" },
        { "title": "中科寒武纪科技股份有限公司", "value": "智能计算系统实践与应用" },
        { "title": "阿里巴巴（中国）有限公司", "value": "城市大脑平台应用与运维" },
        { "title": "腾讯云计算（北京）有限责任公司", "value": "人机对话智能系统开发" },
        { "title": "北京百度网讯科技有限公司", "value": "自动驾驶软件系统应用" },
        { "title": "浙江宇视科技有限公司", "value": "智慧安防系统实施与运维" },
        { "title": "超级智慧家（上海）物联网科技有限公司", "value": "智慧社区集成与运维" },
        { "title": "广东省通信产业服务有限公司", "value": "智慧家庭建设与维护" },
        { "title": "北京电信规划设计院有限公司", "value": "智能硬件应用开发" },
        { "title": "TCL科技集团股份有限公司", "value": "智能终端产品调试与维修" },
        { "title": "中科智库物联网技术研究院江苏有限公司", "value": "物联网智慧农业系统集成和应用" }]

    }
    render() {
        const { columns, dataSource } = this.state;
        return (
            <div className='company_list'>
                <div className='Certificatepage-banner'>
                    <div className='Common-content'>
                        <h2 className='detail-banner-name'>证书中心 <span className='crumbs-menu' style={{ display: "none" }}><Link to="/certificate/certificatehome">首页</Link> / <Link to="/certificate/certificatecenter">证书中心</Link> /试点院校</span></h2>
                    </div>
                </div>
                <div className="table_box">
                    <p style={{
                        color:'#888888',
                        marginBottom:'15px'
                    }}>证书按所属领域划分，同一领域证书排序不分先后</p>
                    <Table
                        columns={columns}
                        dataSource={dataSource}
                        pagination={false}
                    />
                </div>
            </div>
        )
    }
}
