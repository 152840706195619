export let resource_preview = ''
export let domainUrl = '' // 域名
export let ziXunUrl = ''
export let nxUrl = ''
export let path = ''
export let nxbUrl = ''
//export let baseUrl = 'https://zjapi.educs.com.cn'//axios baseURL
export let baseUrl = 'https://gw.zj.nxeduyun.com'//axios baseURL

export const setResourcePreview = (url)=>{
  resource_preview = url
}
export const setDomainUrl = (url)=>{
  domainUrl = url
}
export const setZiXunUrl = (url)=>{
  ziXunUrl = url
}
export const setPath = (url) => {
  path = url
}
export const setBaseUrl = (url)=>{
  baseUrl = url
}
export const setNxUrl = (url) => {
  nxUrl = url
}
export const setNxbUrl = (url)=>{
  nxbUrl = url
}
// 依赖07接口
export const initSecretUrlByType07 = (config) => {
  setBaseUrl(config?.gw ?? '')
  let domain = config?.gw?.split('.').reverse().slice(0, 2).reverse().join('.')
  setDomainUrl(domain ? `.${domain}` : '')
  setResourcePreview('https://zjyd-prod.oss-cn-beijing.aliyuncs.com')
  setPath(config?.gw ?? '')
  setNxUrl(config?.sys?.provinceportal?? '')
  setNxbUrl(config?.sys?.provincemanager ?? '')
}
//2020-09-28修改
export const commonalityUrl = 'https://office.chinaeducloud.com/?ssl=1&draw=1&';

export const turningSupportedArr = ['txt', 'doc', 'rtf', 'docx', 'xls', 'xlsx'];     //此文件类型用iframe加载需要更换白色背景
export const supportPaging = ['ppt', 'pdf', 'pptx', 'xls', 'xlsx'];                 //此文件类型支持分页切换
