import React, { Component } from 'react'
import Styles from "./index.module.scss";
import '../demoeHeader/smartedu.css'
import { nxbUrl} from '../../config/secret'
export default class FooterBox extends Component {
    GetQueryString
    render() {
        const GetQueryString = (name) => {
            const search = window.location.href;
            if (!search) return null;
            if (!search.split("?")[1]) return null;
            const item = search.split("?")[1].split("&");
            if (!item) return null;
            const fi = item.find((v) => v.includes(name))?.split("=")[1];
            if (item) {
                return fi ? decodeURI(fi) : undefined;
            } else {
                console.log(`${name} is not in search`);
                return null;
            }
        }
        if( GetQueryString('smartedu') == 'smartedu'  || localStorage.getItem('smartedu')=='1'){
            return (
                <div className="smartedu_bottom-box">
                <div className="smartedu_bottom-contain">
                    <div>
                        <p className="smartedu_text-center smartedu_bottom-p-2 smartedu_bottom-mobil">服务热线：0951-5559291&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
                        <p className="smartedu_text-center smartedu_bottom-p-2 smartedu_bottom-mobil">服务邮箱：nx5559291@126.com&nbsp;&nbsp;&nbsp;&nbsp;</p>
                        <p className="smartedu_text-center smartedu_bottom-p smartedu_bottom-mobil">技术支持：宁夏教育信息化管理中心&nbsp;&nbsp;</p>
                        <div className="smartedu_bottom-img-box smartedu_text-center ">
                            <p className="smartedu_bottom-mobil">
                                <img className="smartedu_icon-mobil"  src="https://img.js.design/assets/img/6319f0a7ab2452f420413cb0.png" alt=""/>
                                <a style={{color:'#fff'}} href="https://beian.miit.gov.cn/" target="_blank">宁ICP备16000125号-3</a>
                            </p>
                            <p className="smartedu_bottom-mobil" style={{marginRight: '20px'}}>
                                <img className="smartedu_bottom-icon smartedu_icon-mobil"  src="https://img.js.design/assets/img/6319f0a7ab2452f420413cb0.png" alt=""/>
                                <a style={{color:'#fff'}} href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=64010602000493" target="_blank">宁公网安备案 64010602000493号</a>
                            </p>
                            <p className="smartedu_bottom-mobil">版权所有：宁夏回族自治区教育厅</p>
                        </div>
                    </div>
                </div>
            </div>
            )
        }else{
            return (
                <div className={Styles.footer_warp}>
                    <div className='footer_logo'>
                        <img src={require('../../assets/democ/footerLogo.svg')}/>
                    </div>
                    <ul>
                        <li className='svg_box svg_box_one'>
                            <span className='isClick' onClick={() => window.open('https://manual.zj.nxeduyun.com')}>帮助中心</span>
                        </li>
                        <li className='svg_box'>
                            <span className='isClick' onClick={() => window.open(nxbUrl)}>门户管理</span>
                        </li>
                        <li className='svg_box'>
                            <span className='isClick' onClick={()=>window.open('https://www.nxeduyun.com/developer/#/home')}>开放平台</span>
                        </li>
                        <li>
                            <span>版权所有：宁夏回族自治区教育厅</span>
                        </li>
                        <li>
                            <span>技术支持：宁夏教育信息化管理中心</span>
                        </li>
                        <li>
                            <span>服务热线：0951-5559291  5559148</span>
                        </li>
                        <li>
                            <span ><a style={{color:'#fff'}} href="https://beian.miit.gov.cn/" target="_blank">宁ICP备16000125号-3</a></span>
                        </li>
                        <li>
                            <span><a style={{color:'#fff'}} href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=64010602000493" target="_blank">宁公网安备案 64010602000493号</a></span>
                        </li>
                    </ul>
                </div>
            )
        }
    }
}
