// 实训中心详情
import React, { Component } from 'react'
import Footer from '../../../components/footer'
import S from 'gongyongicon'
import {nxUrl} from '@/config/secret.js';
import {selectTrainingBaseInfo} from "../../../api/schoolUrl"
import RenderImg from "../../../components/renderImg";
import Header from "../../../components/demoeHeader";
import style from "./index.module.scss"

export default class TrainingDetails extends Component {
    state={
        trainingBaseData: {}, // 实训基地对象
        id: '', // 基地id
    }
    componentDidMount() {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
        const data = this.props.location.search; //地址栏截取
        var param = data.split("?")[1];
        if (param) {
            console.log(param);
            param = param.split("&")[0];
            const codeParam = param.includes("%") ? decodeURI(param) : param;
            const jsonparam = JSON.parse(codeParam);
            this.setState({
                id: jsonparam.id
            }, () => {
                this.getTrainingBaseInfo()
            })
        }


    }
    getTrainingBaseInfo=async ()=>{
        await selectTrainingBaseInfo({id:this.state.id}).then(e => {
            if(e){
                console.log('noticeData', e)
                this.setState({trainingBaseData:e})
            }
        })
    }
    render() {
        let {trainingBaseData}=this.state
        return (
            <div className={style.trainingDetails}>
                <Header {...this.props} id={3} />
                <div className='trainingDetails_span'>
                    <span>您的位置：
                        <span onClick={()=>this.props.history.goBack()} style={{cursor: 'pointer'}}>智慧实训</span> /
                        <i>实训基地详情</i>
                    </span>
                </div>
                <div className='main_box'>
                    <div className='news_title' style={{backgroundImage: `url("${window.$$setImgSrc('/nxmh-traback.png')}")`,backgroundSize: '100% 100%'}}>
                        <div className='tra_title'>{trainingBaseData.cityName}实训基地</div>
                        <span>主学校名称：{trainingBaseData.mainSchoolName}</span>
                        <span className='autherInfo'>
                            <S.Icon_diqu/>宁夏回族自治区{trainingBaseData.cityName}
                        </span>
                    </div>
                    <div className='bottom_box'>
                        <div className='jd_box'>
                            <div className="title">
                                基地图片
                            </div>
                            <ul className='img_box'>
                                <li>
                                    {trainingBaseData.imgOne?<RenderImg id={trainingBaseData.imgOne} size='m' type={trainingBaseData.mediaTypeOne} />:''}
                                </li>
                                <li >
                                    {trainingBaseData.imgTwo?<RenderImg id={trainingBaseData.imgTwo} size='m' type={trainingBaseData.mediaTypeTwo} />:''}
                                </li>
                            </ul>
                        </div>
                        <div className='jd_box'>
                            <div className="title">
                                关联学校
                            </div>
                            <ul className='school_box'>
                                {
                                    trainingBaseData.schoolNameList && trainingBaseData.schoolNameList.length > 0 ? trainingBaseData.schoolNameList.map((v, i) => {
                                        return <div key={v.id}>
                                            <ul>
                                                <li>
                                                    {v}
                                                </li>
                                            </ul>
                                        </div>
                                    }) : ""
                                }
                            </ul>
                        </div>
                        <div className='jd_box'>
                            <div className="title">
                                基地简介
                            </div>
                            <div className="text">
                                <div dangerouslySetInnerHTML={{__html: trainingBaseData.trainingIntroduction}}/>
                            </div>
                        </div>
                        <div className='jd_box'>
                            <div className="title">
                                适用方向
                            </div>
                            <div className="text">
                                {trainingBaseData.applicableDirection}
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        )
    }
}
