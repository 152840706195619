import { post,get } from '../../until/axios_instans';
// 获取院校中心列表
export function selectSchoolNingXia(data) {
    return post(window.apiNx+'/api/v1/nx/portal/index/selectSchoolNingXia', data);
}
// 服务文案
export function selectServiceCopywriting(data) {
    return post(window.apiNx+'/api/v1/nx/portal/index/selectServiceCopywriting', data);
}

// 学校类型分类
export function getShoolType(data) {
    return post('/api/ucenter/api/v1/sdk/ningxia/type', data);
}

//获取实训基地列表
export function getTrainingBaseList(data) {
    return post(window.apiNx+'/api/v1/nx/portal/trainingBase/getTrainingBaseList', data);
}

//获取教育服务列表
export function getEduServiceList(data) {
    return post(window.apiNx+'/api/v1/nx/portal/edu/getEduServiceList', data);
}

//获取应用中心
export function getEduService(data) {
    return post(window.apiNx+'/api/v1/nx/portal/index/getEduService', data);
}

//获取院校类型
export function getSchoolTypeList(data) {
    return post('/api/ucenter/api/v1/sdk/ningxia/type', data);
}
//获取首页轮播图
export function getTopBanner(data) {
    return post(window.apiNx+'/api/v1/nx/portal/index/getTopBanner', data);
}
//获取门户友情链接
export function getFriendLink(data) {
    return post(window.apiNx+'/api/v1/nx/portal/index/getFriendLink', data);
}

//获取移动端设置
export function selectAppConfig(data) {
    return post(window.apiNx+'/api/v1/nx/portal/index/selectAppConfig', data);
}
//获取最新资讯
export function getHotspotAndLatestNews(data) {
    return post('/api/zixun/api/v1/sdk/news/nxmh/getHotspotAndLatestNews', data);
}
//获取最新资讯
export function getNxmhNewsAndSpecialList(data) {
    return post('/api/zixun/api/v1/sdk/news/nxmh/getNxmhNewsAndSpecialList', data);
}

//查询一级分类和新闻资讯列表
export function getNewsListByCategory(data) {
    return post('/api/zixun/api/v1/sdk/news/nxmh/getNewsListByCategory', data);
}

//获取最新公告
export function getNotice(data) {
    return post(window.apiNx+'/api/v1/nx/portal/index/getNotice', data);
}
//师生空间开通数
export function getEduNum(data) {
    return post(window.apiNx+'/api/v1/nx/portal/index/getEduNum', data);
}

//查询公告列表
export function getNoticeList(data) {
    return post(window.apiNx+'/api/v1/nx/portal/index/getNoticeList', data);
}

//查询公告明细
export function selectNoticeById(data) {
    return post(window.apiNx+'/api/v1/nx/portal/index/selectNoticeById', data);
}

//根据域名查询机构详情
export function organizationDetail(data) {
    return post(window.apiNx+'/api/v1/nx/portal/index/organizationDetail', data);
}

//获取实训基地详情
export function selectTrainingBaseInfo(data) {
    return post(window.apiNx+'/api/v1/nx/portal/trainingBase/selectTrainingBaseInfo', data);
}

//获取友情链接数据(分页)
export function getFriendLinkPage(data) {
    return post(window.apiNx+'/api/v1/nx/portal/index/getFriendLinkPage', data);
}
// 百度地图访问路径
export function getCityName(data) {
    return get('https://free-api.heweather.com/v5/weather?key=19713447578c4afe8c12a351d46ea922', data);
}
// 优秀院校
export function getExcellentInstitutions(data) {
    return post(window.apiNx+'/api/v1/nx/portal/index/getExcellentInstitutions', data);
}
//数据统计
export function selectResourceTypeCount(data) {
    return post('/api/resource/sdk/resource/selectResourceTypeCount', data);
}
//平台统计
export function getApplicationPerson(data) {
    return post(window.apiNx+'/api/v1/nx/portal/index/getApplicationPerson', data);
}
