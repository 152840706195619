import React from "react";
import {Carousel} from 'antd'
import Header from '../../components/demoeHeader'
import FooterBox from '../../components/footer'
import {
    getTopBanner, getFriendLink,getExcellentInstitutions,
    getEduService, getNotice,getNoticeList,
    getEduNum, getShoolType, getNxmhNewsAndSpecialList,organizationDetail,
    selectResourceTypeCount,
    getApplicationPerson
} from "../../api/schoolUrl"
import {ProvincialDataBasic,ProvincialData} from '../../api/edums/index'
import RenderImg from "../../components/renderImg";
import Swiper from 'swiper/dist/js/swiper.js'
import * as echarts from 'echarts';
import ReactEcharts from 'echarts-for-react';
import ningxia from '../../assets/ningxia.json'
import S from "gongyongicon";
import 'swiper/dist/css/swiper.min.css'
import Styles from "./index.module.scss";
import {newsHostURL} from "../../config"
import CountUp from 'react-countup';
import EmptyConet from '../../components/mainemptyConet'
import { CricleLoding } from "gongyongloading";
export default class Main extends React.Component {
    state = {
        dataImg: [],
        friendLinkList: [],
        schoolList: [],
        pageNum: 1, // 当前页
        pageSize: 10, // 每页条数
        total: 0, // 总条数
        accessType: 0, // 应用接入类型：0-正式，1-试用，2-未开通，3-已停用
        cityId: '', // 所在市
        provinceId: '640000', // 所在省宁夏回族自治区
        enterpriseName: '', // 企业名称
        schoolType: '', // 学校类型
        schoolRunLevel: '', // 办学层次
        copywritingIntroduction: '', // 校训文案
        eduServiceList: '',//教育服务
        latestNewsList: '',//最新资讯
        portalSpecialVoList: null, //专题
        hotspotNewsList: '',//热点新闻
        noticeList: '',//最新公告
        teaStuSpaceNum: '',//平台用户数量
        teaSpaceNum: '', // 教师空间数量
        stuSpaceNum: '', // 学生空间数量
        studioNum: '', // 平台匠人工作室数量
        userWeekVisitNum: '',//本周访问用户
        organizationId: JSON.parse(localStorage.getItem('enterpriseInfo')) ? JSON.parse(localStorage.getItem('enterpriseInfo')).id : '', //机构id,,,
        secondaryVocationalSchool: '', // 中职院校
        vocationalSchool: '', // 高职院校
        undergraduate: '', // 本科
        cityName: '', // 当前城市名称
        cityCode: '' ,// 当前城市code
        curUrl: '', // 当前域名
        yinChuanCityNum:null,//银川市访问量
        shiZuiShanCityNum:null,//石嘴山市访问量
        wuZhongCityNum:null,//吴忠市访问量
        zhongWeiCityNum:null,//中卫市访问量
        guYuanCityNum:null,//固原市访问量
        animate__rollIn: '',
        rotateInUpLeft: '',
        rotateInUpRight: '',
        sales:[5, 20, 36, 10, 10, 20],
        end:100,
        pieTitle:[],
        loading: false,
        jgObj:{},//警官职业学院
        lwObj:{},//灵武市职业学院
        gsObj:{},//工商职业学院
        courseCount:0, //课程资源数量
        materialCount:0, //素材资源数量
        outsideCourseCount:0, //第三方课程资源数量
        vmResourceCount:0, //虚拟仿真资源数量
        userNumber:[],
        yinChuanTeaStuNumber: 0, // 银川市院校师生总数量
        shiZuiShanTeaStuNumber: 0, // 石嘴山市院校师生总数量
        wuZhongTeaStuNumber: 0, // 吴忠市院校师生总数量
        zhongWeiTeaStuNumber: 0, // 中卫市院校师生总数量
        guYuanTeaStuNumber: 0, // 固原市院校师生总数量
    }
    componentDidMount() {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
        window.addEventListener('scroll', this.bindHandleScroll)
        let curUrl=window.location.href
        var regExp=/#(\S*)/;
        curUrl= curUrl.replace(regExp,"") // 获取当前域名
            organizationDetail({enterpriseDomain:curUrl}).then((res)=>{
            if(!res || !res.result){
                res = {
                    id:"e787a9923f9549afbb2a63565335250b",
                    enterpriseName:"宁夏教育厅",
                    enterpriseCode:"宁夏教育厅",
                    enterpriseLogo:"8c76a9af50bf40c5acb7afe3fb74fbbd/obs/image/20210831/79af118475de483288c03959d75556f1.jpg"
                }
            }
            localStorage.setItem("enterpriseInfo",JSON.stringify(res))
            this.setState({
                curUrl: curUrl,
                organizationId:JSON.parse(localStorage.getItem('enterpriseInfo')) ? JSON.parse(localStorage.getItem('enterpriseInfo')).id : ''
            },()=>{
                this.getTopBanner()//轮播图
                this.getFriendLink()//友情链接
                this.getEduService()//教育服务
                this.getNxmhNewsAndSpecialList()//最新资讯和热点新闻
                this.newsTopic()  //新闻专题
                this.getNotice()//最新公告
                this.getEduNum()//师生空间开通数
                this.getShoolType()//获取学校类型
                this.getExcellentInstitutions()//优秀院校
                this.dataStatistics()//数据统计
                this.getProvincialDataBasic()
                this.getProvincialData()
                this.map()
            })
        })
        this.getApplicationPerson()

    }
    getProvincialDataBasic = async() =>{ // 获取学生空间数量
        let res = await ProvincialDataBasic();
        let education_bigdata = res.data.education_bigdata
        if ( education_bigdata) {
            this.setState({
                stuSpaceNum: education_bigdata.student_count,
                teaSpaceNum: education_bigdata.teacher_count, // 教师空间数量
                teaStuSpaceNum: education_bigdata.student_count + education_bigdata.teacher_count, // 平台用户数量
            })
        }
    }
    getProvincialData = async() => { // 获取院校师生总数量
        let res = await ProvincialData();
        let province_city_basic_data = res.data.province_city_basic_data
        console.log('lcl',province_city_basic_data)
        province_city_basic_data.map((item,index) => {
            if (item.name === '银川市') {
                this.setState({
                    yinChuanTeaStuNumber: item.student_num + item.teacher_num
                },()=>{
                    this.map()
                })
            } else if (item.name === '石嘴山市') {
                this.setState({
                    shiZuiShanTeaStuNumber: item.student_num + item.teacher_num
                },()=>{
                    this.map()
                })
            } else if (item.name === '吴忠市') {
                this.setState({
                    wuZhongTeaStuNumber: item.student_num + item.teacher_num
                },()=>{
                    this.map()
                })
            } else if (item.name === '中卫市') {
                this.setState({
                    zhongWeiTeaStuNumber: item.student_num + item.teacher_num
                },()=>{
                    this.map()
                })
            } else {
                this.setState({
                    guYuanTeaStuNumber: item.student_num + item.teacher_num
                },()=>{
                    this.map()
                })
            }
        })
    }
    bindHandleScroll=(e)=>{ // 动画效果
        const consNumber = document.getElementById("consNumber").offsetTop // 数据统计距离顶部距离
        const consNumberHeight = document.getElementById('consNumber').clientHeight;
        // 滚动的高度(兼容多种浏览器)
        const scrollTop = (e.srcElement ? e.srcElement.documentElement.scrollTop : false)  || window.pageYOffset || (e.srcElement ? e.srcElement.body.scrollTop : 0);

        // 判断用户当前是否进行了横向滚动，如果用户发生了横向滚动，则设置元素为static
        const scrollLeft = (e.srcElement ? e.srcElement.documentElement.scrollLeft : false) || window.pageXOffset  || (e.srcElement ? e.srcElement.body.scrollLeft : 0);

        if(scrollTop>560&&scrollTop<=1300){
            this.setState({
                animate__rollIn: 'animate__rollIn',
                rotateInUpLeft: 'animate__slideInRight',
                rotateInUpRight: 'animate__slideInLeft'
            })
        } else if (scrollTop > 1300) {
            this.setState({
                animate__rollIn: 'animate__rollOut',
                rotateInUpLeft: 'animate__slideOutRight',
                rotateInUpRight: 'animate__slideOutLeft'
            })
            if (scrollTop >= consNumber && scrollTop <= consNumber+ 20) { // 数据数据统计执行动画
                this.countupP.restart()
                this.countupJ.restart()
                this.countupG.restart()
                this.countupX.restart()
            }
        }
    }
    //最后要记得在componentWillUnmount，进行scroll事件的注销，不然消耗性能
    componentWillUnmount(){
        window.removeEventListener('scroll', this.bindHandleScroll);
    }

    getLast7Days () { // 获取当日前七日日期
        var myDate = new Date(); //获取今天日期
        myDate.setDate(myDate.getDate() - 6);
        var dateArray = [];
        var dateTemp;
        var flag = 1;
        for (var i = 0; i < 7; i++) {
            dateTemp = myDate.getFullYear()+ "-" + (myDate.getMonth() + 1) + "-" + myDate.getDate();
            dateArray.push(dateTemp);
            myDate.setDate(myDate.getDate() + flag);
        }
        return dateArray
    }
    //获取平台数据
    getApplicationPerson(){
        getApplicationPerson().then(res=>{
            this.setState({
                userNumber:res
            })
        })
    }
    /**
     * 折线图的配置对象
     */
    getOption = (sales,stores) =>{
        return {
            tooltip: {
                trigger: 'axis',
                padding: [8, 10, 8, 10],
                textStyle:{
                    fontSize:'0.14rem',
                    color:'#fff'
                },
                backgroundColor: 'rgba(51, 51, 51, 0.7)',
                borderColor: 'rgba(51, 51, 51, 0.7)',
                // 自定义提示框的内容
                formatter: (params) => {
                    let result = '活跃量' + params[0].value;
                    //console.log(result);
                    //let result = '活跃量' + 10;
                    return result;
                }
            },
            xAxis: {
                type: 'category',
                axisLine: {
                    lineStyle: {
                        color: '#fff', //左边线的颜色
                    }
                },
                data: this.getLast7Days()
            },
            yAxis: {
                type: 'value'
            },
            textStyle:{
                fontSize:'0.12rem',
                color:'#fff'
            },
            series: [{
                data: this.state.userNumber,
                type: 'line',
                smooth: true,
                itemStyle : {
                    normal : {
                        lineStyle:{
                            color:'#1DD3DD'
                        }
                    }
                },
            }]
        };
    }
    //数据统计饼状图
    pieData=()=>{

    }

    optionPie = () => { // 饼状图
        return {
            color: ["#EF2700","#1DD3DD","#9B25F8","#FF7D04","#FFE11E"],
            tooltip: {
                textStyle:{
                    fontSize:'0.14rem',
                    color:'#fff'
                },
                backgroundColor: 'rgba(51, 51, 51, 0.7)',
                borderColor: 'rgba(51, 51, 51, 0.7)',
                trigger: 'item',
                formatter: '{a} <br/>{b}: {c} ({d}%)'
            },
            legend: {
                show: false
            },
            graphic: {
                type: "text",
                left: "center",
                top: "47%",
                style: {
                    text: "资源分布",
                    textAlign: "center",
                    fill: "rgba(255,255,255,1)",
                    fontSize: '0.14rem',
                    fontWeight: 500
                }
            },
            series: [
                {
                    name: '分布情况',
                    type: 'pie',
                    radius: ['40%', '70%'],
                    avoidLabelOverlap: false,
                    label: {
                        show: false,
                        position: 'center',
                        fontSize: '0.14rem',
                        fontColor: '#fff'
                    },
                    emphasis: {
                        label: {
                            show: true,
                            fontSize: '0.14rem',
                            fontColor: '#fff'
                        }
                    },
                    data: this.state.pieTitle
                }
            ]
        };
    }

    // 地图
    map = () => {
        const {
            yinChuanCityNum, shiZuiShanCityNum, wuZhongCityNum, zhongWeiCityNum, guYuanCityNum,
            yinChuanTeaStuNumber,shiZuiShanTeaStuNumber,wuZhongTeaStuNumber,zhongWeiTeaStuNumber,guYuanTeaStuNumber
        } = this.state
        let ningxiaGeoCoordMap = {
            "银川市": [106.347233, 38.2883],
            "石嘴山市": [106.376173, 39.01333],
            "吴忠市": [106.199409, 37.986165],
            "中卫市": [105.189568, 37.514951],
            "固原市": [106.28, 36.01],
            "贺兰": [106.35, 38.55],
            "陶乐": [106.69, 38.82],
            "灵武": [106.34, 38.1],
            "同心": [105.94, 36.97],
            "中宁": [105.66, 37.48],
            "盐池": [107.41, 37.78],
            "西吉":[105.7,35.97],
            "泾源":[106.33,35.5],
            "海原":[105.64,36.56],
            "隆德":[106.11,35.63]
        }
        let ningxiaDatas = [
            [{
                name: "石嘴山市",
                value: 0
            }],
            [{
                name: "吴忠市",
                value: 0
            }],
            [{
                name: "中卫市",
                value: 0
            }],
            [{
                name: "固原市",
                value: 0
            }],
            [{
                name: "贺兰",
                value: 0
            }],
            [{
                name: "陶乐",
                value: 0
            }],
            [{
                name: "灵武",
                value: 0
            }],
            [{
                name: "同心",
                value: 0
            }],
            [{
                name: "中宁",
                value: 0
            }],
            [{
                name: "盐池",
                value: 0
            }],
            [{
                name: "西吉",
                value: 0
            }],
            [{
                name: "泾源",
                value: 0
            }],
            [{
                name: "海原",
                value: 0
            }],
            [{
                name: "隆德",
                value: 0
            }]

        ]
        var convertData = function (data) {
            var res = [];
            for (var i = 0; i < data.length; i++) {
                var dataItem = data[i];
                var fromCoord = ningxiaGeoCoordMap[dataItem[0].name];
                var toCoord = [106.347233, 38.2883];//中心点地理坐标
                if (fromCoord && toCoord) {
                    res.push([{
                        coord: toCoord,
                        // value: dataItem[0].value
                    }, {
                        coord: fromCoord,
                    }]);
                }
            }
            return res;

        };
        // 基于准备好的dom，初始化echarts实例
        if (document.getElementById('mapId')) {
            let myChart = echarts.init(document.getElementById('mapId'));
            // 绘制图表
            let name = 'ningxia' //地图名是宁夏
            let data = ningxia  //地图的数据来自之前引入的json文件
            let series = [];
            [['银川市', ningxiaDatas]].forEach(function (item, i) {
                series.push({
                        type: 'lines',
                        zlevel: 2,
                        effect: {
                            show: true,
                            period: 5, //箭头指向速度，值越小速度越快
                            trailLength: 0.02, //特效尾迹长度[0,1]值越大，尾迹越长重
                            symbol: 'arrow', //箭头图标
                            symbolSize: 5, //图标大小
                        },
                        lineStyle: {
                            normal: {
                                width: 1, //尾迹线条宽度
                                opacity: 0.6, //尾迹线条透明度
                                curveness: .5, //尾迹线条曲直度
                                color: '#26e7f3',
                                type: 'dashed'
                            }
                        },
                        data: convertData(item[1])
                    }, {
                        type: 'effectScatter',
                        coordinateSystem: 'geo',
                        zlevel: 2,
                        rippleEffect: { //涟漪特效
                            period: 4, //动画时间，值越小速度越快
                            brushType: 'stroke', //波纹绘制方式 stroke, fill
                            scale: 4 //波纹圆环最大限制，值越大波纹越大
                        },
                        symbol: 'circle',
                        symbolSize: function (val, params) {
                            if (params.name === '银川市' || params.name === '石嘴山市' || params.name === '吴忠市' || params.name === '中卫市' || params.name === '固原市' ) {
                                return 10; //圆环大小
                            } else {
                                return 6; //圆环大小
                            }
                        },
                        itemStyle: {
                            normal: {
                                show: false,
                                color: '#26e7f3',
                            }
                        },
                        data: item[1].map(function (dataItem) {
                            return {
                                //在这里定义你所要展示的数据
                                name: dataItem[0].name,
                                value: ningxiaGeoCoordMap[dataItem[0].name].concat([dataItem[0].value])
                            };
                        }),
                        label: {
                            color: '#ffffff',
                            formatter: '   {b}',
                            position: 'right',
                            // show: true
                        },
                    },
                    {
                        type: 'effectScatter',
                        coordinateSystem: 'geo',
                        zlevel: 2,
                        rippleEffect: { //涟漪特效
                            period: 4, //动画时间，值越小速度越快
                            brushType: 'stroke', //波纹绘制方式 stroke, fill
                            scale: 4 //波纹圆环最大限制，值越大波纹越大
                        },
                        symbol: 'circle',
                        itemStyle: {
                            normal: {
                                show: false,
                                color: '#26e7f3'
                            }
                        },
                        data: [{
                            name: item[0],
                            value: ningxiaGeoCoordMap[item[0]],
                        }],
                    }
                );
            });
            echarts.registerMap(name, data) //此步不可省略，要想展示一个地图，先需要注册
            let option = {
                title: {
                    top: 20,
                    text: '',
                    subtext: '',
                    x: 'center',
                    textStyle: {
                        color: '#000'
                    }
                },
                tooltip: {
                    trigger: 'item',
                    show: 'true',
                    // alwaysShowContent: 'true',
                    backgroundColor: 'rgba(255, 255, 255, 0.7)',
                    borderColor: 'rgba(255, 255, 255, 0.7)',
                    padding: 0,
                    textStyle: {
                        color: '#333333'  //字体颜色
                    },

                    //使用这个函数返回悬浮内容
                    formatter: function(params, ticket, callback) {
                        let datass = {}
                        var tipHtml = '';
                        if (params.name==='银川市') {
                            datass = yinChuanCityNum&&yinChuanCityNum[0]
                            tipHtml = '<div style="text-align: center;width: 2.81rem;height: 1.91rem;padding: 0.2rem;">' +
                                '<div style="color: #333333;font-size: 0.16rem;font-weight: 500;margin-bottom: 0.2rem">' +params.name + '·数据统计情况' + '</div>' +
                                '<div style="color: #333333;font-size: 0.14rem;line-height: 0.3rem;display: flex;justify-content: space-between;">' +
                                '<div style="color: #333333;font-size: 0.14rem;line-height: 0.3rem;">' + '登录访问用户总量' +  '</div>' + '<div style="color: #333333;font-size: 0.14rem;line-height: 0.3rem;">' + datass?.cityNowNum + '人' + '</div>' +
                                '</div>' +'<div style="color: #333333;font-size: 0.14rem;line-height: 0.3rem;display: flex;justify-content: space-between;">' +
                                '<div style="color: #333333;font-size: 0.14rem;line-height: 0.3rem;">' + '院校师生总数量' +  '</div>' + '<div style="color: #333333;font-size: 0.14rem;line-height: 0.3rem;">' + yinChuanTeaStuNumber + '人' + '</div>' +
                                '</div>' +'<div style="color: #333333;font-size: 0.14rem;line-height: 0.3rem;display: flex;justify-content: space-between;">' +
                                '<div style="color: #333333;font-size: 0.14rem;line-height: 0.3rem;">' + '教师登陆访问总数' +  '</div>' + '<div style="color: #333333;font-size: 0.14rem;line-height: 0.3rem;">' + datass?.teaCityNum + '人' + '</div>' +
                                '</div>'+'<div style="color: #333333;font-size: 0.14rem;line-height: 0.3rem;display: flex;justify-content: space-between;">' +
                                '<div style="color: #333333;font-size: 0.14rem;line-height: 0.3rem;">' + '学生登陆访问总数' +  '</div>' + '<div style="color: #333333;font-size: 0.14rem;line-height: 0.3rem;">' + datass?.stuCityNum + '人' + '</div>' +
                                '</div>'
                                +'</div>';
                        } else if (params.name==='石嘴山市') {
                            datass = shiZuiShanCityNum&&shiZuiShanCityNum[0]
                            tipHtml = '<div style="text-align: center;width: 2.81rem;height: 1.91rem;padding: 0.2rem;">' +
                                '<div style="color: #333333;font-size: 0.16rem;font-weight: 500;margin-bottom: 0.2rem">' +params.name + '·数据统计情况' + '</div>' +
                                '<div style="color: #333333;font-size: 0.14rem;line-height: 0.3rem;display: flex;justify-content: space-between;">' +
                                '<div style="color: #333333;font-size: 0.14rem;line-height: 0.3rem;">' + '登录访问用户总量' +  '</div>' + '<div style="color: #333333;font-size: 0.14rem;line-height: 0.3rem;">' + datass?.cityNowNum + '人' + '</div>' +
                                '</div>' +'<div style="color: #333333;font-size: 0.14rem;line-height: 0.3rem;display: flex;justify-content: space-between;">' +
                                '<div style="color: #333333;font-size: 0.14rem;line-height: 0.3rem;">' + '院校师生总数量' +  '</div>' + '<div style="color: #333333;font-size: 0.14rem;line-height: 0.3rem;">' + shiZuiShanTeaStuNumber + '人' + '</div>' +
                                '</div>' +'<div style="color: #333333;font-size: 0.14rem;line-height: 0.3rem;display: flex;justify-content: space-between;">' +
                                '<div style="color: #333333;font-size: 0.14rem;line-height: 0.3rem;">' + '教师登陆访问总数' +  '</div>' + '<div style="color: #333333;font-size: 0.14rem;line-height: 0.3rem;">' + datass?.teaCityNum + '人' + '</div>' +
                                '</div>'+'<div style="color: #333333;font-size: 0.14rem;line-height: 0.3rem;display: flex;justify-content: space-between;">' +
                                '<div style="color: #333333;font-size: 0.14rem;line-height: 0.3rem;">' + '学生登陆访问总数' +  '</div>' + '<div style="color: #333333;font-size: 0.14rem;line-height: 0.3rem;">' + datass?.stuCityNum + '人' + '</div>' +
                                '</div>'
                                +'</div>';
                        } else if (params.name==='吴忠市') {
                            datass = wuZhongCityNum&&wuZhongCityNum[0]
                            tipHtml = '<div style="text-align: center;width: 2.81rem;height: 1.91rem;padding: 0.2rem;">' +
                                '<div style="color: #333333;font-size: 0.16rem;font-weight: 500;margin-bottom: 0.2rem">' +params.name + '·数据统计情况' + '</div>' +
                                '<div style="color: #333333;font-size: 0.14rem;line-height: 0.3rem;display: flex;justify-content: space-between;">' +
                                '<div style="color: #333333;font-size: 0.14rem;line-height: 0.3rem;">' + '登录访问用户总量' +  '</div>' + '<div style="color: #333333;font-size: 0.14rem;line-height: 0.3rem;">' + datass?.cityNowNum + '人' + '</div>' +
                                '</div>' +'<div style="color: #333333;font-size: 0.14rem;line-height: 0.3rem;display: flex;justify-content: space-between;">' +
                                '<div style="color: #333333;font-size: 0.14rem;line-height: 0.3rem;">' + '院校师生总数量' +  '</div>' + '<div style="color: #333333;font-size: 0.14rem;line-height: 0.3rem;">' + wuZhongTeaStuNumber + '人' + '</div>' +
                                '</div>' +'<div style="color: #333333;font-size: 0.14rem;line-height: 0.3rem;display: flex;justify-content: space-between;">' +
                                '<div style="color: #333333;font-size: 0.14rem;line-height: 0.3rem;">' + '教师登陆访问总数' +  '</div>' + '<div style="color: #333333;font-size: 0.14rem;line-height: 0.3rem;">' + datass?.teaCityNum + '人' + '</div>' +
                                '</div>'+'<div style="color: #333333;font-size: 0.14rem;line-height: 0.3rem;display: flex;justify-content: space-between;">' +
                                '<div style="color: #333333;font-size: 0.14rem;line-height: 0.3rem;">' + '学生登陆访问总数' +  '</div>' + '<div style="color: #333333;font-size: 0.14rem;line-height: 0.3rem;">' + datass?.stuCityNum + '人' + '</div>' +
                                '</div>'
                                +'</div>';
                        }else if (params.name==='中卫市') {
                            datass = zhongWeiCityNum&&zhongWeiCityNum[0]
                            tipHtml = '<div style="text-align: center;width: 2.81rem;height: 1.91rem;padding: 0.2rem;">' +
                                '<div style="color: #333333;font-size: 0.16rem;font-weight: 500;margin-bottom: 0.2rem">' +params.name + '·数据统计情况' + '</div>' +
                                '<div style="color: #333333;font-size: 0.14rem;line-height: 0.3rem;display: flex;justify-content: space-between;">' +
                                '<div style="color: #333333;font-size: 0.14rem;line-height: 0.3rem;">' + '登录访问用户总量' +  '</div>' + '<div style="color: #333333;font-size: 0.14rem;line-height: 0.3rem;">' + datass?.cityNowNum + '人' + '</div>' +
                                '</div>' +'<div style="color: #333333;font-size: 0.14rem;line-height: 0.3rem;display: flex;justify-content: space-between;">' +
                                '<div style="color: #333333;font-size: 0.14rem;line-height: 0.3rem;">' + '院校师生总数量' +  '</div>' + '<div style="color: #333333;font-size: 0.14rem;line-height: 0.3rem;">' + zhongWeiTeaStuNumber + '人' + '</div>' +
                                '</div>' +'<div style="color: #333333;font-size: 0.14rem;line-height: 0.3rem;display: flex;justify-content: space-between;">' +
                                '<div style="color: #333333;font-size: 0.14rem;line-height: 0.3rem;">' + '教师登陆访问总数' +  '</div>' + '<div style="color: #333333;font-size: 0.14rem;line-height: 0.3rem;">' + datass?.teaCityNum + '人' + '</div>' +
                                '</div>'+'<div style="color: #333333;font-size: 0.14rem;line-height: 0.3rem;display: flex;justify-content: space-between;">' +
                                '<div style="color: #333333;font-size: 0.14rem;line-height: 0.3rem;">' + '学生登陆访问总数' +  '</div>' + '<div style="color: #333333;font-size: 0.14rem;line-height: 0.3rem;">' + datass?.stuCityNum + '人' + '</div>' +
                                '</div>'
                                +'</div>';
                        }else if (params.name==='固原市') {
                            datass = guYuanCityNum&&guYuanCityNum[0]
                            tipHtml = '<div style="text-align: center;width: 2.81rem;height: 1.91rem;padding: 0.2rem;">' +
                                '<div style="color: #333333;font-size: 0.16rem;font-weight: 500;margin-bottom: 0.2rem">' +params.name + '·数据统计情况' + '</div>' +
                                '<div style="color: #333333;font-size: 0.14rem;line-height: 0.3rem;display: flex;justify-content: space-between;">' +
                                '<div style="color: #333333;font-size: 0.14rem;line-height: 0.3rem;">' + '登录访问用户总量' +  '</div>' + '<div style="color: #333333;font-size: 0.14rem;line-height: 0.3rem;">' + datass?.cityNowNum + '人' + '</div>' +
                                '</div>' +'<div style="color: #333333;font-size: 0.14rem;line-height: 0.3rem;display: flex;justify-content: space-between;">' +
                                '<div style="color: #333333;font-size: 0.14rem;line-height: 0.3rem;">' + '院校师生总数量' +  '</div>' + '<div style="color: #333333;font-size: 0.14rem;line-height: 0.3rem;">' + guYuanTeaStuNumber + '人' + '</div>' +
                                '</div>' +'<div style="color: #333333;font-size: 0.14rem;line-height: 0.3rem;display: flex;justify-content: space-between;">' +
                                '<div style="color: #333333;font-size: 0.14rem;line-height: 0.3rem;">' + '教师登陆访问总数' +  '</div>' + '<div style="color: #333333;font-size: 0.14rem;line-height: 0.3rem;">' + datass?.teaCityNum + '人' + '</div>' +
                                '</div>'+'<div style="color: #333333;font-size: 0.14rem;line-height: 0.3rem;display: flex;justify-content: space-between;">' +
                                '<div style="color: #333333;font-size: 0.14rem;line-height: 0.3rem;">' + '学生登陆访问总数' +  '</div>' + '<div style="color: #333333;font-size: 0.14rem;line-height: 0.3rem;">' + datass?.stuCityNum + '人' + '</div>' +
                                '</div>'
                                +'</div>';
                        } else if (params.name === '贺兰') {
                            // tipHtml = ''
                            yinChuanCityNum != null ?
                                tipHtml = '<div style="text-align: center;width: 2.81rem;height: 1.91rem;padding: 0.2rem;">' +
                                    '<div style="color: #333333;font-size: 0.16rem;font-weight: 500;margin-bottom: 0.2rem">' + '银川市·数据统计情况' + '</div>' +
                                    '<div style="color: #333333;font-size: 0.14rem;line-height: 0.3rem;display: flex;justify-content: space-between;">' +
                                    '<div style="color: #333333;font-size: 0.14rem;line-height: 0.3rem;">' + '登录访问用户总量' +  '</div>' + '<div style="color: #333333;font-size: 0.14rem;line-height: 0.3rem;">' + yinChuanCityNum[0].cityNowNum + '人' + '</div>' +
                                    '</div>' +'<div style="color: #333333;font-size: 0.14rem;line-height: 0.3rem;display: flex;justify-content: space-between;">' +
                                    '<div style="color: #333333;font-size: 0.14rem;line-height: 0.3rem;">' + '院校师生总数量' +  '</div>' + '<div style="color: #333333;font-size: 0.14rem;line-height: 0.3rem;">' + yinChuanTeaStuNumber + '人' + '</div>' +
                                    '</div>' +'<div style="color: #333333;font-size: 0.14rem;line-height: 0.3rem;display: flex;justify-content: space-between;">' +
                                    '<div style="color: #333333;font-size: 0.14rem;line-height: 0.3rem;">' + '教师登陆访问总数' +  '</div>' + '<div style="color: #333333;font-size: 0.14rem;line-height: 0.3rem;">' + yinChuanCityNum[0].teaCityNum + '人' + '</div>' +
                                    '</div>'+'<div style="color: #333333;font-size: 0.14rem;line-height: 0.3rem;display: flex;justify-content: space-between;">' +
                                    '<div style="color: #333333;font-size: 0.14rem;line-height: 0.3rem;">' + '学生登陆访问总数' +  '</div>' + '<div style="color: #333333;font-size: 0.14rem;line-height: 0.3rem;">' + yinChuanCityNum[0].stuCityNum + '人' + '</div>' +
                                    '</div>'
                                    +'</div>' : tipHtml = ''

                        } else {
                            tipHtml = ''
                        }
                        return tipHtml;
                    }
                },
                geo: {
                    type: 'map',
                    map: name, //'jiangxi'
                    roam: false,
                    geoIndex: 1,
                    zoom: 1.1,  //地图的比例
                    show: true,
                    layoutCenter: ['35%', '50%'],//距离左右，上下距离的百分比
                    layoutSize:'100%',//map百分比大小
                    label: {
                        normal: {
                            show: true,
                            textStyle: {
                                color: '#FFFFFF'  //字体颜色
                            }
                        },
                        emphasis: {
                            show: true,
                            textStyle: {
                                color: '#FFFFFF'  //选中后的字体颜色
                            }
                        },
                        // position: 'left',
                    },
                    regions:[{
                        name: '银川市',
                        selected: true,
                        select: {
                            itemStyle: {
                                areaColor: 'rgba(255, 255, 255, 0.3)',
                                borderColor: '#41C6FF',
                            },
                            label: {
                                // fontWeight: 'bold',
                                color: "#ffffff",
                                // formatter: '银川市',
                                // position: ['73%','50%'],
                                show: true
                            },
                        },

                    }],
                    selectedMode: true,
                    itemStyle: {
                        normal: {
                            areaColor: 'rgba(255, 255, 255, 0.2)',
                            borderColor: 'rgba(255, 255, 255, 0.2)',
                            areaStyle: {color: '#002344'}
                        },
                        emphasis: {
                            areaColor: 'rgba(255, 255, 255, 0.3)',
                            borderColor: '#41C6FF',
                        }
                    },
                },

                legend: {
                    orient: 'vertical',
                    top: 'bottom',
                    left: 'right',
                    data: ['pm2.5'],
                    textStyle: {
                        color: '#fff',
                    },
                },
                series: series
            }
            myChart.setOption(option, true);
            // 默认显示toollip
            setTimeout(function(){
                myChart.dispatchAction({
                    type: 'showTip',
                    seriesIndex:1,  // 显示第几个series
                    dataIndex: 4, // 显示第几个数据
                    position:['45%','30%']
                });
            })
            window.addEventListener("resize", function () {
                myChart.resize();
            })
        }
    }
    //师生空间开通数
    getEduNum = async () => {
        await getEduNum().then(e => {
            if (e) {
                this.setState({
                    studioNum: e.studioNum, // 平台匠人数量
                    userWeekVisitNum: e.userWeekVisitNum,
                    yinChuanCityNum:e.yinChuanCityNum,//银川市访问量
                    shiZuiShanCityNum:e.shiZuiShanCityNum,//石嘴山市访问量
                    wuZhongCityNum:e.wuZhongCityNum,//吴忠市访问量
                    zhongWeiCityNum:e.zhongWeiCityNum,//中卫市访问量
                    guYuanCityNum:e.guYuanCityNum,//固原市访问量
                },()=>{
                    this.map()
                })
            }
        })
    }
    // 新闻专题
    newsTopic = async () => {
        let data = {
            organizationId: this.state.organizationId,
            platform: "NX"
        }
        await getNxmhNewsAndSpecialList(data).then(e => {
            this.setState({
                portalSpecialVoList: e.portalSpecialVoList
            },()=>{
                // 职教资讯切换
                new Swiper('.cont1_wrap_zwzt .swiper-container', {
                    pagination: '.swiper-pagination',
                    paginationClickable: '.swiper-pagination',
                    nextButton: '.swiper-button-nextb',
                    prevButton: '.swiper-button-prevb',
                    spaceBetween: 30,
                    slidesPerView: 1,
                });
            })
        })
    }
    // 职教资讯
    getNxmhNewsAndSpecialList = async () => {
        let data = {
            organizationId: this.state.organizationId,
            platform: "NX"
        }
        await getNxmhNewsAndSpecialList(data).then(e => {
            this.setState({
                latestNewsList: e.portalNewsVoList,
                // portalSpecialVoList: e.portalSpecialVoList
            },()=>{
                // 职教资讯切换
                new Swiper('.swiper_table .swiper-container', {
                    spaceBetween: 8,
                    slidesPerView:4,
                    speed:2500,
                    slidesPerGroup : 1,
                    autoplay: {
                       delay: 4000,
                       stopOnLastSlide: false,
                       disableOnInteraction: true,
                    },
                    loopFillGroupWithBlank: true,
                    pagination: {
                        el: ".swiper-pagination",
                        clickable: true,
                    },
                    navigation: {
                        nextEl: ".swiper-button-nexta",
                        prevEl: ".swiper-button-preva",
                    },
                });
            })
        })
    }
    //优秀院校
    getExcellentInstitutions=async ()=>{
        await getExcellentInstitutions().then(res=>{
            res&&res.map((i, index)=>{
                if(index == 0){
                    this.setState({
                        jgObj:i
                    })
                }
                if(index == 1){
                    this.setState({
                        lwObj:i
                    })
                }
                if(index == 2){
                    this.setState({
                        gsObj:i
                    })
                }
                
            })
        })
    }

    //教育服务列表
    getEduService = async () => {
        await getEduService().then(e => {
            if (e) {
                this.setState({eduServiceList: e})
            }
        })
    }

    async getShoolType() { // 获取学校类型分类
        let data = {
            type: 3 // 办学层次sel
        }
        await getShoolType(data).then(res => {
            if (res) {
                res.map((item, index) => {
                    if (item.name.includes('本科')) {
                        this.setState({
                            undergraduate: item.code
                        })
                    } else if (item.name.includes('中职')) {
                        this.setState({
                            secondaryVocationalSchool: item.code,
                            schoolRunLevel: item.code,
                        }, () => {
                        })
                    } else {
                        this.setState({
                            vocationalSchool: item.code
                        })
                    }
                })
            }
        })

    }

    //首页轮播图列表
    getTopBanner = async () => {
        this.setState({ loading: true });
        await getTopBanner().then(e => {
            if (e) {
                this.setState({
                    dataImg: e
                })
            }
            this.setState({ loading: false });
        })
    }//友情链接列表
    getFriendLink = async () => {
        await getFriendLink().then(e => {
            // let data = JSON.parse(JSON.stringify(e));
            // data.unshift({
            //     linkName:'1+X证书',
            //     linkUrl:newsHostURL+'#/certificate/certificatehome'
            // })
            if (e) {
                this.setState({
                    friendLinkList: e
                })
            }
        })
    }
    //跳转tab
    changeTab = (e) => {
        if (e) {
            this.selectSchoolNingXia(e);
        }
    }
    //通知公告
    getNotice = async () => {
        // var BMap = window.BMap;//取出window中的BMap对象
        // var myCity = new BMap.LocalCity();
        // await myCity.get(res => {
        //     this.setState({
        //         cityName: res.name,
        //         cityCode: res.code
        //     }, () => {
        //         let data = {
        //             cityName: res.name,
        //             cityCode: res.code
        //         }
        //         getNotice(data).then(e => {
        //             if (e) {
        //                 this.setState({
        //                     noticeList: e
        //                 })
        //             }
        //         })
        //     })
        // })
         let data = {noticeTitle: "", pageNum: 1, pageSize: 8}
         getNoticeList(data).then(e => {
            if(e){
                 this.setState({noticeList: e.data})
            }
            
         })
    }
    gotoNewsDetail = (e) => { // 资讯详情页
        const {organizationId} = this.state
        window.open(newsHostURL + `news/nx-news-details.html?oneCategoryId=${e.oneCategoryId}&id=${e.newsId}&schoolId=${organizationId}&name=NX`)
    }
    gotoZixun = () => { // 跳到资讯列表页
        const {organizationId} = this.state
        window.open(newsHostURL + `news/nx_index.html?schoolId=${organizationId}&name=NX`)
    }
    gotoDetile = (e) => { // 跳转到公告详情页
        let data = {id: e}
        this.props.history.push({
            pathname: "/noticeDetile",
            search: JSON.stringify(data)
        })
        // this.props.history.push('/noticeDetile')
    }
    // 轮播图左右箭头
    next = () => {
        this.slider.slick.slickNext();
    }
    prev = () => {
        this.slider.slick.slickPrev();
    }

    goto=(e)=>{
        if(e){
            window.open(e)
        }
    }
    handleScroll = (e) =>{
    }
    //数据统计
    dataStatistics=()=>{
        let data={
            orgId:this.state.organizationId,
            isManagement:true
        }
        selectResourceTypeCount(data).then(res=>{
            this.setState({
                pieTitle:[
                    {value:res.resourceCount ,name:"资源总数",color:"#EF2700"},
                    {value:res.materialCount,name:"素材资源",color:"#1DD3DD"},
                    {value:res.courseCount,name:"课程资源",color:"#9B25F8"},
                    {value:res.vmResourceCount ,name:"虚拟仿真资源",color:"#FF7D04"},
                    {value:res.outsideCourseCount ,name:"第三方课程资源",color:"#FFE11E"},

                ]
            })

        })
    }
    render() {
        const {dataImg, friendLinkList, eduServiceList, latestNewsList, noticeList, loading,
            userWeekVisitNum,animate__rollIn,rotateInUpRight,rotateInUpLeft,sales,pieTitle,
            teaStuSpaceNum,teaSpaceNum,stuSpaceNum,studioNum,jgObj,lwObj,gsObj,outsideCourseCount,courseCount,materialCount,vmResourceCount } = this.state;
        return (
            <div className="main" onWheel={(e) => this.handleScroll(e)}>
                <div className="nx_container">
                    <div className={Styles.container}>
                        <Header id={0} {...this.props} />
                        <div className="main">
                            {/* 导航 */}
                            {loading ? (
                                <CricleLoding className="asfd" />
                            ) : (
                            <div className="img_box">
                                {
                                    dataImg&&dataImg.length>0?(
                                        <Carousel autoplay dots={true} ref={el => (this.slider = el)} autoplaySpeed={5000} effect="fade">
                                            {dataImg && dataImg.map((item, index) => {
                                                return (
                                                    <div key={index}>
                                                        {item.isUrl===0&&item.bannerUrl?
                                                        <a href={item.bannerUrl} target='_blank'>
                                                            <RenderImg type={item.mediaType} id={item.bannerImg}/>
                                                        </a>:<RenderImg type={item.mediaType} id={item.bannerImg}/>
                                                        }
                                                    </div>
                                                )
                                            })}
                                        </Carousel>
                                    ):(
                                        <Carousel autoplay dots={true} ref={el => (this.slider = el)} autoplaySpeed={5000} effect="fade">
                                            <div>
                                                <img src={window.$$setImgSrc('/nx_Recordeda.jpg')}/>
                                            </div>
                                            <div>
                                                <img src={window.$$setImgSrc('/nx_Recordedb.png')}/>
                                            </div>
                                            <div>
                                                <img src={window.$$setImgSrc('/nx_Recordedc.png')}/>
                                            </div>
                                            <div>
                                                <img src={window.$$setImgSrc('/nx_Recordedd.jpeg')}/>
                                            </div>
                                        </Carousel>
                                    )
                                }

                                <span className="left-circle" onClick={this.prev}><S.Previcon/></span>
                                <span className="right-circle" onClick={this.next}><S.Previcon/></span>
                                {/*箭头*/}
                                {/*{dataImg.length > 0 &&*/}
                                {/*<span className="left-circle" onClick={this.prev}><S.Previcon/></span>}*/}
                                {/*{dataImg.length > 0 &&*/}
                                {/*<span className="right-circle" onClick={this.next}><S.Previcon/></span>}*/}
                            </div>
                                )
                            }
                            <div className={'cont1_wrapzx'}>
                                <div className={'cont1_center'}>
                                    <div className={'cont1_left'}>
                                        <div className={'cont1_title'}>
                                            <h3>职教资讯<span className="title"><S.Titlebg/></span></h3>
                                            <span className="link" onClick={() => {
                                                this.gotoZixun()
                                            }}>查看更多{'>'}</span>
                                        </div>
                                        {
                                            latestNewsList && latestNewsList.length > 0 ? (
                                                    <div className="swiper_table">
                                                        <div className="swiper_table_cont">
                                                            <div className="swiper-container">
                                                                <div className="swiper-wrapper">
                                                                    {
                                                                        latestNewsList && latestNewsList.length > 0 ? latestNewsList && latestNewsList.map((item, index) => {
                                                                            return (
                                                                                <div className={"animate__animated swiper-slide" + " " + animate__rollIn}>
                                                                                    <div className="top">
                                                                                        <div className="pic">
                                                                                            {latestNewsList && latestNewsList.length > 0 ?
                                                                                                <RenderImg id={item.newsImg}
                                                                                                           type={item.mediaType}/> : ''}
                                                                                        </div>
                                                                                        <div className="text">
                                                                                            <h4 onClick={() => this.gotoNewsDetail(item)}>{item.newsTitle}</h4>
                                                                                            <span className="time">{item.releaseTime?.replaceAll("-",".")}</span>
                                                                                            <div
                                                                                                className="doc">{item.newsAbstract}</div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div onClick={() => this.gotoNewsDetail(item)} className="bottom">
                                                                                        <h4>{item.newsTitle}</h4>
                                                                                        <span className="time">{item.releaseTime?.replaceAll("-",".")}</span>
                                                                                        <div
                                                                                            className="doc">{item.newsAbstract}</div>
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                        }) : ""

                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="swiper-button-next swiper-button-nexta"><S.Lefticon/></div>
                                                        <div className="swiper-button-prev swiper-button-preva"><S.Lefticon/></div>
                                                    </div>
                                                ) :
                                                    <EmptyConet/>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className={'cont1_wrap_tznews'}>
                                {
                                    noticeList && noticeList.length > 0 ?<span className="span_bg">
                                    <S.Tzicon/>
                                </span>:""
                                }

                                <div className={'cont1_center'}>
                                    <div className={'cont1_left'}>
                                        <div className={'cont1_title'}>
                                            <h3>通知公告<span className="title"><S.Titlebg/></span></h3>
                                            <span className="link"
                                                  onClick={() => this.props.history.push('/noticeList')}>更多公告 {'>'}</span>
                                        </div>
                                        <ul className={'cont1_ul'}>
                                            {
                                                noticeList && noticeList.length > 0 ? noticeList && noticeList.map((v, index) => {
                                                    return (
                                                        <li className={(index+1)%2 === 1 ? "animate__animated" + " " + rotateInUpRight : "animate__animated" + " " + rotateInUpLeft}>
                                                            {/*(index+1)%2 === 1 ? rotateInUpRight :*/}
                                                <span className="time">
                                                    <b>{v.createTime?.split("-")[1]}</b>
                                                    <i>{v.createTime?.split("-")[0] === 'Sep' ? "Sep" : v.createTime?.split("-")[0]}</i>
                                                </span>
                                                            <span className="right_text">
                                                    <b onClick={() => this.gotoDetile(v.id)}>{v.noticeTitle}</b>
                                                    <i>发布者：{v.noticeAuthor}</i>
                                                </span>
                                                        </li>)
                                                }) : (
                                                    <EmptyConet/>
                                                )
                                            }
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            {/*
                            <div className={eduServiceList.length>4?'cont1_wrap_yyzx':'cont1_wrap_yyzx cont1_wrap_yyzx_bg'}>
                                <div className={'cont1_center'}>
                                    <div className={'cont1_left'}>
                                        <div className={'cont1_title'}>
                                            <h3 className={'title_lcl'}>应用中心</h3>
                                            <span className="link"
                                                  onClick={() => this.props.history.push('/apply')}>更多应用 {'>'}</span>
                                        </div>
                                        <div className="ul_list">
                                            {
                                                eduServiceList && eduServiceList.length > 0 ? eduServiceList.map((item, index) => {
                                                    return (
                                                        <div className="li" onClick={() => this.goto(item.eduLink)} style={{background: `url("${window.$$setImgSrc('/nxmh_yybgicon.png')}") no-repeat 100% rgba(255, 255, 255,1)`}}>
                                                            <span className="icon">
                                                                {
                                                                    item.imgType === 1 ? <img src={window.$$setImgSrc(item.eduIcon)}/> :
                                                                        <RenderImg id={item.eduIcon} type={item.iconMediaType}/>
                                                                }
                                                            </span>
                                                            <h4>{item.eduName}</h4>
                                                            <div className="text">
                                                                {item.eduIntroduction}
                                                            </div>
                                                        </div>
                                                    )
                                                }) : (
                                                    <EmptyConet/>
                                                )
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            */}
                            {/*优秀院校*/}
                            <div className={'yxyx_box'}>
                                <div className={'cont1_left'}>
                                    <div className={'cont1_title'}>
                                        <h3>优秀院校<span className="title"><S.Titlebg/></span></h3>
                                    </div>
                                    <div className={'yxyx_img_box'}>
                                        <div className="li">
                                            <div className="pic" onClick={()=>window.open(jgObj.schoolDomain + '#/main/home')}>
                                                {
                                                    jgObj.enterpriseImg?<RenderImg id={jgObj.enterpriseImg} size='m' type={''} />:<img src={require('../../assets/img/yx_01.jpg')}/>
                                                }
                                            </div>
                                            <div className="content" onClick={()=>window.open(jgObj.schoolDomain + '#/main/home')}>
                                                <span className="logo">
                                                    <RenderImg id={jgObj.logo} size='m' type={''} />
                                                </span>
                                                <span className="bg">
                                                   <S.Tzicon/>
                                                </span>
                                                <h4>{jgObj.schoolName}</h4>
                                                {/* <i><img src={require('../../assets/img/title_2.svg')}/></i> */}
                                            </div>
                                            <div className="bottom">
                                                <div className="num_li">
                                                    <div className="text" onClick={()=>window.open(`https://alrc.zj.nxeduyun.com/#/main/home?schoolId=${jgObj.id}&name=CS&nav=%25E6%25A0%25A1%25E6%259C%25AC%25E8%25B5%2584%25E6%25BA%2590%25E5%25BA%2593#/main/resources/`)}>
                                                        <span>校本素材资源</span>
                                                        <h5><b>{jgObj.resourseCount?jgObj.resourseCount:0}</b><i>个</i></h5>
                                                    </div>
                                                    <div className="text" onClick={()=>window.open(`https://alrc.zj.nxeduyun.com/#/main/home?schoolId=${jgObj.id}&name=CS&nav=%25E6%25A0%25A1%25E6%259C%25AC%25E8%25B5%2584%25E6%25BA%2590%25E5%25BA%2593#/main/curriculum/`)}>
                                                        <span>校本课程资源</span>
                                                        <h5><b>{jgObj.courseCount?jgObj.courseCount:0}</b><i>个</i></h5>
                                                    </div>
                                                </div>
                                                <div className="num_li" onClick={()=>window.open(jgObj.schoolDomain +'#/main/home')}>
                                                    <div className="text">
                                                        <span>师生人数</span>
                                                        <h5><b>{jgObj.teaStuNum?jgObj.teaStuNum:0}</b><i>人</i></h5>
                                                    </div>
                                                    <div className="text">
                                                        <span>空间访问</span>
                                                        <h5><b>{jgObj.spaceNum?jgObj.spaceNum:0}</b><i>次</i></h5>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="li">
                                            <div className="pic" onClick={()=>window.open(lwObj.schoolDomain + '#/main/home')}>
                                                {
                                                   lwObj.enterpriseImg?<RenderImg id={lwObj.enterpriseImg} size='m' type={''} />:<img src={require('../../assets/img/yx_02.jpg')}/>
                                                }
                                            </div>
                                            <div className="content" onClick={()=>window.open(lwObj.schoolDomain + '#/main/home')}>
                                                <span className="logo">
                                                    {/* <img src={require('../../assets/img/yx_kjlogo2.png')}/> */}
                                                    <RenderImg id={lwObj.logo} size='m' type={''} />
                                                </span>
                                                <span className="bg">
                                                   <S.Tzicon/>
                                                </span>
                                                <h4>{lwObj.schoolName}</h4>
                                                {/* <i><img src={require('../../assets/img/title-1.svg')}/></i> */}
                                            </div>
                                            <div className="bottom">
                                                <div className="num_li"  >
                                                    <div className="text" onClick={()=>window.open(`https://alrc.zj.nxeduyun.com/#/main/home?schoolId=${lwObj.id}&name=CS&nav=%25E6%25A0%25A1%25E6%259C%25AC%25E8%25B5%2584%25E6%25BA%2590%25E5%25BA%2593#/main/resources/`)}>
                                                        <span>校本素材资源</span>
                                                        <h5><b>{lwObj.resourseCount?lwObj.resourseCount:0}</b><i>个</i></h5>
                                                    </div>
                                                    <div className="text" onClick={()=>window.open(`https://alrc.zj.nxeduyun.com/#/main/home?schoolId=${lwObj.id}&name=CS&nav=%25E6%25A0%25A1%25E6%259C%25AC%25E8%25B5%2584%25E6%25BA%2590%25E5%25BA%2593#/main/curriculum/`)}>
                                                        <span>校本课程资源</span>
                                                        <h5><b>{lwObj.courseCount?lwObj.courseCount:0}</b><i>个</i></h5>
                                                    </div>
                                                </div>
                                                <div className="num_li"  onClick={()=>window.open(lwObj.schoolDomain + '#/main/home')}>
                                                    <div className="text">
                                                        <span>师生人数</span>
                                                        <h5><b>{lwObj.teaStuNum?lwObj.teaStuNum:0}</b><i>人</i></h5>
                                                    </div>
                                                    <div className="text">
                                                        <span>空间访问</span>
                                                        <h5><b>{lwObj.spaceNum?lwObj.spaceNum:0}</b><i>次</i></h5>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="li">
                                            <div className="pic" onClick={()=>window.open(gsObj.schoolDomain + '#/main/home')}>
                                                {/* <img src={require('../../assets/img/yx_03.jpg')}/> */}
                                                {
                                                    gsObj.enterpriseImg?<RenderImg id={gsObj.enterpriseImg} size='m' type={''} />:<img src={require('../../assets/img/yx_03.jpg')}/> 
                                                }
                                            </div>
                                            <div className="content" onClick={()=>window.open(gsObj.schoolDomain + '#/main/home')}>
                                                <span className="logo">
                                                    {/* <img src={require('../../assets/img/yx_03.jpg')}/> */}
                                                    <RenderImg id={gsObj.logo} size='m' type={''} />
                                                </span>
                                                <span className="bg">
                                                  <S.Tzicon/>
                                                </span>
                                                <h4>{gsObj.schoolName}</h4>
                                                {/* <i><img src={require('../../assets/img/title-3.svg')}/></i> */}
                                            </div>
                                            <div className="bottom">
                                                <div className="num_li" >
                                                    <div className="text" onClick={()=>window.open(`https://alrc.zj.nxeduyun.com/#/main/home?schoolId=${gsObj.id}&name=CS&nav=%25E6%25A0%25A1%25E6%259C%25AC%25E8%25B5%2584%25E6%25BA%2590%25E5%25BA%2593#/main/resources/`)}>
                                                        <span>校本素材资源</span>
                                                        <h5><b>{gsObj.resourseCount?gsObj.resourseCount:0}</b><i>个</i></h5>
                                                    </div>
                                                    <div className="text" onClick={()=>window.open(`https://alrc.zj.nxeduyun.com/#/main/home?schoolId=${gsObj.id}&name=CS&nav=%25E6%25A0%25A1%25E6%259C%25AC%25E8%25B5%2584%25E6%25BA%2590%25E5%25BA%2593#/main/curriculum/`)}>
                                                        <span>校本课程资源</span>
                                                        <h5><b>{gsObj.courseCount?gsObj.courseCount:0}</b><i>个</i></h5>
                                                    </div>
                                                </div>
                                                <div className="num_li" onClick={()=>window.open(gsObj.schoolDomain + '#/main/home')}>
                                                    <div className="text">
                                                        <span>师生人数</span>
                                                        <h5><b>{gsObj.teaStuNum?gsObj.teaStuNum:0}</b><i>人</i></h5>
                                                    </div>
                                                    <div className="text">
                                                        <span>空间访问</span>
                                                        <h5><b>{gsObj.spaceNum?gsObj.spaceNum:0}</b><i>次</i></h5>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/*<img src={require('../../assets/img/nxjg.png')} onClick={()=>window.open('https://nsjy.zj.nxeduyun.com/')}/>*/}
                                        {/*<img src={require('../../assets/img/nxlw.png')} onClick={()=>window.open('https://lwzy.zj.nxeduyun.com/#/main/home')}/>*/}
                                        {/*<img src={require('../../assets/img/nxgs.png')} onClick={()=>window.open('https://nxgs.zj.nxeduyun.com/')}/>*/}
                                    </div>
                                </div>

                            </div>
                            <div className="mapwrap" id={'consNumber'}>
                                <div className={'mapcent'}>
                                    <div className={'title'}>
                                        数据统计
                                    </div>
                                    <div className={'mapcont'}>
                                        <div className={'mapidwrap'}>
                                            <div id={'mapId'} style={{height: '8.2rem', width: '100%'}}></div>
                                        </div>
                                        <div className={'tj_left_box'}>
                                            <ul className={'shujulist'}>
                                                <li>
                                                    <div className={'number_img_box'}>
                                                        <img src={require('../../assets/img/pt.png')}/>
                                                    </div>
                                                    <div className={'number_right_box'}>
                                                        <div className={'number'}>
                                                            <CountUp
                                                                ref={el=>this.countupP=el}
                                                                className="custom-count"
                                                                start={0}
                                                                end={teaStuSpaceNum ? teaStuSpaceNum : 0}
                                                                duration={1}
                                                                redraw={false}  //如果为true，则组件将始终在每次重新渲染时进行动画处理。
                                                                separator=""  //制定千分位的分隔符
                                                                decimal=","  //制定小数字符
                                                                prefix=""  //动画值前缀
                                                                suffix=""  //动画值后缀，可以加单位
                                                                onComplete={this.onComplete} //动画完成后调用的函数
                                                                onStart={this.onStart} //在动画开始前调用的函数
                                                            />
                                                        </div>
                                                        <div className={'shujuname'}>平台总用户数量</div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className={'number_img_box'}>
                                                        <img src={require('../../assets/img/js.png')}/>
                                                    </div>
                                                    <div className={'number_right_box'}>
                                                        <div className={'number'}>
                                                            <CountUp
                                                                ref={el=>this.countupJ=el}
                                                                className="custom-count"
                                                                start={0}
                                                                end={teaSpaceNum ? teaSpaceNum: 0}
                                                                duration={1}
                                                                redraw={false}  //如果为true，则组件将始终在每次重新渲染时进行动画处理。
                                                                separator=""  //制定千分位的分隔符
                                                                decimal=","  //制定小数字符
                                                                prefix=""  //动画值前缀
                                                                suffix=""  //动画值后缀，可以加单位
                                                                onComplete={this.onComplete} //动画完成后调用的函数
                                                                onStart={this.onStart} //在动画开始前调用的函数
                                                            />
                                                        </div>
                                                        <div className={'shujuname'}>教师空间数量</div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className={'number_img_box'}>
                                                        <img src={require('../../assets/img/jr.png')}/>
                                                    </div>
                                                    <div className={'number_right_box'}>
                                                        <div className={'number'}>
                                                            <CountUp
                                                                ref={el=>this.countupG=el}
                                                                className="custom-count"
                                                                start={0}
                                                                end={studioNum ? studioNum : 0}
                                                                duration={1}
                                                                redraw={false}  //如果为true，则组件将始终在每次重新渲染时进行动画处理。
                                                                separator=""  //制定千分位的分隔符
                                                                decimal=","  //制定小数字符
                                                                prefix=""  //动画值前缀
                                                                suffix=""  //动画值后缀，可以加单位
                                                                onComplete={this.onComplete} //动画完成后调用的函数
                                                                onStart={this.onStart} //在动画开始前调用的函数
                                                            />
                                                        </div>
                                                        <div className={'shujuname'}>平台匠人工作室数量</div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className={'number_img_box'}>
                                                        <img src={require('../../assets/img/xs.png')}/>
                                                    </div>
                                                    <div className={'number_right_box'}>
                                                        <div className={'number'}>
                                                            <CountUp
                                                                ref={el=>this.countupX=el}
                                                                className="custom-count"
                                                                start={0}
                                                                end={stuSpaceNum?stuSpaceNum: 0}
                                                                duration={1}
                                                                redraw={false}  //如果为true，则组件将始终在每次重新渲染时进行动画处理。
                                                                separator=""  //制定千分位的分隔符
                                                                decimal=","  //制定小数字符
                                                                prefix=""  //动画值前缀
                                                                suffix=""  //动画值后缀，可以加单位
                                                                onComplete={this.onComplete} //动画完成后调用的函数
                                                                onStart={this.onStart} //在动画开始前调用的函数
                                                            />
                                                        </div>
                                                        <div className={'shujuname'}>学生空间数量</div>
                                                    </div>
                                                </li>
                                            </ul>
                                            {/*饼状图*/}
                                            <div className={'pie_box'}>
                                                <div className={'pie_left'}>
                                                    <div className={'tjt_title'}>平台资源总数</div>
                                                    <ReactEcharts option={this.optionPie()} />
                                                </div>
                                                <div className={'pie_right'}>
                                                    {/* <div className={'yctitle'}>资源分布情况（个）</div> */}
                                                    <ul className={'pie_lenge'}>
                                                        {
                                                            pieTitle&&pieTitle.length > 0 ? pieTitle.map((item,index) =>{
                                                                return (
                                                                    <li>
                                                                        <div className={'back_bef'} style={{background: `${item.color}`}}></div>
                                                                        <div className={"pie_detail"}>
                                                                            <span className={'pie_title'}>{item.name}</span>
                                                                            <span className={'pie_number'}>{item.value}</span>
                                                                        </div>
                                                                    </li>
                                                                )
                                                            }) : ''
                                                        }
                                                        {/*<li>*/}
                                                        {/*    <span className={'back_bef'} style={{background: "#1DD3DD"}}></span>*/}
                                                        {/*    <span className={'pie_title'}>素材资源</span>*/}
                                                        {/*    <span className={'pie_number'}>{materialCount}</span>*/}
                                                        {/*</li>*/}
                                                        {/*<li>*/}
                                                        {/*    <span className={'back_bef'} style={{background: "#9B25F8"}}></span>*/}
                                                        {/*    <span className={'pie_title'}>课程资源</span>*/}
                                                        {/*    <span className={'pie_number'}>{courseCount }</span>*/}
                                                        {/*</li>*/}
                                                        {/*<li>*/}
                                                        {/*    <span className={'back_bef'} style={{background: "#FF7D04"}}></span>*/}
                                                        {/*    <span className={'pie_title'}>虚拟仿真资源</span>*/}
                                                        {/*    <span className={'pie_number'}>{vmResourceCount}</span>*/}
                                                        {/*</li>*/}
                                                        {/*<li>*/}
                                                        {/*    <span className={'back_bef'} style={{background: "#FFE11E"}}></span>*/}
                                                        {/*    <span className={'pie_title'}> 第三方课程资源</span>*/}
                                                        {/*    <span className={'pie_number'}>{outsideCourseCount }</span>*/}
                                                        {/*</li>*/}
                                                    </ul>
                                                </div>

                                            </div>
                                            {/*折线图*/}
                                            <div className={'line_box'}>
                                                <div className={'tjt_title'}>平台用户访问</div>
                                                <ReactEcharts option={this.getOption(sales)} />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className='friendshipLink'>
                                <div className="friendshipLink_top">
                                    <div className={'cont1_center'}>
                                        <div className={'cont1_title'}>
                                            <h3>友情链接<span className="title"><S.Titlebg/></span></h3>
                                            <span className="link"
                                                  onClick={() => this.props.history.push('/morelinks')}>更多友链 {'>'}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="friendshipLink_bottom">
                                    <div className={'cont1_center'}>
                                        <div className='tabsContent'>
                                            <ul>
                                                {
                                                    friendLinkList && friendLinkList.length > 0 ? friendLinkList.map((i, index) => {
                                                        if(index<10){
                                                            return (
                                                                <li>
                                                                    <a href={i.linkUrl} target='_blank'>
                                                                        {i.linkName}
                                                                    </a>
                                                                </li>
                                                            )
                                                        }
                                                    }) : <div className="Empty-conet">
                                                        <EmptyConet/>
                                                    </div>
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <FooterBox/>
                    </div>
                </div>
            </div>
        );
    }
}
