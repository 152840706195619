import React, { Component } from 'react'
import Footer from '../../../components/footer'
import { Input, Button, Pagination } from 'antd'
import DemoeHeader from '../../../components/demoeHeader'
import RenderImg from '../../../components/renderImg'
import S from 'gongyongicon'
import { selectSchoolNingXia,selectServiceCopywriting,getShoolType } from "../../../api/schoolUrl"
import Styles from "./index.module.scss";
import EmptyConet from '../../../components/mainemptyConet'
const {Search} = Input
const urlPre = "zjyd-space-front-peixun/"
export default class SchoolCentre extends Component {
    state = {
        dataSoure: [],
        pageNum: 1, // 当前页
        pageSize: 24, // 每页条数
        total: 0, // 总条数
        accessType: 0, // 应用接入类型：0-正式，1-试用，2-未开通，3-已停用
        cityId: '', // 所在市
        provinceId: '640000', // 所在省宁夏回族自治区
        enterpriseName: '', // 企业名称
        schoolType: '', // 学校类型
        schoolRunLevel: '', // 办学层次
        secondaryVocationalSchool: '', // 中职院校
        vocationalSchool: '', // 高职院校
        undergraduate: '', // 本科
        schoolSelList: [],
        selIndex: 0, // 是否被选中按钮
        imgList:[{}],
        organizationId: JSON.parse(localStorage.getItem('enterpriseInfo'))? JSON.parse(localStorage.getItem('enterpriseInfo')).id:'', //机构id,,,
    }
    componentDidMount() {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
        this.selectSchoolNingXia()
        this.getShoolType()
    }
    async selectSchoolNingXia () { // 获取院校列表
        const {pageNum,pageSize,accessType,enterpriseName,schoolType,schoolRunLevel,organizationId}=this.state
        let data = {
            accessType: accessType,
            enterpriseName: enterpriseName,
            pageNum: pageNum,
            pageSize: pageSize,
            schoolRunLevel: schoolRunLevel,
            schoolType: schoolType,
            enterpriseId:organizationId?organizationId:''
        }
        await selectSchoolNingXia(data).then(res=>{
            if (res) {
                this.setState({
                    dataSoure: res.data,
                    total: res.total
                })
            }
        })

    }
    async getShoolType () { // 获取学校类型分类
        let data = {
            type : 3 // 办学层次sel
        }
        await getShoolType(data).then(res=>{
            if (res) {
                res.map((item,index) =>{
                    if (item.name.includes('本科')) {
                        this.setState({
                            undergraduate: item.code
                        })
                    } else if (item.name.includes('中职')) {
                        this.setState({
                            secondaryVocationalSchool: item.code
                        })
                    } else {
                        this.setState({
                            vocationalSchool: item.code
                        })
                    }
                })
                this.setState({
                    schoolSelList: [{
                        name: '全部分类',
                        code: null
                    },{
                        name: '中职院校',
                        code: this.state.secondaryVocationalSchool
                    },{
                        name: '高职院校',
                        code: this.state.vocationalSchool
                    },{
                        name: '职教本科',
                        code: this.state.undergraduate
                    }]
                })
            }
        })

    }
    changeSchoolSel = (code, index) => { // 选择学校类型
        this.setState({
            selIndex: index,
            schoolRunLevel: code
        },()=>{
            this.selectSchoolNingXia()
        })
    }
    onShowSizeChange=(current, pageSize)=>{ // 每页条数
        this.setState({
            pageSize:pageSize,
            pageNum:current
        },()=>{
            this.selectSchoolNingXia()
        })
    }
    onChange=(page)=>{ // 分页
        this.setState({
            pageNum:page
        },()=>{
            this.selectSchoolNingXia();
            document.body.scrollTop = document.documentElement.scrollTop = 0;
        })
    }
    onSearch = (val) => { // 搜索
        this.setState({
            enterpriseName: val
        }, ()=>{
            window.aplus_queue.push({
                'action':'aplus.record',
                'arguments':['search_click', 'CLK', {
                    keyword:val
                 }]
            })
            this.selectSchoolNingXia()
        })
    }

    render() {
        const {$$img} = window;
        const { dataSoure,total,schoolSelList,selIndex,pageSize,pageNum } = this.state
        return (
            <div className={Styles.schoolCentre_warp}>
                <DemoeHeader {...this.props} id={2} />
                <div className="top_banner">
                    <div className='top_banner_span'>
                        <span>院校中心</span>
                    </div>
                </div>
                <div className='schoolCentre_content_box'>
                    <div className="top_warp">
                        <div className="top">
                            <div className="btns">
                                <span className="fist">院校分类</span>
                                {
                                    schoolSelList && schoolSelList.map((item,index)=>{
                                        return <>
                                            <Button className={index === selIndex ? 'active' : ''} onClick={()=>this.changeSchoolSel(item.code,index)}> {item.name}</Button>
                                        </>
                                    })
                                }
                            </div>
                        </div>
                        <div className="search">
                        <span>
                            共查询到{total}所学校
                        </span>
                            <Search style={{ width: '3.6rem', height: '0.38rem' }} placeholder="请输入院校中心名称" onSearch={this.onSearch} enterButton/>
                        </div>
                    </div>
                    <div className="schoolCentre_content">
                        {
                            dataSoure && dataSoure.length > 0 ? dataSoure.map((v, i) => {
                                return <div className="dataList" key={v.id}>
                                    <ul>
                                        <li>
                                            <RenderImg id={v.enterpriseLogo} size='m' type={''} />
                                        </li>
                                        <div className="li_con">
                                            <li>{v.enterpriseName}</li>
                                            <li>{v.enShorterForm}</li>
                                            <li>创办时间:<span>{v.foundedTime?v.foundedTime.substring(0,4)+'年':''}</span> 办学方式:<span>{v.schoolRunModeName}</span>校训:<span>{v.schoolMotto}</span></li>
                                            <dd>
                                                <S.Icon_diqu/> {v.cityName}
                                            </dd>
                                            <div className="footer_box">
                                                <Button onClick={() => {
                                                    window.aplus_queue.push({ 
                                                        'action':'aplus.record', 
                                                        'arguments':['special_click', 'CLK', { cate_special_name: '院校',special_name:v.enterpriseName}] 
                                                    });
                                                    window.open(v.enterpriseDomain)

                                                }} disabled={v.isOpenDomain &&v.enterpriseDomain &&  v.isOpenDomain === 1?false:true}>
                                                    院校空间
                                                </Button>
                                                <Button onClick={() => {
                                                    window.aplus_queue.push({ 
                                                        'action':'aplus.record', 
                                                        'arguments':['special_click', 'CLK', { cate_special_name: '院校',special_name:v.enterpriseName}] 
                                                    });
                                                    window.open(v.websiteAdd)

                                                }} disabled={v.websiteAdd?false:true}>学校官网</Button>
                                            </div>
                                        </div>
                                    </ul>
                                </div>
                            }) : (
                                <div className={Styles.EmptyConet_CONT}>
                                    <EmptyConet/>
                                </div>
                            )
                        }
                        {
                            dataSoure && dataSoure.length > 0 ?
                                <div className='footer_pag'
                                     style={{ width: '13rem', display: "flex", justifyContent: "flex-end", marginTop: '0.3rem', marginBottom: "0.5rem" }}
                                >
                                    <Pagination
                                        showSizeChanger
                                        pageSizeOptions={["12", "24", "36", "48"]}
                                        onShowSizeChange={this.onShowSizeChange}
                                        onChange={this.onChange}
                                        defaultCurrent={1}
                                        total={total}
                                        pageSize={pageSize}
                                        current={pageNum}
                                    />
                                </div>: ''
                        }

                    </div>
                </div>

                <Footer />
            </div>
        )
    }
}
