import React, { Component } from 'react'
import Header from '../../../components/demoeHeader'
import Footer from '../../../components/footer'
import "./index.scss"
export default class ElseNav extends Component {
    render() {
        return (
            <div>
                <Header {...this.props} id={4} />
                教育资讯
                <Footer />
            </div>
        )
    }
}
