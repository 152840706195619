/*
 * @Author: your name
 * @Date: 2021-11-04 10:06:20
 * @LastEditTime: 2021-11-23 16:52:09
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \zjyd-front-ningxia-portal\src\router\router_config.js
 */
import LoadableComponent from "../until/LoadableComponent";
let moduleFile = require.context("../page", true, /\index.(jsx|js)$/);
let result = moduleFile.keys().reduce((prev, item) => {
  let str = item.split("/")[item.split("/").length - 2];
  let name = str[0].toLocaleUpperCase() + str.slice(1);
  prev = Object.assign({}, prev, {
    [name]: LoadableComponent(import("../page" + item.slice(1))),
  });
  return prev;
}, {});

export default [
  {
    name: "首页",
    path: "/",
    redirect: "/main",
  },
  {
    name: "首页",
    path: "/main",
    component: result.Main,
  },
  {
    name: "职教风采",
    path: "/education",
    component: result.Education
  },
  {
    name: "院校中心",
    path: "/schoolCentre",
    component: result.SchoolCentre
  },
  // 本期暂时不做
  // {
  //   name: "资源中心",
  //   path: "/resourceCentre",
  //   component: result.ResourceCentre
  // },
  {
    name: "应用中心",
    path: "/apply",
    component: result.Apply
  },
  {
    name: "企业中心",
    path: "/company",
    component: result.Company
  },
  {
    name: "智慧实训",
    path: "/trainingCentre",
    component: result.TrainingCentre
  },
  {
    name: "App下载",
    path: "/appDownload",
    component: result.AppDownload
  },
  {
    name: "公告列表",
    path: "/noticeList",
    component: result.NoticeList
  },
  {
    name: "公告详情",
    path: "/noticeDetile",
    component: result.NoticeDetile
  },
  {
    name: "实训详情",
    path: "/trainingDetails",
    component: result.TrainingDetails
  },
  {
    name: "加入我们",
    path: "/join",
    component: result.Jonin
  },
  {
    name: "更多链接",
    path: "/morelinks",
    component: result.Morelinks
  },
  {
    name: "大国工匠",
    path: "/greatCountry",
    component: result.GreatCountry
  },
  // {
  //   name: "1+X证书",
  //   path: "/certificatehome",
  //   component: result.Certificatea
  // },
  {
    name: "1+X证书",
    path: "/certificate",
    component: result.Certificate,
    children: [
        {
            name: "首页",
            path: "/certificate/certificatehome",
            component: result.Certificatehome,
        },
        {
            name: "证书中心",
            path: "/certificate/certificatecenter",
            component: result.Certificatecenter,
            childrenPath: "/certificate/certificatepage"
        },
        {
            name: "证书中心详情",
            path: "/certificate/certificatepage",
            component: result.Certificatepage,
            nameHide: true,
        },
        {
            name: "试点院校",
            path: "/certificate/pilotpage",
            component: result.Pilotpage,
        },
        {
            name: "颁证机构",
            path: "/certificate/lssuingcenter",
            component: result.Lssuingcenter,
        },
        {
            name: "表格统计",
            path: "/certificate/companylist",
            component: result.Companylist,
            childrenPath: "/certificate/companylist",
            nameHide: true
        },
    ],
},
];
