import React, { Component } from 'react'
import Header from '../../../components/demoeHeader'
import Footer from '../../../components/footer'
import {ziXunUrl} from '@/config/secret.js';
import "./index.scss"
import {getNewsListByCategory} from "../../../api/schoolUrl"
import {Button, Input, Pagination} from "antd";
import EmptyConet from '../../../components/emptyConet'
import RenderImg from "../../../components/renderImg";
const urlPre = "zjyd-space-front-peixun/"
const {Search} = Input
export default class Education extends Component {
    onSearch = (val) => { // 搜索
        this.setState({
            name: val
        }, ()=>{
            this.getNewsListByCategory()
        })
    }
    state={
        dataSoure: [],
        pageNum:1,
        pageSize:10,
        total:0,
        name:'',//检索条件
        organizationId: JSON.parse(localStorage.getItem('enterpriseInfo'))? JSON.parse(localStorage.getItem('enterpriseInfo')).id:'', //机构id,,,
        nxmhCategoryListVos:'',//一级分类集合
        oneCategoryId:'',
        selIndex: 0, // 是否被选中按钮
    }
    componentDidMount() {
        this.getNewsListByCategory()
    }
    getNewsListByCategory=async (e)=>{
        let {
            pageNum,
            pageSize,
            name,
            oneCategoryId,
            organizationId,
        } = this.state;
        let data={organizationId:organizationId,platform:"NX",oneCategoryId:oneCategoryId,newsTitle:name,pageNum:pageNum,pageSize:pageSize,}
        await getNewsListByCategory(data).then(e=>{
            if(e){
                this.setState({
                    dataSoure:e.nxmhLatestNewsVoPageData.data,
                    nxmhCategoryListVos:e.nxmhCategoryListVos,
                    total:e.nxmhLatestNewsVoPageData.total
                })
            }
        })
    }
    onChangeButton(e,i){
        this.setState({
            oneCategoryId:e.oneCategoryId,
            selIndex:i,
        },()=>{
            this.getNewsListByCategory()
        })
    }
    //pageSize变化
    onShowSizeChange = (current, pageSize) => {
        this.setState(
            {
                pageNum: 1,
                pageSize: pageSize,
            },
            () => {
                this.getNewsListByCategory();
            }
        );
    };
    //pageNum变化
    onPageNumChange = (page, pageSize) => {
        this.setState(
            {
                pageNum: page,
            }, () => {
                this.getNewsListByCategory();
            }
        );
    };
    gotoNewsDetail=(e)=>{
        let {nxmhCategoryListVos,oneCategoryId}=this.state
        let id=oneCategoryId&&oneCategoryId?oneCategoryId:nxmhCategoryListVos[0].oneCategoryId
        let curUrl=window.location.href
        var regExp=/#(\S*)/;
        curUrl= curUrl.replace(regExp,"")
        window.open(curUrl + `/news/news-details.html?oneCategoryId=${id}&id=${e.newsId}&name=zj`)
    }
    render() {
        const { dataSoure,total,pageNum,pageSize,nxmhCategoryListVos,selIndex} = this.state
        const {$$img} = window;
        return (
            <div className='education schoolCentre_warp'>
                <Header {...this.props} id={4} />
                <div className="box">
                    <div className="top_warp">
                        <div className="top">
                            {/*<img src={require('../../../../src/assets/img/jiaoyuzixun.png')} alt=""/>*/}
                            <div className="btns">
                                {
                                    nxmhCategoryListVos && nxmhCategoryListVos.length > 0 ? nxmhCategoryListVos && nxmhCategoryListVos.map((v, i) => {
                                        return(
                                            <Button  className={i === selIndex ? 'active' : ''} onClick={()=>this.onChangeButton(v,i)} > {v.oneCategoryName}</Button>
                                        )
                                    }): ''
                                }
                            </div>
                        </div>
                        <div className="search">
                        <span>
                            共查询到{total}条资讯
                        </span>
                            <Search style={{ width: 360, height: 38 }} placeholder="请输入资讯名称" onSearch={this.onSearch} enterButton/>
                        </div>
                    </div>

                </div>
                <div className='conten_box'>
                    {
                        dataSoure && dataSoure.length > 0 ? dataSoure && dataSoure.map((v, i) => {
                            return(
                            <div className='content'>
                                <div className='content-c'>
                                    <div className='date'>
                                        <span>{v.releaseTime?v.releaseTime.substr(v.releaseTime.lastIndexOf('-')+1,v.releaseTime.length):''}</span>
                                        <h2>{v.releaseTime?v.releaseTime.substr(0,v.releaseTime.lastIndexOf('-')):''}</h2>
                                    </div>
                                    <div className='right_wenzi' onClick={() => this.gotoNewsDetail(v)}>
                                        <span>{v.newsTitle}</span>
                                        <h3>{v.newsAbstract}</h3>
                                        <h2>来源：{v.authorName}</h2>
                                    </div>
                                </div>
                            </div>
                            )
                        }): (
                           <EmptyConet/>
                        )
                    }
                </div>

                {
                    dataSoure&&dataSoure.length > 0?
                    <div className={'schoolCentre_content'}>
                        <div className='footer_pag' style={{ width: 1200, display: "flex", justifyContent: "flex-end", marginTop: 30, marginBottom: "50px" }}>
                            <Pagination
                                showSizeChanger
                                pageSizeOptions={["10", "20", "30", "40"]}
                                onShowSizeChange={this.onShowSizeChange}
                                onChange={this.onPageNumChange}
                                defaultCurrent={1}
                                current={pageNum}
                                pageSize={pageSize}
                                total={total}
                            />
                        </div>
                    </div>: ''
                }

                <Footer />
            </div>
        )
    }
}
