/*eslint eqeqeq: ["off", "smart"]*/
import axios from "axios"
import React from 'react'
import { message } from "antd"
import { setToken, removeToken } from "./token";
import md5 from "js-md5";
import { isEmpty } from './isEmpty'
import  messageObj  from './err_code'
import { logoutUrl,initUrlByType07 } from '../config/index.js';
import Cookies from 'js-cookie';
import { initSecretUrlByType07 ,baseUrl as baseURL} from '../config/secret'
import "@/components/global_components/confirm/index.js";
import "@/components/global_components/confirm/index.css";
let requestFlag = true;
const Message = new React.Component()
const Http = axios.create({
    timeout: 15000,
    withCredentials: false,
    baseURL:baseURL,
});
Http.interceptors.request.use(
    (config) => {

        let now = new Date().getTime();
        config.headers = {
            ...config.headers,
            "z-ts": now,
            "z-sign": md5(now + ""),
        };
        if (config.url !== '/api/fujian/api/v1/fujian/uploadFile') {
            let token = Cookies.get("info")
                ? JSON.parse(Cookies.get("info")).token
                : "";
            token && (config.headers.token = token);
        }

        return config;
    },
    (error) => {
        Promise.reject(error).catch((e) => { });
    }
);
Http.interceptors.response.use(
    response => {
        const res = response.data
        let token = response.headers["token"]

        

        if (!isEmpty(token)) {
            setToken(token)
        }
        if (response.config.url === '/api/v1/userLogin/login' && response.status === 200) {
            requestFlag = true;
        }
        if (res.code === 0 || (response.config && response.config.responseType === "blob")) {
            if(response?.data?.result?.config){
                initUrlByType07(response.data.result.config)
                initSecretUrlByType07(response.data.result.config)
            }
            if (res.result) {
                return res.result
            } else {
                return res
            }
        } else {
            return Promise.reject(res)
        }
    },
    err => {
        if (err.response && err.response.status) {
            if (err.response.status === 401) {
                if (requestFlag) {
                    requestFlag = false;
                    Message.mesWarning('您的登录已过期，请重新登录');
                    setTimeout(() => {
                        removeToken()
                        window.location = `${logoutUrl}?url=${window.location.origin}`
                    }, 1000);
                }

            } else if (err.response.status === 5503) { } else {
                // Message.mesWarning('哎呀，有意外了，攻城狮正火速来援');
            }

        }
    }
)
export const get = (url, params = {}) => {
    return new Promise(resolve => {
        Http.get(url, { params: params })
            .then(res => {
                resolve(res)
            })
            .catch(err => {
                if (err.code != 0) {
                    if (messageObj[err.code]) {
                        // Message.mesWarning(messageObj[err.code])
                        return;
                    } else if(err.code === 551005 || err.code === 551004 || err.code === 551002 || err.code === 551003){
                        Message.mesError(err.message);
                        /* history.replace("/login")*/
                    } else{
                        Message.mesError(err.message)
                    }
                }
            })
    })
}
export const post = (url, params = {},headers) => {
    return new Promise(resolve => {
        Http.post(url, params,{...headers})
            .then(res => {
                resolve(res)
            })
            .catch(err => {
                message.destroy();
                if (err.code != 0) {
                    if (messageObj[err.code]) {
                        // Message.mesWarning(messageObj[err.code]);
                        return;
                    } else if(err.code === 551005 || err.code === 551004 || err.code === 551002 || err.code === 551003){
                        Message.mesError(err.message);
                        window.location = `${logoutUrl}?url=${window.location.origin}`
                    } else{
                        Message.mesError(err.message)
                    }
                }
            })
    })
}
export const del = (url, params = {}) => {
    return new Promise(resolve => {
        Http.delete(url, { params: params })
            .then(res => {
                resolve(res)
            })
            .catch(err => {
                if (err.code != 0) {
                    if (messageObj[err.code]) {
                        // Message.mesWarning(messageObj[err.code]);
                        return;
                    }
                    Message.mesError(err.message)
                }
            })
    })
}

export const put = (url, params = {}) => {
    return new Promise(resolve => {
        Http.put(url, params)
            .then(res => {
                resolve(res)
            })
            .catch(err => {
                if (err.code != 0) {
                    if (messageObj[err.code]) {
                        // Message.mesWarning(messageObj[err.code]);
                        return;
                    }
                    Message.mesError(err.message)
                }
            })
    })
}

export default Http

