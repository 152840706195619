import React, { Component } from 'react'
import Footer from '../../../components/footer'
import {getNoticeList} from "../../../api/schoolUrl"
import {Input, Pagination} from "antd";
import Header from "../../../components/demoeHeader";
import style from './index.module.scss'
import EmptyConet from '../../../components/mainemptyConet'
import Styles from "../greatCountry/index.module.scss";
const {Search} = Input
export default class NoticeList extends Component {
    onSearch = (val) => { // 搜索
        this.setState({
            name: val
        }, ()=>{
            this.getNoticeList()
        })
    }
    state={
        dataSoure: [],
        pageNum:1,
        pageSize:24,
        total:0,
        name:'',//检索条件
        nxmhCategoryListVos:'',//一级分类集合
        oneCategoryId:'',
        selIndex: 0, // 是否被选中按钮
    }
    componentDidMount() {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
        this.getNoticeList()
    }
    getNoticeList=async (e)=>{
        let {
            pageNum,
            pageSize,
            name
        } = this.state;
        let data={noticeTitle:name,pageNum:pageNum,pageSize:pageSize}
        await getNoticeList(data).then(e=>{
            if(e){
                console.log(e)
                this.setState({
                    dataSoure:e.data,
                    total:e.total
                })
            }
        })
    }
    //pageSize变化
    onShowSizeChange = (current, pageSize) => {
        this.setState(
            {
                pageNum: 1,
                pageSize: pageSize,
            },
            () => {
                this.getNoticeList();
            }
        )
    }
    //pageNum变化
    onPageNumChange = (page, pageSize) => {
        this.setState(
            {
                pageNum: page,
            },
            () => {
                this.getNoticeList();
            }
        )
    }
    gotoDetile = (e) =>{ // 跳转到详情页
        let data={id:e}
        this.props.history.push({
            pathname: "/noticeDetile",
            search: JSON.stringify(data)
        })
        // this.props.history.push('/noticeDetile')
    }
    render() {
        const { dataSoure,total,pageNum,pageSize} = this.state
        const {$$img} = window;
        return (
            <div className={style.notice_box}>
                <Header {...this.props} />
                <div className="box">

                    <div className="top_warp"  style={{background: `url("${window.$$setImgSrc('/nx_tongzhigonggao.png')}") no-repeat 100%`,backgroundSize: '100% 100%'}}>
                        <div className="top">
                         <span>您的位置：<i className={'isClick'} onClick={()=> this.props.history.goBack()}>首页</i> / <i className={'noClick'}>通知公告</i></span>
                            <div className='top_gg'>通知公告</div>
                        </div>
                    </div>
                </div>
                <div className="center_conmain">
                    <div className='center_con_box'>
                        <div className='center_con'>
                            <div className='conten_box'>
                                <div className="search">
                                    <span>
                                        共查询到{total}条公告
                                    </span>
                                    <Search style={{ width: '3.6rem', height: '0.38rem' }} placeholder="请输入信息名称" onSearch={this.onSearch} enterButton/>
                                </div>
                                <div className="bottom_list">
                                    {
                                        dataSoure && dataSoure.length > 0 ? dataSoure && dataSoure.map((v, i) => {
                                            return(

                                                <div className='content' onClick={()=> this.gotoDetile(v.id)}>
                                                    <div className='content-c'>
                                                        <div className='date'>
                                                            <h2>{v.createTime?v.createTime.substr(v.createTime.lastIndexOf('-')+1,v.createTime.length):''}</h2>
                                                            <span>{v.createTime?v.createTime.substr(0,v.createTime.lastIndexOf('-')):''}</span>
                                                        </div>
                                                        <div className='right_wenzi'>
                                                            <h2>{v.noticeTitle}</h2>
                                                            <h3 className="h3">{v.noticeAbstract}</h3>
                                                            <span>发布者：{v.noticeAuthor}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }): (
                                            <div className={Styles.EmptyConet_CONT}>
                                                <EmptyConet/>
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                            {
                                dataSoure && dataSoure.length > 0 ?
                                    <div className={'schoolCentre_content'}>
                                        <div className='footer_pag'>
                                            <Pagination
                                                showSizeChanger
                                                pageSizeOptions={["12", "24", "36", "48"]}
                                                onShowSizeChange={this.onShowSizeChange}
                                                onChange={this.onPageNumChange}
                                                defaultCurrent={1}
                                                current={pageNum}
                                                pageSize={pageSize}
                                                total={total}
                                            />
                                        </div>
                                    </div>: ''
                            }
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        )
    }
}
