import React from 'react';
import { Spin } from 'antd';
import './index.scss';


export default props => {
    const { tip = '数据加载中', h = 500 } = props;
    return <div style={{ minHeight: h + 'px' }} className='loading_box' wrapperClassName='my_loading'>
        {/* <Spin size='middle' tip={tip} />*/}
        <div className="span">
            <img src={require("../../../../../assets/certificate/loading.png")} style={{marginBottom:'10px'}} alt='' />
            <p className='loading_dian'>{tip}</p>
        </div>
    </div>
}