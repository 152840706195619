import React, { Component } from 'react'
import Header from '../../../components/demoeHeader'
import Footer from '../../../components/footer'
import {Button, Input, Pagination} from 'antd'
import RenderImg from '../../../components/renderImg'
import S from 'gongyongicon'
import {getTrainingBaseList,selectServiceCopywriting} from "../../../api/schoolUrl"
import style from './index.module.scss'
import EmptyConet from '../../../components/mainemptyConet'
const {Search} = Input
const urlPre = "zjyd-space-front-peixun/"

export default class TrainingCentre extends Component {
    state = {
        dataSoure: [],
        pageNum:1,
        pageSize:24,
        total:0,
        name:'',//检索条件
        copywritingIntroduction:'',//介绍文案
    }
    componentDidMount() {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
        this.getTrainingBaseList()
        this.selectServiceCopywriting()
    }
    //top文案
    selectServiceCopywriting=async ()=>{
        await selectServiceCopywriting({serviceType:2}).then(e=>{
            if(e){
                this.setState({copywritingIntroduction:e.copywritingIntroduction})
            }
        })
    }
    //实训基地列表
    getTrainingBaseList=async ()=>{
        let {
            pageNum,
            pageSize,
            name,
        } = this.state;
        let data={eduName:name,pageNum:pageNum,pageSize:pageSize,}
        await getTrainingBaseList(data).then(e => {
            if(e){
                this.setState({dataSoure:e.data,total:e.total,pageNum:e.pageNum,pageSize:e.pageSize})
            }
        })
    }
    //pageSize变化
    onShowSizeChange = (current, pageSize) => {
        this.setState(
            {
                pageNum: 1,
                pageSize: pageSize,
            },
            () => {
                this.getTrainingBaseList();
            }
        );
    };
    //pageNum变化
    onPageNumChange = (page, pageSize) => {
        this.setState(
            {
                pageNum: page,
            },
            () => {
                this.getTrainingBaseList();
            }
        );
    };
    onSearch = (val) => { // 搜索
        this.setState({
            name: val
        }, ()=>{
            window.aplus_queue.push({
                'action':'aplus.record',
                'arguments':['search_click', 'CLK', {
                    keyword:val
                 }]
            })
            this.getTrainingBaseList()
        })
    }
    gotoDetile = (e) =>{ // 跳转到详情页
        let data={id:e}
        this.props.history.push({
            pathname: "/trainingDetails",
            search: JSON.stringify(data)
        })
    }
    render() {
        const {$$img} = window;
        const { dataSoure ,pageNum,pageSize,total,copywritingIntroduction} = this.state
        return (
            <div className={style.trainingCentre_warp}>
                <Header {...this.props} id={3} />
                <div className="top_warp" style={{background: `url("${window.$$setImgSrc('/nx_practical_ wisdom.png')}") no-repeat 100%`,backgroundSize: '100% 100%'}}>
                    <div className={'title'}>智慧实训</div>
                    {/*<div className="top">/}
                    {/*    <p>实训基地</p>*/}
                    {/*    <p>*/}
                    {/*        /!*一段文案说明，产教融合，共同推动职业教育发展Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet. Proin gravida dolor sit amet lacus accumsan et viverra justo commodo. Proin sodales pulvinar sic tempor. Sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Nam fermentum, nulla luctus pharetra vulputate, felis tellus mollis orci, sed rhoncus pronin sapien nunc accuan eget.*!/*/}
                    {/*        {copywritingIntroduction}*/}
                    {/*    </p>*/}
                    {/*</div>*/}
                </div>
                <div className="training_contai">
                       
                    <div className="search">
                        <span>
                            共查询到{total}个实训基地   
                        </span>
                        <Search style={{ width: '3.6rem', height: '0.38rem' }} className={'search_wrap'} placeholder="请输入实训基地名称" onSearch={this.onSearch} enterButton/>
                        <Button style={{ width: '1.8rem', marginTop:'0.2rem',fontSize:'0.18rem',fontWeight: 'bold' ,color:'#1890ff'}} 
                        onClick={()=>{window.location = 'https://college.zj.nxeduyun.com/training/?schoolId=b267bc47cdae4dfcb212cb247f0b0943&name=CS&nav=%25E5%25AE%259E%25E8%25AE%25AD'}}>  实训室预约 </Button>
                    </div>
                    {
                        dataSoure && dataSoure.length > 0 ? dataSoure && dataSoure.map((v, i) => {
                            return <div className="data_list" key={v.id}>
                                <div className="title">
                                    <h2>{v.trainingName}</h2>
                                    <p>
                                        <span>{v.mainSchoolName}</span>
                                        <a  onClick={()=> this.gotoDetile(v.id)}>查看详情&nbsp;&nbsp;&gt;</a>
                                    </p>
                                    <dd>
                                        <S.Icon_diqu/>{v.cityName}
                                    </dd>
                                </div>
                                <div className="con">
                                    <p dangerouslySetInnerHTML={{__html: v.trainingIntroduction.replace(/<img [^>]*src=['"]([^'"]+)[^>]*>/g,'').replace(/&nbsp;/ig,'')}}>
                                    </p>
                                    <p>
                                        {v.applicableDirection}
                                    </p>
                                    <div className="img_box">
                                        {v.imgOne ?<RenderImg size='m' id={v.imgOne} type={v.mediaTypeOne }/>:''}
                                        {v.imgTwo?<RenderImg size='m' id={v.imgTwo} type={v.mediaTypeTwo}/>:''}
                                    </div>
                                </div>
                            </div>
                        }): (
                            <div className={style.EmptyConet_CONT}>
                                <EmptyConet/>
                            </div>
                        )
                    }
                    {
                        dataSoure && dataSoure.length > 0 ?
                            <div className='footer_pag'>
                                <Pagination
                                    showSizeChanger
                                    pageSizeOptions={["12", "24", "36", "48"]}
                                    onShowSizeChange={this.onShowSizeChange}
                                    onChange={this.onPageNumChange}
                                    defaultCurrent={1}
                                    current={pageNum}
                                    pageSize={pageSize}
                                    total={total}
                                />
                            </div> : ''
                    }
                </div>
                <Footer/>
            </div>
        )
    }
}
