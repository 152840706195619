import React, { Component } from 'react'
import Cookies from 'js-cookie';
import {gerenzhongxin, logoutUrl, zjyIndex} from '@/config/index';
import Styles from "./index.module.scss";
import S from 'gongyongicon'
import RenderImg from '@/components/renderImg/index'
import {authorityList, userInfo} from '@/until/authorityList'
import {withRouter} from "react-router-dom";
const _id_create = () => {
    return 'xxxxxx-xxx-4xxx-yxx-xxxxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16 | 0,
            v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}
const state = _id_create()
@withRouter
class index extends Component {
    state = {
        act: 0 ,
        jumpHome: "",
        change_nav: false,
        userId: ''
    }
    componentDidMount() {
        window.addEventListener('scroll', this.bindHandleScroll)
        this.setState({
            userId: Cookies.get("info")? JSON.parse(decodeURI(Cookies.get("info"))).userId:''
        });
    }

    bindHandleScroll = (e) => {
        if (e.srcElement && e.srcElement.documentElement.scrollTop > 180) {
            return this.setState({ change_nav: true })
        }
        this.setState({ change_nav: false })
        if (e.srcElement && e.srcElement.documentElement.scrollTop > 45 && e.srcElement.documentElement.scrollTop < 170) {
            return this.setState({ isOpacoty: false })
        } else {
            this.setState({ isOpacoty: true })
        }
    }
    goroRegister = () =>{ // 注册
        window.location = `${gerenzhongxin+"/#/register"}?url=${window.location.origin}`
    }
    //登录
    handleLogin=()=>{
        window.location = `${logoutUrl}?url=${window.location.origin}`
    }
    //确定退出登录
    handleLogout = () => {
        this.hint({
            "title": '温馨提示',
            'type': 'warning',
            'content': `确定要退出登录吗？`,
            'cancelText': '取消',
            'okText': '确定',
            'isCancel': true,
            'sure': this.logout
        })
    };
    logout = () => { // 退出登录
        window.location = `${logoutUrl}?url=${window.location.origin}&out=true`
    }
    gotoIndex = () =>{ // 返回中教云首页
        window.open(`${zjyIndex}`)
    }
    render() {
        const { act } = this.state
        return (
            <div className={Styles.demoeHeader_wrap}>
                <div className="demoeHeader_cont">
                    <div className="demoe_title_wrap">
                        <div className="title_container">
                            <div className="schoolName">
                                <img src={require('@/assets/democ/headerLogo.svg')}/>
                            </div>
                            <div className="header_right">
                                <div className="functionBtns">
                                    <div className='return_box' onClick={()=>this.gotoIndex()}><S.Return1/><span>返回教育云平台</span></div>
                                    {
                                        userInfo.id?'':
                                            <div className="login_box">
                                                <span onClick={this.handleLogin}>登录</span>
                                                <span onClick={this.goroRegister}>注册</span>
                                            </div>

                                    }
                                </div>
                                {
                                    userInfo.id?
                                        <>
                                            <div className='nx_btn_box'>
                                                <div className={'goto_btn'}><S.Menhicon/>进入云门户</div>
                                                {
                                                    authorityList.length?(
                                                        <div className='menu_right_down'>
                                                            {authorityList.map((v, i) => {
                                                                return <div
                                                                    key={i}
                                                                    className='menu_right_down_item'
                                                                    onClick={()=>{
                                                                        window.location = v.url;
                                                                    }}
                                                                >进入{v.name}</div>
                                                            })}
                                                        </div>
                                                    ):""
                                                }

                                            </div>
                                            <div className='menu_right'>
                                                <RenderImg size='m' id={userInfo.portraitId} type={userInfo.suffix}/>
                                                <div className="user-right">
                                                    <p className="userinfo">{userInfo.humanName || userInfo.fullName || ""}</p>
                                                    <img className="icon" src={require('@/assets/democ/userInfoIcon.svg')} />
                                                </div>
                                                <div className='menu_right_down'>
                                                    <div className='menu_right_down_item' onClick={this.handleLogout}><S.Gbiocn/>退出登录</div>
                                                </div>

                                            </div>
                                        </>:''
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className={'header_bottom'}>
                    <div className="demoeHeader_cont demoeHeader_cont_bottom">
                        <div className="nav_wrap">
                            <div className="nav_container">
                                <ul>
                                    <li
                                        className={act === 0 ? "act" : ''}
                                        onClick={() => {
                                            this.setState({ act: 0 })
                                            this.props.history.push("/certificate/certificatehome")
                                        }}>首页</li>
                                    <li
                                        className={act === 4 ? "act" : ''}
                                        onClick={() => {
                                            this.setState({ act: 4 })
                                            this.props.history.push("/certificate/certificatecenter")
                                        }}>证书中心</li>
                                    <li
                                        className={act === 2 ? "act" : ''}
                                        onClick={() => {
                                            this.setState({ act: 2 })
                                            this.props.history.push('/certificate/pilotpage')
                                        }}>试点院校</li>
                                    <li
                                        className={act === 6 ? "act" : ''}
                                        onClick={() => {
                                            this.setState({ act: 6 })
                                            this.props.history.push('/certificate/lssuingcenter')
                                        }}>颁证机构</li>

                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        )
    }
}
export default index;
