// 暂无数据组件
import React, { Component } from 'react'
import Styles from "./index.module.scss";
class EmptyConet extends Component {
    render() {
        return (
            <div className="Empty-conet">
                <div className="img">
                    <img src={window.$$setImgSrc('px-no-data.png')}/>
                    <span>暂无数据</span>
                </div>
            </div>
        )
    }

}
export default EmptyConet;
