import React, { Component } from 'react'
import {Form, Input} from "antd";
import Join from "../../page/main/jonin";
const FormItem = Form.Item;

class AddLabel extends Component {
    state = {

    }
    render() {
        const { getFieldDecorator } = this.props.form;
        return (
            <div className="add_mark">

                <Form
                >
                    <FormItem label={'标签名称'} className="Item-bottom">
                            {getFieldDecorator("epsLabel", {
                                initialValue: "",
                                rules: [{ required: true, message: "请输入企业标签名称" }]
                            })(
                                <Input
                                    placeholder="请输入企业标签名称"
                                    maxLength={6} className="input"
                                    suffix={<span className='len'>{this.props.form.getFieldValue(`epsLabel`) ? this.props.form.getFieldValue(`epsLabel`).length : 0}/6</span>}/>)}

                    </FormItem>
                </Form>
            </div >
        )
    }
}
AddLabel = Form.create()(AddLabel);
export default AddLabel;