import Cookies from 'js-cookie';

const TokenKey = 'OBS_token';

export function getCookieToken() {
  if (Cookies.get("Main_Info") && Cookies.get("Main_Info") !== undefined && Cookies.get("Main_Info") !== 'undefined') {
    let mainInfo = decodeURI(Cookies.get("Main_Info"));
    let personnelDetail = JSON.parse(mainInfo)
    return personnelDetail.token;
  } else {
    return "";
  }
}

export function getToken() {
  return localStorage.getItem(TokenKey);
}

export function setToken(token) {
  localStorage.setItem(TokenKey, token);
}

export function removeToken() {
  localStorage.removeItem(TokenKey);
}
