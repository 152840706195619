import React, {Component} from 'react'
import Footer from '../../../components/footer'
import {Button, Input, Pagination} from 'antd'
import DemoeHeader from '../../../components/demoeHeader'
import Styles from "./index.module.scss"
import {studioLevel, studioList,getDoMain} from '../../../api/craftsman/index'
import RenderImg from "../../../components/renderImg";
import EmptyConet from '../../../components/mainemptyConet'

const {Search} = Input
const urlPre = "zjyd-space-front-peixun/"
export default class GreatCountry extends Component {
    state = {
        dataSoure: [],
        pageNum: 1, // 当前页
        pageSize: 24, // 每页条数
        total: 0, // 总条数
        levelList: [],
        searchValue: '',
        studioLevel: null,
        code: '',
        selIndex: 0
    }

    componentDidMount() {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
        this.studioLevel()
        this.studioList()
    }
    async studioLevel() {
        let data = {
            code:this.state.code
        }
        let levelList = await studioLevel(data)
        levelList&&levelList.length!=0&&levelList.unshift({code:'', desc: '全部'});
        if (levelList&&levelList.length>0) {
            this.setState({
                levelList: levelList,
                code:this.state.code,
            }, () => {
                this.studioList(levelList[0].code);
            })
        }
    }
    // studioLevel = () => {
    //     studioLevel().then(e => {
    //         this.setState({
    //             levelList: e
    //         })
    //     })
    // }

    studioList = () => {
        const {pageNum, pageSize, searchValue, studioLevel} = this.state
        let data = {
            pageNum, pageSize, searchValue, studioLevel
        }
        studioList(data).then(e => {
            console.log("e:::;::::", e)
            this.setState({
                total:e.total,
                dataSoure:e.data
            })
        })
    }

    //pageSize变化
    onShowSizeChange = (current, pageSize) => {
        this.setState(
            {
                pageNum: 1,
                pageSize: pageSize,
            },
            () => {
                this.studioList();
            }
        );
    };

    //pageNum变化
    onPageNumChange = (page, pageSize) => {
        this.setState(
            {
                pageNum: page,
            },
            () => {
                this.studioList();
                document.body.scrollTop = document.documentElement.scrollTop = 0;
            }
        );
    };

    onSearch = (val) => { // 搜索
        this.setState({
            searchValue: val
        }, () => {
            this.studioList()
        })
    }

    updateCode = (studioLevel,index) => {
        this.setState({
            selIndex: index,
            studioLevel
        }, () => {
            this.studioList()
        })
    }
    gotoDggj = async (item) => { // 跳转到大国工匠详情页
        const orgId = item.orgId
        let data = {
            schoolId:orgId
        }
        await getDoMain(data).then(res=>{
            let doamin = res ? res.domain: ''
            let gotoUrl = doamin + '/gongjiang/'
            window.open(gotoUrl + `/#/daguocraftsman/studioGlobal/studioHome?${encodeURI(JSON.stringify(
                {studioId: item.id, preViewState: 2}))}`)
        })
    }
    render() {
        const {$$img} = window;
        const {dataSoure, total, levelList, selIndex, pageSize, pageNum,code } = this.state
        return (
            <div className={Styles.greatCountry_warp}>
                <DemoeHeader {...this.props} id={6}/>
                <div className="top_banner"
                     style={{background: `url("${window.$$setImgSrc('/nxmh_great Country.png')}") no-repeat 100%`,backgroundSize: '100% 100%'}}>
                    <div className='top_banner_span'>
                        <span>大国工匠</span>
                    </div>
                </div>
                <div className='greatCountry_warp_content_box'>
                    <div className="top_warp">
                        <div className="top">
                            <div className="btns">
                                <span className="fist">工作室类型</span>
                                {
                                    levelList && levelList.map((item, index) => {
                                        return <>
                                            <Button className={index === selIndex ? 'active' : ''}
                                                    onClick={() => this.updateCode(item.code,index)}> {item.desc}</Button>
                                        </>
                                    })
                                }
                            </div>
                        </div>
                        <div className="search">
                            <span>
                                共查询到{total}个工作室
                            </span>
                            <Search style={{width: '3.6rem', height: '0.38rem'}} placeholder="请输入工作室名称" onSearch={this.onSearch}
                                    enterButton/>
                        </div>
                    </div>
                    <div className="greatCountry_content">
                        {
                            dataSoure && dataSoure.length > 0 ? dataSoure.map((item, index) => {
                                return <div className="dataList" key={index} onClick={()=>this.gotoDggj(item)}>
                                    <div className="top">
                                        <div className="pic">
                                            {item.studioLevel === 0 ?<i>国家级</i>: item.studioLevel === 1 ?<i className="on">省级</i>: item.studioLevel === 2 ?<i className="active">市级</i>:
                                                item.studioLevel === 3 ?<i className="end">区级</i>: item.studioLevel === 4 ?<i className="start">校级</i>: ''}
                                            <RenderImg  type={item.studioHeadType} id={item.studioHeadUrl}/>
                                        </div>
                                        <div className="text">
                                            <h4>{item.studioName}</h4>
                                            <div className="doc">
                                                {item.studioContentBrief}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="bottom">
                                        <span className="title_span">
                                            {item.orgName}
                                        </span>
                                        <span className="bott_span">
                                            <i className="img">
                                                <RenderImg  type={item.headType} size='s' id={item.headUrl}/>
                                            </i>
                                            <b>{item.leaderName}  ｜  领衔人</b>
                                        </span>
                                    </div>
                                </div>
                            }) : (
                                <div className={Styles.EmptyConet_CONT}>
                                    <EmptyConet/>
                                </div>
                            )
                        }
                        {
                            dataSoure && dataSoure.length > 0 ?
                                <div className='footer_pag' style={{
                                    width: '100%',
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    marginTop: '0.3rem',
                                    marginBottom: "0.5rem"
                                }}>
                                    <Pagination
                                        showSizeChanger
                                        pageSizeOptions={["12", "24", "36", "48"]}
                                        onShowSizeChange={this.onShowSizeChange}
                                        onChange={this.onPageNumChange}
                                        defaultCurrent={1}
                                        total={total}
                                        pageSize={pageSize}
                                        current={pageNum}
                                    />
                                </div> : ''
                        }

                    </div>
                </div>

                <Footer/>
            </div>
        )
    }
}
