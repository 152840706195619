import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./index.css";
import "antd/dist/antd.css";
import {apiNx} from "./config/apiPrefix";
import {organizationDetail} from  "./api/schoolUrl"
import { getImageOssBaseurl } from "./api/Baseurl";
import store from './store'
import { Provider } from 'react-redux'
import { initUrlByType07,baseURL} from './config'
import { initSecretUrlByType07 ,path} from './config/secret'
import Http from './until/axios_instans'
import {getAuthorityList} from './until/authorityList'
window.apiNx = apiNx(); // 宁夏api前缀
function create(src) {
    return function (path) {
        if (!path) return;
        if (path.indexOf("/") === 0) {
            return src + path.slice(1);
        } else {
            return src + path;
        }
    };
}
let curUrl=window.location.href
var regExp=/#(\S*)/;
curUrl= curUrl.replace(regExp,"")
organizationDetail({enterpriseDomain:curUrl}).then((res)=>{
    if(!res || !res.result){
        res = {
            id:"e787a9923f9549afbb2a63565335250b",
            enterpriseName:"宁夏教育厅",
            enterpriseCode:"宁夏教育厅",
            enterpriseLogo:"8c76a9af50bf40c5acb7afe3fb74fbbd/obs/image/20210831/79af118475de483288c03959d75556f1.jpg"
        }
    }
    localStorage.setItem("enterpriseInfo",JSON.stringify(res))
})

getImageOssBaseurl({ type: '100007' }).then(res => {
    const {
        'static-img':staticImg,
        'front-img': frontImg,
        'office-public-resource': officePublicResource,
        'public-resource': publicResource,
        'gw': gw,
    } = res.config
    window.$$setImgSrc = create(staticImg);
    window.$$ImageOssBaseurl = frontImg;
    window.$$img = create(frontImg);
    window.$$uploadRc = create(publicResource);
    window.$$serverImageUrl = create(publicResource);
    window.$$uploadDoc = create(officePublicResource);
    window.$$imgSrc = create(publicResource);
    window.$$imgSrcLcl = publicResource;
    window.$$createUploadFilePath = create(publicResource);
    window.$$gw = create(gw);

    if (res) {
        // document.title = res?.config?.title.NX;
        document.querySelector('link[rel="icon"]').href = window.$$setImgSrc(res?.config?.favicon)
    }
    if(res?.config){
        initUrlByType07(res?.config)
        initSecretUrlByType07(res?.config)
        Http.defaults.baseURL = baseURL
    }
    // 获取权限列表
    getAuthorityList(res.config.sys,()=>{
        ReactDOM.render(
            <Provider store={store}>
                <App/>
            </Provider>
            ,
            document.getElementById('root')
        );
    })
})
